import styled, { css } from 'styled-components/macro'

export const GalleryGrid = styled.div`
  position: relative;
  padding-top: ${props =>
    `calc(${props.rows / props.columns} * calc(100% - ${props.columns -
      0.5}rem) + ${props.rows - 0.5}rem)`};
`

export const GalleryInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  grid-gap: 10px;
  grid: ${props =>
    `repeat(${props.rows}, 1fr) / repeat(${props.columns}, 1fr)`};
  height: ${props =>
    props.isMobile ? 'calc(100vw - 2rem)' : 'calc(((100vw - 95px) / 5 * 3))'};
`

export const Cell = styled.div`
  position: relative;
  background-color: black;
  ${props =>
    props.isMobile
      ? css`
          &:nth-child(1) {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
          }
          &:nth-child(2) {
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
          }
          &:nth-child(3) {
            grid-column: 3 / span 1;
            grid-row: 2 / span 1;
          }
          &:nth-child(4) {
            grid-column: 1 / span 1;
            grid-row: 3 / span 1;
          }
          &:nth-child(5) {
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;
          }
          &:nth-child(6) {
            grid-column: 3 / span 1;
            grid-row: 3 / span 1;
          }
        `
      : css`
          &:nth-child(1) {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
          }
          &:nth-child(2) {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
          }
          &:nth-child(3) {
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
          }
          &:nth-child(4) {
            grid-column: 4 / span 2;
            grid-row: 1 / span 2;
          }
          &:nth-child(5) {
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
          }
          &:nth-child(6) {
            grid-column: 2 / span 2;
            grid-row: 2 / span 2;
          }
          &:nth-child(7) {
            grid-column: 4 / span 1;
            grid-row: 3 / span 1;
          }
          &:nth-child(8) {
            grid-column: 5 / span 1;
            grid-row: 3 / span 1;
          }
        `}
`
