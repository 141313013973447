import { DIRECTION_SET, DIRECTION_UNSET } from './types'

export const setDirection = payload => ({
  type: DIRECTION_SET,
  payload,
})

export const unsetDirection = payload => ({
  type: DIRECTION_UNSET,
})
