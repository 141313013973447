import React from 'react'
import * as S from './styles'
import { useConsumerMobile } from '../../context/ContextMobile'

const GalleryGrid = ({ items }) => {
  const isMobile = useConsumerMobile()
  const config = {
    rows: 3,
    columns: isMobile ? 3 : 5,
  }
  return (
    <S.GalleryGrid {...config}>
      <S.GalleryInner {...config} isMobile={isMobile}>
        {items.map((item, key) => (
          <S.Cell key={key} isMobile={isMobile}>
            {item}
          </S.Cell>
        ))}
      </S.GalleryInner>
    </S.GalleryGrid>
  )
}

export default GalleryGrid
