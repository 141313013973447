import React from 'react'
import LazyLoad from 'react-lazyload'
import * as S from './styles'
import GridElementWrapper from '../GridElementWrapper'

export default props => {
  const { noDecorator, src, scrollContainer = window } = props

  return (
    <LazyLoad once offset={100} scrollContainer={scrollContainer}>
      <S.Image url={src}>{!noDecorator && <GridElementWrapper />}</S.Image>
    </LazyLoad>
  )
}
