import { ACTIVE_DOT_SET, ACTIVE_DOT_UNSET } from './types'

export const setActiveDot = payload => ({
  type: ACTIVE_DOT_SET,
  payload,
})

export const unsetActiveDot = payload => ({
  type: ACTIVE_DOT_UNSET,
})
