import produce from 'immer'
import {
  MODIFIER_TOGGLE,
  FEATURE_SELECT,
  COMPARE_ADD,
  COMPARE_REMOVE,
  SHOW_TECH_DATA,
  HIDE_TECH_DATA,
  OPEN_FEATURE_SELECT,
  CLOSE_FEATURE_SELECT,
} from './types'

const defaultState = {
  versions: [
    {
      currentFeature: 'barchetta',
      currentModifiers: [],
    },
  ],
  isShowingTechData: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FEATURE_SELECT:
      return produce(state, draftState => {
        draftState.versions[action.payload.version].currentFeature =
          action.payload.id
      })
    case MODIFIER_TOGGLE:
      return produce(state, draftState => {
        let compareCurrentModifiers =
          draftState.versions[action.payload.version].currentModifiers
        if (compareCurrentModifiers.indexOf(action.payload.id) > -1) {
          draftState.versions[
            action.payload.version
          ].currentModifiers = compareCurrentModifiers.filter(
            e => e !== action.payload.id,
          )
          return
        }
        compareCurrentModifiers.push(action.payload.id)
      })
    case COMPARE_ADD:
      if (state.versions.length === 2) return state
      return produce(state, draftState => {
        draftState.versions.push(defaultState.versions[0])
      })
    case COMPARE_REMOVE:
      return produce(state, draftState => {
        if (state.versions.length === 1) return
        draftState.versions.length = draftState.versions.length - 1
      })

    case SHOW_TECH_DATA:
      return {
        ...state,
        isShowingTechData: true,
      }

    case HIDE_TECH_DATA:
      return {
        ...state,
        isShowingTechData: false,
      }

    case OPEN_FEATURE_SELECT:
      return {
        ...state,
        isShowingFeatureSelect: true,
      }

    case CLOSE_FEATURE_SELECT:
      return {
        ...state,
        isShowingFeatureSelect: false,
      }

    default:
      return state
  }
}

export const getSelectedFeature = state =>
  state.customizer.versions[0].currentFeature
export const getSelectedModifiers = state =>
  state.customizer.versions[0].currentModifiers

export const getComparingSelectedFeature = state =>
  state.customizer.versions[1] && state.customizer.versions[1].currentFeature
export const getComparingSelectedModifiers = state =>
  state.customizer.versions[1] && state.customizer.versions[1].currentModifiers

export const getIsShowingTechData = state => state.customizer.isShowingTechData

export const getIsShowingFeatureSelect = state =>
  state.customizer.isShowingFeatureSelect
