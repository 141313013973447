import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { connect } from 'react-redux'
import sliderActions from '../../redux/slider/actions'
import { getActiveDot } from '../../redux/slider/dots/reducer'
import Cta from '../Cta'

import TweenMax from 'gsap/TweenMax'

const SubMenu = ({ points, isSliding, setActiveDot, activeDot }) => {
  const dotContainer = useRef()
  const navigateToDot = ({ point }) => {
    const { id } = point
    setActiveDot(id)
  }

  useEffect(() => {
    if (isSliding) return
    if (!dotContainer && !dotContainer.current) return
    TweenMax.staggerTo(
      dotContainer.current.children,
      0.1,
      {
        opacity: 1,
      },
      0.1,
    )
  }, [isSliding])

  return (
    <Container ref={dotContainer}>
      {points.map((point, index) => (
        <Label key={index + 'sublabel'} isActive={point.id === activeDot}>
          <Cta
            onClick={e => {
              e.stopPropagation()
              navigateToDot({ point })
            }}
            inline
          >
            {point.label}
          </Cta>
        </Label>
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  padding-left: 0px;
  transition: 0.3s;
  margin-top: 9px;
  z-index: 9;
  width: 140px;
  left: 0;
  margin-left: 0px;
  filter: drop-shadow(0px 0px 0px transparent);
`

const Label = styled.div`
  color: #fff;
  opacity: 0;
  font-size: 10px;
  text-shadow: none;
  margin-bottom: 6px;
  letter-spacing: 0.4px;
  filter: drop-shadow(0px 0px 0px transparent);
  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.main};
      text-shadow: none;
      filter: drop-shadow(0px 0px 0px transparent);
    `}
`

const mapStateToProps = state => ({
  activeDot: getActiveDot(state),
})

export default connect(
  mapStateToProps,
  {
    ...sliderActions,
  },
)(SubMenu)
