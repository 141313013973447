import React from 'react'

import * as S from './styles'
import { Rombo } from '../../svg'

export default ({ children, big }) => (
  <S.LateralFrame>
    <S.Frame big={big} left>
      <Rombo color="black" />
    </S.Frame>
    <S.Frame big={big} right>
      <Rombo color="black" />
    </S.Frame>
    {children}
  </S.LateralFrame>
)
