import React, { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import LensTrigger from './LensTrigger'
import { connect } from 'react-redux'
import TweenMax from 'gsap/TweenMax'

import { useConsumerTranslation } from '../../context/ContextTranslation'
import { getCurrentID } from '../../redux/slider/currentID/reducer'
import { getIsSliding } from '../../redux/slider/isSliding/reducer'
import { getActiveDot } from '../../redux/slider/dots/reducer'
import * as sliderActions from '../../redux/slider/dots/actions'

// From top left corner
const DESTINATION_POINT_WHEN_ZOOMED = {
  x: 0.45,
  y: 0.43,
}

const calcTranslation = ({ point, image }) => {
  const { zoomPositionX, zoomPositionY } = point
  //first I translate to center of the image (that's how tweenmax works)
  const translationToCenter = {
    // left dall'immagine (che può sbordare) meno delta di sbordamento meno metà dello schermo mi da la distanza dal centro dello schermo del pallozzo
    x: point.x - (image.width - window.innerWidth) / 2 - window.innerWidth / 2,
    y:
      point.y -
      (image.height - window.innerHeight) / 2 -
      window.innerHeight / 2,
  }

  // Just to know I need to start from screen center
  const centerOfTheScreen = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  }

  return {
    x:
      -translationToCenter.x -
      (centerOfTheScreen.x -
        (zoomPositionX || DESTINATION_POINT_WHEN_ZOOMED.x) * window.innerWidth),
    y:
      -translationToCenter.y -
      (centerOfTheScreen.y -
        (zoomPositionY || DESTINATION_POINT_WHEN_ZOOMED.y) *
          window.innerHeight),
  }
}

const InnerPanning = ({
  image,
  currentID,
  activeDot,
  unsetActiveDot,
  isSliding,
}) => {
  const mainImage = useRef(null)
  const { slides } = useConsumerTranslation()

  const currentSlide = slides[currentID]
  const [animating, setAnimating] = useState()

  const { points } = currentSlide
  const screenHeight = window.innerHeight
  const screenWidth = window.innerWidth

  const imageSizes = {
    width: 1440, //La larghezza in pixel dell'immagine originale
    height: 800, //L'altezza in pixel dell'immagine originale
  }

  let scaledImage = {
    ...imageSizes, // L'immagine scalata ha una proporzione come quella originale e una modificata sotto
  }

  let scaledPoints = []

  const imageRatio = imageSizes.width / imageSizes.height
  const screenRatio = screenWidth / screenHeight

  if (imageRatio > screenRatio) {
    // se il rapporto dell'immagine è maggiore di quello dello schermo
    scaledImage.width = imageRatio * screenHeight
    scaledImage.height = screenHeight
    scaledPoints = points.map(point => ({
      ...point,
      x: scaledImage.width * (point.x / imageSizes.width),
      id: point.id,
      y: screenHeight * (point.y / imageSizes.height),
    }))
  } else {
    scaledImage.height = screenWidth / imageRatio
    scaledImage.width = screenWidth
    scaledPoints = points.map(point => ({
      ...point,
      x: screenWidth * (point.x / imageSizes.width),
      y: scaledImage.height * (point.y / imageSizes.height),
      id: point.id,
    }))
  }

  useEffect(() => {
    if (isSliding) return
    if (!mainImage && !mainImage.current && !mainImage.current.children) return

    TweenMax.staggerTo(
      mainImage.current.children,
      0.1,
      {
        opacity: 1,
      },
      0.2,
    )
  }, [isSliding])

  return (
    <Container blackBackground={animating}>
      <Frame
        isDotSelected={activeDot}
        screenRatio={screenRatio}
        imageRatio={imageRatio}
        image={scaledImage}
        left={(scaledImage.width - screenWidth) / 2}
        top={(scaledImage.height - screenHeight) / 2}
        ref={mainImage}
        className="mainImage"
      >
        {scaledPoints.map((point, index) => (
          <LensTrigger
            mainImage={mainImage}
            point={point}
            setAnimating={setAnimating}
            bgXMiniLens={(point.x - 15 - 10) * -1}
            bgYMiniLens={(point.y - 15 - 10) * -1}
            reducedBackgroundSize={scaledImage.width}
            isHidden={activeDot !== null && point.id === activeDot}
            key={point.id}
            translation={calcTranslation({ point, image: scaledImage })}
          />
        ))}
        <Img src={image} onClick={unsetActiveDot} />
      </Frame>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props =>
    props.blackBackground ? '#000' : 'transparent'};
`

const Img = styled.img`
  height: 100%;
  width: 100%;
`
const Frame = styled.div`
  user-select: none;
  ${props =>
    props.imageRatio > props.screenRatio &&
    css`
      height: 100%;
      width: ${props => props.image.width}px;
      left: -${props => props.left}px;
      transform: translateX(0px);
      position: absolute;
    `}

  ${props =>
    props.imageRatio <= props.screenRatio &&
    css`
      width: 100%;
      height: ${props => props.image.height}px;
      top: -${props => props.top}px;
      transform: translateY(0px);
      position: absolute;
    `}
    ::before {
    user-select: none;
    opacity: ${props => (props.isDotSelected ? 0.3 : 0)};
    transition: 0.5s ease-in opacity;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
  }
`
const mapStateToProps = state => ({
  currentID: getCurrentID(state),
  isSliding: getIsSliding(state),
  activeDot: getActiveDot(state),
})
export default connect(
  mapStateToProps,
  sliderActions,
)(InnerPanning)
