import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { doAnimate, backToStart } from './animations'
import Rombo from './../../svg/Rombo'
import { connect } from 'react-redux'
import { getCurrentID } from '../../redux/slider/currentID/reducer'
import { getNextID } from '../../redux/slider/nextID/reducer'
import { getIsSliding } from '../../redux/slider/isSliding/reducer'
import { getDirection } from '../../redux/slider/direction/reducer'
import { getActiveDot } from '../../redux/slider/dots/reducer'
import * as sliderActions from '../../redux/slider/dots/actions'
import * as directionActions from '../../redux/slider/direction/actions'
import * as mouseTrackerActions from '../../redux/mouseTracker/actions'
import { BurgerClose } from '../../svg'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import Cta from '../Cta'

const LensTrigger = ({
  mainImage,
  point,
  translation,
  activeDot,
  setActiveDot,
  unsetActiveDot,
  unsetDirection,
  direction,
  currentID,
  isHidden,
  bgXMiniLens,
  bgYMiniLens,
  reducedBackgroundSize,
  setAnimating,
  onMouseEnter,
  onMouseLeave,
}) => {
  const miniLens = useRef(null)
  const bigLens = useRef(null)
  const descriptionEl = useRef(null)
  const labelContainer = useRef(null)
  const { label, description, bgX, bgY, inverted } = point
  const { slides } = useConsumerTranslation()
  const currentSlide = slides[currentID]

  useEffect(() => {
    if (!activeDot) {
      backToStart({
        onComplete: () => {
          setAnimating(false)
          unsetDirection()
        },
      })
      return
    }

    if (
      !activeDot ||
      activeDot !== point.id ||
      !miniLens ||
      !bigLens ||
      !mainImage
    ) {
      return
    }

    doAnimate({
      miniLens,
      mainImage,
      bigLens,
      translation,
      labelContainer,
      descriptionEl,
    })
    onMouseLeave()
    setAnimating(true)
  }, [activeDot])

  useEffect(() => {
    if (!direction) return
    unsetActiveDot()
  }, [direction])

  return (
    <LensTriggerContainer
      onMouseEnter={!activeDot && onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHidden={isHidden}
      point={point}
      onClick={event => {
        const { id } = point
        setActiveDot(id)
      }}
    >
      <Lens
        ref={bigLens}
        bgX={bgX}
        bgY={bgY}
        currentImage={currentSlide && currentSlide.image}
      />
      <MiniLens
        ref={miniLens}
        bgXMiniLens={bgXMiniLens}
        bgYMiniLens={bgYMiniLens}
        reducedBackgroundSize={reducedBackgroundSize}
        currentImage={currentSlide && currentSlide.image}
      >
        <DescriptionContainer inverted={inverted}>
          <LabelContainer ref={labelContainer} inverted={inverted}>
            <Label yellow> </Label>
            <Label grey>{label}</Label>
            <Label close>
              <Cta
                onClick={e => {
                  e.stopPropagation()
                  unsetActiveDot()
                }}
                fullWidth
              >
                <BurgerClose fill="#000" />
              </Cta>
            </Label>
          </LabelContainer>
          <Description
            ref={descriptionEl}
            dangerouslySetInnerHTML={{ __html: description }}
            inverted={inverted}
          />
        </DescriptionContainer>
      </MiniLens>
      <RomboRama className="top">
        <Rombo />
      </RomboRama>
      <RomboRama className="bottom">
        <Rombo />
      </RomboRama>
      <RomboRama className="left">
        <Rombo />
      </RomboRama>
      <RomboRama className="right">
        <Rombo />
      </RomboRama>
    </LensTriggerContainer>
  )
}

const RomboRama = styled.div`
  position: absolute;
  line-height: 0;
  &.left {
    top: 50%;
    transform: translateY(-50%);
    left: -12px;
  }
  &.right {
    top: 50%;
    transform: translateY(-50%);
    right: -12px;
  }
  &.top {
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
  }
  &.bottom {
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 1rem;
  ${props =>
    props.inverted
      ? css`
          right: 4.5rem;
          text-align: right;
        `
      : css`
          left: 4.5rem;
        `}
`

const LensTriggerContainer = styled.div`
  @keyframes pulse {
    0% {
      transform: scale(1.5);
      opacity: 0.2;
    }
    100% {
      transform: scale(3.5);
      opacity: 0;
    }
  }

  @keyframes pulse2 {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(3);
      opacity: 0;
    }
  }

  opacity: 0;
  border: 1px solid #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: ${props => props.point && props.point.x - 10}px;
  top: ${props => props.point && props.point.y - 10}px;

  &:before,
  &:after {
    border: 1px solid #fff;
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    transform-origin: center center;
    opacity: 0;
    box-sizing: border-box;
    animation-timing-function: 1s 0.1s pulse2 infinite
      cubic-bezier(0, 0, 0.43, 1.96);
  }

  &:before {
    animation: 1.8s pulse2 infinite cubic-bezier(0, 0, 0, 0.99);
  }

  &::after {
    animation: 1.8s pulse infinite cubic-bezier(0, 0, 0, 0.99);
  }
  ${props =>
    props.isHidden &&
    css`
      border: 1px solid transparent;
      ${RomboRama} {
        display: none;
      }
      &:before,
      &:after {
        display: none;
      }
    `}
`
const Lens = styled.div`
  border-radius: 50%;
  width: 570%;
  height: 570%;
  text-transform: uppercase;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  z-index: 1;
  transform: translate(-50%, -50%) scale(0);
  background-image: url(${props => props.currentImage}?w=2880&h=1600);
  background-position: ${props => props.bgX}px ${props => props.bgY}px;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: 1280px;
  &:after {
    border: 0.4px solid #fff;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`
const MiniLens = styled.div`
  border-radius: 50%;
  width: 250%;
  height: 250%;
  opacity: 0;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%) scale(1);
  background-image: url(${props => props.currentImage}?w=2880&h=1600);
  background-position: ${props => props.bgXMiniLens}px
    ${props => props.bgYMiniLens}px;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: ${props => props.reducedBackgroundSize}px;
  box-sizing: border-box;
  &:after {
    border: 0.4px solid #fff;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`
const LabelContainer = styled.div`
  display: flex;
  flex-flow: ${props => (props.inverted ? 'row-reverse' : 'row')} nowrap;
`

const Label = styled.div`
  margin: 0 0.6px;
  background-color: #fff;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  align-items: center;
  white-space: pre;
  opacity: 0;
  padding: ${props => `${0.5 / 2.5}rem ${0.75 / 2.5}rem ${0.25 / 2.5}rem`};
  font-size: ${props => `${0.75 / 2.5}rem`};
  ${props =>
    props.yellow &&
    css`
      width: 11px;
      padding: 0;
      background-color: ${props => props.theme.colors.main};
    `}
  ${props =>
    props.close &&
    css`
      display: flex;
      justify-content: center;
      width: 11px;
      padding: 0;
    `}
  ${props =>
    props.grey &&
    css`
      color: #00000087;
    `}
  * svg {
    height: 5px;
    width: 5px;
    shape-rendering: geometricPrecision;
    * {
      shape-rendering: geometricPrecision;
    }
  }
`

const Description = styled.div`
  font-size: 7px;
  color: #fff;
  display: flex;
  width: 200px;
  opacity: 0;
  text-transform: uppercase;
  padding-top: 0.45rem;
  letter-spacing: 0.06rem;
  text-shadow: 1px 1px 5px #454545;
  ${props =>
    props.inverted
      ? css`
          padding-right: 1.4rem;
        `
      : css`
          padding-left: 1.4rem;
        `}
`

const mapStateToProps = state => ({
  currentID: getCurrentID(state),
  nextID: getNextID(state),
  isSliding: getIsSliding(state),
  activeDot: getActiveDot(state),
  direction: getDirection(state),
})

export default connect(
  mapStateToProps,
  {
    ...sliderActions,
    ...directionActions,
    ...mouseTrackerActions,
  },
)(LensTrigger)
