import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import DiamondColumn from './DiamondColumn'
import { connect } from 'react-redux'
import * as actions from '../../redux/customizer/actions'
import { getSelectedFeature } from '../../redux/customizer/reducer'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import Cta from '../Cta'

const SingleSelector = props => {
  const { selectFeature, selectedFeature, label, id, thumbnailUrl } = props
  const isActive = id === selectedFeature

  function handleClick() {
    selectFeature({ id, version: 0 })
  }

  return (
    <React.Fragment>
      <DiamondColumn hideBottomRombo />
      <Cta onClick={handleClick}>
        <ImageSelector isActive={isActive}>
          {isActive && <Title>{label}</Title>}
          <img src={thumbnailUrl} alt="" />
        </ImageSelector>
      </Cta>
    </React.Fragment>
  )
}

const CustomizeSelector = ({ selectFeature, selectedFeature }) => {
  const { customize } = useConsumerTranslation()
  const parentRef = useRef()

  return (
    <BoxContainer ref={parentRef}>
      {customize.map(selectorProps => (
        <SingleSelector
          {...selectorProps}
          key={selectorProps.id}
          selectFeature={selectFeature}
          selectedFeature={selectedFeature}
        />
      ))}
      <DiamondColumn hideLine={true} hideBottomRombo />
    </BoxContainer>
  )
}

const BoxContainer = styled.div`
  display: flex;
  height: 120px;
  opacity: 1;
  padding-top: 1rem;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: auto;
  }
`
const Title = styled.div`
  position: absolute;
  top: -10px;
  color: #fff;
  left: 0;
  margin-left: 3px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.06rem;
`
const ImageSelector = styled.div`
  border-style: none;
  height: 121px;
  padding: 11px 3px;
  box-sizing: border-box;
  position: relative;
  img {
    height: calc(100% - 11px);
    box-sizing: border-box;
    border: ${props =>
      props.isActive
        ? `1px solid ${props.theme.colors.main}`
        : `1px solid transparent`};
    @media (min-width: 1024px) {
      height: 100%;
    }
  }
`
export default connect(
  state => ({
    selectedFeature: getSelectedFeature(state),
  }),
  actions,
)(CustomizeSelector)
