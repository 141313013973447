import React, { useState, useContext, useEffect } from 'react'

const Context = React.createContext()

const checkMobile = target => target.innerWidth < 1024

const ProviderMobile = ({ children }) => {
  const [isMobile, setIsMobile] = useState(checkMobile(window))

  const handleResize = e => {
    setIsMobile(checkMobile(e.target))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <Context.Provider value={isMobile}>{children}</Context.Provider>
}

const useConsumerMobile = () => {
  const isMobile = useContext(Context)
  return isMobile
}

export { useConsumerMobile, ProviderMobile }
