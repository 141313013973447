import { INITIAL_LOADER_SET, INITIAL_LOADER_IMAGE_SET } from './types'

const defaultState = {
  isLoaded: false,
  images: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case INITIAL_LOADER_SET:
      return {
        ...state,
        isLoaded: true,
      }
    case INITIAL_LOADER_IMAGE_SET:
      return {
        ...state,
        images: [...state.images, action.payload],
      }
    default:
      return state
  }
}

export const isInitialLoaderLoaded = state => state.initialLoader.isLoaded
export const getInitialLoaderImages = state => state.initialLoader.images
