import { INITIAL_LOADER_SET, INITIAL_LOADER_IMAGE_SET } from './types'

export const setInitialLoader = payload => ({
  type: INITIAL_LOADER_SET,
})

export const setImageInitialLoader = payload => ({
  type: INITIAL_LOADER_IMAGE_SET,
  payload,
})
