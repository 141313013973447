import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Preload from 'react-preload'
import { Diamond } from '../svg'
import Cta from '../components/Cta'
import { useConsumerTranslation } from '../context/ContextTranslation'
import { useConsumerMobile } from '../context/ContextMobile'
import { connect } from 'react-redux'
import CloseWhenClickOutside from 'react-outside-click-handler'

import { AddressDescription } from '../components'
import './cutom_contact.css'

const Contacts = ({ contactPush }) => {
  const [activePoint, setActivePoint] = useState(false)
  const { contacts } = useConsumerTranslation()
  const isMobile = useConsumerMobile()

  const currentContact = contacts.list.find(item => item.id === activePoint)
  return (
    <Preload images={['/map.svg']}>
      <InnerPaddingWrapper>
        {contactPush === 'MAP' && (
          <DesktopContainer>
            {currentContact && (
              <CloseWhenClickOutside
                onOutsideClick={() => setActivePoint(null)}
              >
                <AddressDescription
                  closeAddress={() => setActivePoint(null)}
                  {...currentContact}
                />
              </CloseWhenClickOutside>
            )}
            {contacts.list.map(({ position, yellow = false, id }) => (
              <Cta onClick={() => {}} key={id}>
                <ContactWrapper
                  onClick={() => setActivePoint(id)}
                  {...position}
                  hide={activePoint && id !== activePoint}
                >
                  <Diamond width="22px" yellow={yellow} contacts />
                </ContactWrapper>
              </Cta>
            ))}
            <img src="/map.svg" alt="Map" />
          </DesktopContainer>
        )}
        <AddressWrapper>
          {contacts.list.map(({ position, id, ...contact }) => (
            <AddressDescription {...contact} key={id} isMobile={isMobile} />
          ))}
        </AddressWrapper>
      </InnerPaddingWrapper>
    </Preload>
  )
}

const DesktopContainer = styled.div`
  display: none;
  max-width: 100vw;
  position: relative;
  @media (min-width: 1024px) {
    display: block;
  }
`

const AddressWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 1024px) {
    display: none;
  }
`

const InnerPaddingWrapper = styled.div`
  height: calc(100% - 10px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: flex-start;
  overflow: hidden;
  padding: 0 11px 1rem;
  @media (min-width: 1024px) {
    height: 100%;
    align-content: center;
    padding: 0;
    height: 100vh;
  }
  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`
const ContactWrapper = styled.div`
  opacity: ${props => (props.hide ? 0 : 1)};
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 1;
  ${props =>
    `top: ${props.top}%;
    left:${props.left}%;`}
`
export default connect(state => ({
  contactPush: state.contactPush,
}))(Contacts)
