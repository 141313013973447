import styled from 'styled-components/macro'

export const Image = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center center;
  position: relative;
`
