import styled from 'styled-components/macro'

export const Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .video-react .video-react-big-play-button {
    display: none;
  }
  ${props =>
    props.fixedRatio &&
    `position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;`}

  video {
    display: block;
  }
`

export const VideoPlayer = styled.div``

export const PlayerSvgWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.image});
  background-position: center center;
  background-size: cover;
`

export const Ratio = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`

export const CloseVideoWrapper = styled.div`
  position: absolute;
  left: -17px;
  top: -17px;
`
