import React from 'react'

export default ({ size = '280px', color = '#FFFFFF' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 280 280"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Dotted Circle</title>
    <defs>
      <circle id="path-1" cx="230" cy="370" r="140" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="280"
        height="280"
        fill="white"
      >
        >
        <use xlinkHref="#path-1" />
      </mask>
    </defs>
    <g
      id="Dallara-Stradale"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeDasharray="1,10"
    >
      <g
        id="01---Loading"
        transform="translate(-580.000000, -230.000000)"
        stroke={color}
        strokeWidth="2"
      >
        <g id="Display" transform="translate(490.000000, 0.000000)">
          <g id="Circle">
            <use id="Forma" mask="url(#mask-2)" xlinkHref="#path-1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
