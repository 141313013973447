import { BURGER_OPEN, BURGER_CLOSE, IMAGE_CLOSE, IMAGE_OPEN } from './types'

const defaultState = {
  isBurgerOpen: false,
  isImageClosed: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BURGER_OPEN:
      return {
        ...state,
        isBurgerOpen: true,
      }
    case BURGER_CLOSE:
      return {
        ...state,
        isBurgerOpen: false,
      }
    case IMAGE_CLOSE:
      return {
        ...state,
        isImageClosed: true,
      }
    case IMAGE_OPEN:
      return {
        ...state,
        isImageClosed: false,
      }
    default:
      return state
  }
}

export const getIsBurgerOpen = state => state.burger.isBurgerOpen
export const getIsImageClosed = state => state.burger.isImageClosed
