import styled, { css } from 'styled-components/macro'

export const RouteChangingWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`
export const MobileWrapper = styled.div`
  background: black;
  transform: ${props => (props.isBurgerOpen ? 'translateY(60px)' : 'none')};
  transition: transform 0.3s ease-in-out;
`

export const HudBox = styled.div`
  /* transform: ${props =>
    props.isBurgerOpen ? 'translateY(60px)' : 'none'}; */
  transition: 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  &:before {
    content: '';
    background: linear-gradient(to bottom,rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.05) 45%,transparent 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
    &:before,
    &:after {
      position: absolute;
      height: auto;
      width: 340px;
      top: -40px;
      bottom: -40px;
    }
    &:before {
      left: -40px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.4) 60%,
        transparent 100%
      );
    }
    &:after {
      content: '';
      right: -40px;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.4) 60%,
        transparent 100%
      );
      z-index: -1;
    }
    ${props =>
      props.hideSideShadow &&
      css`
        &:before {
          background: none;
        }
        &:after {
          background: none;
        }
      `}
  }

  > * {
    pointer-events: none;
  }

  .bottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -4px;
    margin-right: -4px;
  }
  .bottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -4px;
    margin-left: -4px;
  }
`
