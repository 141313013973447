import { ACTIVE_DOT_SET, ACTIVE_DOT_UNSET } from './types'

export default (state = null, action) => {
  switch (action.type) {
    case ACTIVE_DOT_SET:
      return action.payload
    case ACTIVE_DOT_UNSET:
      return null
    default:
      return state
  }
}

export const getActiveDot = state => state.slider.activeDot
