import React from 'react'

export default ({ size = '6px', color = '#FFFFFF' }) => {
  return (
    <svg
      width="65px"
      height="65px"
      style={{
        width: '100%',
        height: '100%',
        padding: '39px',
        boxSizing: 'border-box',
        backgroundColor: '#0000003b',
      }}
      viewBox="0 0 65 65"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Play</title>
      <defs>
        <rect id="path-1" x="20" y="20" width="25" height="25" />
        <filter
          x="-28.0%"
          y="-20.0%"
          width="156.0%"
          height="156.0%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="shadowInner"
          />
          <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner" />
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Dallara-Stradale"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="11---Dynamics---Highlight"
          transform="translate(-1241.000000, -596.000000)"
        >
          <g id="Video" transform="translate(1134.000000, 509.000000)">
            <g id="Play" transform="translate(107.000000, 87.000000)">
              <rect
                id="Forma"
                strokeOpacity="0.2"
                stroke="#FFFFFF"
                transform="translate(32.500000, 32.500000) rotate(45.000000) translate(-32.500000, -32.500000) "
                x="10.5"
                y="10.5"
                width="44"
                height="44"
              />
              <g
                id="Forma"
                transform="translate(32.500000, 32.500000) rotate(45.000000) translate(-32.500000, -32.500000) "
              >
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                />
                <rect
                  strokeOpacity="0.8"
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  strokeLinejoin="square"
                  x="20.5"
                  y="20.5"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="Icon"
                transform="translate(31.000000, 28.000000)"
                fill="#FFFFFF"
              >
                <g id="02">
                  <rect id="Forma" x="4" y="4" width="1" height="1" />
                  <rect id="Forma" x="3" y="3" width="1" height="1" />
                  <rect id="Forma" x="2" y="2" width="1" height="1" />
                  <rect id="Forma" x="1" y="1" width="1" height="1" />
                  <rect id="Forma" x="0" y="0" width="1" height="1" />
                </g>
                <g id="01" transform="translate(0.000000, 5.000000)">
                  <rect id="Forma" x="3" y="0" width="1" height="1" />
                  <rect id="Forma" x="2" y="1" width="1" height="1" />
                  <rect id="Forma" x="1" y="2" width="1" height="1" />
                  <rect id="Forma" x="0" y="3" width="1" height="1" />
                </g>
                <rect id="Forma" x="0" y="1" width="1" height="7" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
