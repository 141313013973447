import React from 'react'
import styled from 'styled-components/macro'

const SvgCompo = styled.svg`
  width: ${props => props.size};
  height: ${props => props.size};
`

export default ({ size = '8px', className, fill = '#FFF', ...allTheRest }) => {
  return (
    <SvgCompo
      className={className}
      size={size}
      viewBox="0 0 4 3"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...allTheRest}
    >
      <g
        id="Dallara-Stradale"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01---Loading"
          transform="translate(-718.000000, -181.000000)"
          fill={fill}
        >
          <g id="Display" transform="translate(490.000000, 0.000000)">
            <g id="Elements" transform="translate(41.000000, 181.000000)">
              <rect
                id="Forma"
                transform="translate(189.000000, 1.414214) rotate(45.000000) translate(-189.000000, -1.414214) "
                x="188"
                y="0.414213562"
                width="2"
                height="2"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgCompo>
  )
}
