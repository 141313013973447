import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from './types'

const defaultState = {
  isSidebarOpen: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: true,
      }
    case SIDEBAR_CLOSE:
      return {
        ...state,
        isSidebarOpen: false,
      }
    default:
      return state
  }
}

export const getIsSidebarOpen = state => state.sidebar.isSidebarOpen
