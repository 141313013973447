import styled, { css } from 'styled-components/macro'
import { isFirefox } from 'react-device-detect'

export const HighlightText = styled.div`
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  ${!isFirefox &&
    css`
      filter: blur(40px);
    `}
`

export const TextWrapper = styled.div`
  color: white;
  position: relative;
  max-width: calc(100% - 20px);
  @media (min-width: 1024px) {
    max-width: 650px;
  }
`

export const TextInner = styled.div`
  padding: 2rem;
  color: white;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-size: 1.125rem;
  letter-spacing: 0.06rem;
`
export const Background = styled.div`
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
`
