import { combineReducers } from 'redux'

import direction from './direction/reducer'
import currentID from './currentID/reducer'
import nextID from './nextID/reducer'
import isSliding from './isSliding/reducer'
import dots from './dots/reducer'
import keyMessage from './keyMessage/reducer'

export default combineReducers({
  direction,
  currentID,
  nextID,
  isSliding,
  activeDot: dots,
  keyMessage,
})
