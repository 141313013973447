import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as S from './styles'
import * as actions from '../../redux/customizer/actions'
import CompareMenu from './CompareMenu'
import GridElementWrapper from '../GridElementWrapper'
import Cta from '../Cta'
import {
  getSelectedFeature,
  getSelectedModifiers,
  getComparingSelectedFeature,
  getComparingSelectedModifiers,
  getIsShowingFeatureSelect,
} from '../../redux/customizer/reducer'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { DiamondSingle, SelectorActive } from '../../svg'
import theme from '../../theme'

const DiamondToggle = React.forwardRef(({ isActive, color, noPulse }, ref) => (
  <S.DiamondWrapper ref={ref}>
    {isActive ? (
      <SelectorActive fill={color} size={'20px'} noPulse={noPulse} />
    ) : (
      <DiamondSingle fill={theme.colors.white30} size={'12px'} />
    )}
  </S.DiamondWrapper>
))

const Modifier = props => {
  const {
    selectedModifiers,
    modifier,
    toggle,
    color,
    disabled,
    stopAnimation,
    handleClick,
  } = props
  const { id, defaultName, activeName } = modifier
  const isActive = selectedModifiers && selectedModifiers.indexOf(id) > -1
  const toggleToAnimate = useRef(null)

  function onClick() {
    handleClick()
    if (!disabled) {
      toggle(id)
    }
  }

  return (
    <S.Modifier disabled={disabled}>
      <Cta onClick={onClick}>
        <DiamondToggle
          isActive={!isActive}
          color={color}
          ref={toggleToAnimate}
          noPulse={stopAnimation}
        />
        <DiamondToggle isActive={isActive} color={color} />
        <S.ModifierText>{isActive ? activeName : defaultName}</S.ModifierText>
      </Cta>
    </S.Modifier>
  )
}

const CompareItemsResume = props => {
  const {
    addCompare,
    selectFeature,
    selectedFeature,
    toggleModifier,
    selectedModifiers,
    compareSelectedModifiers,
    compareSelectedFeature,
    isShowingFeatureSelect,
    show,
  } = props
  const { colors } = theme
  const { customize, modifiers } = useConsumerTranslation()
  const [isFirstModifierClicked, setIsFirstModifierClicked] = useState(false)
  const [isSecondModifierClicked, setIsSecondModifierClicked] = useState(false)

  if (!show) return null

  function addModel(id) {
    addCompare()
    selectFeature({ id, version: 1 })
  }

  function toggle(id, version) {
    toggleModifier({ id, version })
  }

  function stopFirstModifierAnimation() {
    setIsFirstModifierClicked(true)
  }

  function stopSecondModifierAnimation() {
    setIsSecondModifierClicked(true)
  }

  return (
    <>
      <S.Flex>
        <GridElementWrapper />
        <CompareMenu
          color="main"
          modelList={customize}
          id={selectedFeature}
          hideList
        />
        <CompareMenu
          color="blue"
          modelList={customize}
          addModel={addModel}
          id={compareSelectedFeature}
        />
      </S.Flex>
      <S.CompareItemsResume disabled={isShowingFeatureSelect}>
        <S.Wrapper>
          <S.ModifiersWrapper>
            {modifiers.map((item, index) => (
              <Modifier
                stopAnimation={isFirstModifierClicked}
                handleClick={stopFirstModifierAnimation}
                color={colors.main}
                key={item.id}
                index={index}
                selectedModifiers={selectedModifiers}
                modifier={item}
                toggle={id => toggle(id, 0)}
              />
            ))}
          </S.ModifiersWrapper>
          <S.ModifiersWrapper>
            {modifiers.map(item => (
              <Modifier
                stopAnimation={isSecondModifierClicked}
                handleClick={stopSecondModifierAnimation}
                disabled={!compareSelectedFeature}
                color={colors.blue}
                key={item.id}
                selectedModifiers={compareSelectedModifiers}
                modifier={item}
                toggle={id => toggle(id, 1)}
              />
            ))}
          </S.ModifiersWrapper>
        </S.Wrapper>
      </S.CompareItemsResume>
    </>
  )
}

export default connect(
  state => ({
    selectedFeature: getSelectedFeature(state),
    selectedModifiers: getSelectedModifiers(state),
    compareSelectedModifiers: getComparingSelectedModifiers(state),
    compareSelectedFeature: getComparingSelectedFeature(state),
    isShowingFeatureSelect: getIsShowingFeatureSelect(state),
  }),
  actions,
)(CompareItemsResume)
