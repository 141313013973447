import React from 'react'
import ReactDOM from 'react-dom'
import { ProviderTranslation } from './context/ContextTranslation'
import { ProviderMobile } from './context/ContextMobile'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components/macro'
import { Normalize } from 'styled-normalize'
import { createStore } from 'redux'
import './index.css'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'
import GlobalStyle from './GlobalTheme'
import theme from './theme'
import rootReducer from './redux'

// eslint-disable-next-line
import iNoBounce from 'inobounce'

import 'promise-polyfill/src/polyfill'

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

const Root = () => (
  <Provider store={store}>
    <Normalize />
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ProviderMobile>
        <ProviderTranslation locale={process.env.REACT_APP_CURRENT_LANGUAGE}>
          <Routes />
        </ProviderTranslation>
      </ProviderMobile>
    </ThemeProvider>
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your Routes to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
