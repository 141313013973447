import React from 'react'

const SvgBurger = props => (
  <svg width={25} height={15} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M24 7h1v1h-1zM20 7h1v1h-1zM16 7h1v1h-1zM12 7h1v1h-1z" />
      <path d="M12 7h1v1h-1zM8 7h1v1H8zM4 7h1v1H4zM0 7h1v1H0zM0 14h25v1H0zM0 0h25v1H0z" />
    </g>
  </svg>
)

export default SvgBurger
