import React, { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import Toggler from '../Toggler'
import { animateRightMenu, animateRightMenuReverse } from './animations'

const ExploreMore = ({ onClick, nextID, show, label, isPushOn }) => {
  if (!show) return null
  const exporeMoreWrapper = useRef(null)

  useEffect(() => {
    if (!exporeMoreWrapper || !exporeMoreWrapper.current) return
    if (isPushOn) {
      animateRightMenuReverse(exporeMoreWrapper.current.children)
    } else {
      animateRightMenu(exporeMoreWrapper.current.children)
    }
  }, [isPushOn])

  return (
    <ExploreMoreWrapper ref={exporeMoreWrapper}>
      <Toggler onClick={onClick} text={label} />
    </ExploreMoreWrapper>
  )
}

export default ExploreMore

const ExploreMoreWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -17px;
  transform: translate(0, -50%);
`
