import React from 'react'
import * as S from './style'
import { DiamondSingle } from '../../svg'

const GridElementWrapper = () => (
  <>
    <S.TopLeft>
      <DiamondSingle />
    </S.TopLeft>
    <S.TopRight>
      <DiamondSingle />
    </S.TopRight>
    <S.BottomLeft>
      <DiamondSingle />
    </S.BottomLeft>
    <S.BottomRight>
      <DiamondSingle />
    </S.BottomRight>
    <S.LeftLine>
      <S.Top />
      <S.Bottom />
    </S.LeftLine>
    <S.RightLine>
      <S.Top />
      <S.Bottom />
    </S.RightLine>
    <S.TopLine>
      <S.Left />
      <S.Right />
    </S.TopLine>
    <S.BottomLine>
      <S.Left />
      <S.Right />
    </S.BottomLine>
  </>
)

export default GridElementWrapper
