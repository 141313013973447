import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { Home, Customize, Gallery, FullImage, Contacts } from './views'
import { Layout } from './components'
import { useConsumerTranslation } from './context/ContextTranslation'

const RouteWithLayout = props => (
  <Layout {...props}>
    <Route {...props} />
  </Layout>
)

export default () => {
  const {
    routes: { CUSTOMIZE, GALLERY, CONTACT, FULLIMAGE, HOME },
  } = useConsumerTranslation()

  return (
    <BrowserRouter>
      <Switch>
        <RouteWithLayout exact path={CUSTOMIZE.path} component={Customize} />
        <RouteWithLayout exact path={GALLERY.path} component={Gallery} />
        <RouteWithLayout exact path={CONTACT.path} component={Contacts} />
        <RouteWithLayout exact path={FULLIMAGE.path} component={FullImage} />
        <RouteWithLayout exact path={HOME.path} component={Home} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  )
}
