export default [
  {
    type: 'video',
    videoId: 'vUSJpYjy4yU',
    loop: 'http://dallara.imgix.net/loop/mobile/19.mp4',
    id: 'v19',
  },
  {
    type: 'video',
    videoId: 'M6uosQ5Z3Tc',
    loop: 'http://dallara.imgix.net/loop/mobile/18.mp4',
    id: 'v18',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-25.jpg',
    id: 25,
  },
  {
    type: 'video',
    videoId: 'j2H5l7SgIN4',
    loop: 'http://dallara.imgix.net/loop/mobile/16.mp4',
    id: 'v16',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-8.jpg',
    id: 8,
  },
  {
    type: 'video',
    videoId: 'R3Cqbcg-0l0',
    loop: 'http://dallara.imgix.net/loop/mobile/17.mp4',
    id: 'v17',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-46.jpg',
    id: 46,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-47.jpg',
    id: 47,
  },
  {
    type: 'video',
    videoId: 'gr478KwLJ70',
    loop: 'http://dallara.imgix.net/loop/mobile/11.mp4',
    id: 'v9',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-14.jpg',
    id: 14,
  },
  {
    type: 'video',
    videoId: '9WN_0kHafT0',
    loop: 'http://dallara.imgix.net/loop/mobile/06.mp4',
    id: 'v7',
  },
  {
    type: 'video',
    videoId: 'HcB2nbBT67I',
    loop: 'http://dallara.imgix.net/loop/mobile/02.mp4',
    id: 'v10',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-11.jpg',
    id: 11,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-2.jpg',
    id: 2,
  },
  {
    type: 'video',
    videoId: 'TQ9tqlsNQ0U',
    loop: 'http://dallara.imgix.net/loop/mobile/01.mp4',
    id: 'v5',
  },
  {
    type: 'video',
    videoId: 'CNjq0fF8NHc',
    loop: 'http://dallara.imgix.net/loop/mobile/07.mp4',
    id: 'v11',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-16.jpg',
    id: 16,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-34.jpg',
    id: 34,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-24.jpg',
    id: 24,
  },
  {
    type: 'video',
    videoId: 'PnunV_eVlw8',
    loop: 'http://dallara.imgix.net/loop/mobile/13.mp4',
    id: 'v8',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-28.jpg',
    id: 28,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-30.jpg',
    id: 30,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-20.jpg',
    id: 20,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-42.jpg',
    id: 42,
  },
  {
    type: 'video',
    videoId: 'OFK2PPsdcZ0&t=479s',
    loop: 'http://dallara.imgix.net/loop/mobile/08.mp4',
    id: 'v6',
  },
  {
    type: 'video',
    videoId: '7yc9n1MlmLU',
    loop: 'http://dallara.imgix.net/loop/mobile/10.mp4',
    id: 'v2',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-18.jpg',
    id: 18,
  },
  {
    type: 'video',
    videoId: 'VQWMZtk6_SA&t=205s',
    loop: 'http://dallara.imgix.net/loop/mobile/05.mp4',
    id: 'v3',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-50.jpg',
    id: 50,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-3.jpg',
    id: 3,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-27.jpg',
    id: 27,
  },
  {
    type: 'video',
    videoId: '2GJqi89rykQ',
    loop: 'http://dallara.imgix.net/loop/mobile/09.mp4',
    id: 'v1',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-12.jpg',
    id: 12,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-44.jpg',
    id: 44,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-1.jpg',
    id: 1,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-38.jpg',
    id: 38,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-19.jpg',
    id: 19,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-10.jpg',
    id: 10,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-39.jpg',
    id: 39,
  },
  {
    type: 'video',
    videoId: 'XUuoAfNSGbM',
    loop: 'http://dallara.imgix.net/loop/mobile/12.mp4',
    id: 'v12',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-15.jpg',
    id: 15,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-5.jpg',
    id: 5,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-33.jpg',
    id: 33,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-7.jpg',
    id: 7,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-9.jpg',
    id: 9,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-4.jpg',
    id: 4,
  },
  {
    type: 'video',
    videoId: 'P1HLY7Z6ZJk',
    loop: 'http://dallara.imgix.net/loop/mobile/15.mp4',
    id: 'v15',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-37.jpg',
    id: 37,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-35.jpg',
    id: 35,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-36.jpg',
    id: 36,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-31.jpg',
    id: 31,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-48.jpg',
    id: 48,
  },
  {
    type: 'video',
    videoId: 'grXkFwpqXIc&t=199s',
    loop: 'http://dallara.imgix.net/loop/mobile/04.mp4',
    id: 'v1',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-32.jpg',
    id: 32,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-29.jpg',
    id: 29,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-52.jpg',
    id: 52,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-41.jpg',
    id: 41,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-13.jpg',
    id: 13,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-26.jpg',
    id: 26,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-51.jpg',
    id: 51,
  },
  {
    type: 'video',
    videoId: 'F00EmPErlIc',
    loop: 'http://dallara.imgix.net/loop/mobile/14.mp4',
    id: 'v14',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-22.jpg',
    id: 22,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-45.jpg',
    id: 45,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-17.jpg',
    id: 17,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-21.jpg',
    id: 21,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-23.jpg',
    id: 23,
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-40.jpg',
    id: 40,
  },
  {
    type: 'video',
    videoId: 'nLbwy0bZGE4',
    loop: 'http://dallara.imgix.net/loop/mobile/03.mp4',
    id: 'v4',
  },
  {
    type: 'image',
    url: 'https://dallara.imgix.net/gallery/dallara-stradale-6.jpg',
    id: 6,
  },
]
