import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import DiamondFrame from '../DiamondFrame'
import GenericPush from '../GenericPush'

const BottomBoxContacts = () => {
  const [activeButton, setActiveButton] = useState('MAP')

  return (
    <React.Fragment>
      <BottomBoxContainer>
        <DiamondFrame alignLines noBorder withSegments />
        <Container>
          <Flex>
            <GenericPush
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
          </Flex>
        </Container>
      </BottomBoxContainer>
    </React.Fragment>
  )
}

const Flex = styled.div`
  flex: 1 0 50%;
`

const BottomBoxContainer = styled.div`
  display: flex;
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  right: auto;
  display: block;
  @media (min-width: 1024px) {
    width: 200px;
  }
`
const Container = styled.div`
  display: flex;
  position: relative;
  padding: 0.5rem;
  flex: 1;
  height: 40px;
  align-items: center;
  @media (min-width: 400px) {
    height: initial;
  }
  @media (min-width: 1024px) {
    opacity: 1;
  }
  ${props =>
    props.disable &&
    css`
      div {
        color: rgba(255, 255, 255, 0.3);
      }
    `}
`

export default BottomBoxContacts
