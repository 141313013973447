import { KEYMESSAGE_SET, KEYMESSAGE_UNSET } from './types'

const defaultState = false
export default (state = defaultState, action) => {
  switch (action.type) {
    case KEYMESSAGE_SET:
      return true
    case KEYMESSAGE_UNSET:
      return false
    default:
      return state
  }
}

export const getIsKeyMessageSet = state => state.slider.keyMessage
