import React from 'react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts'

import * as S from './styles'
import theme from '../../theme'

export default ({ data, dataKeyToCompare }) => {
  const { colors } = theme

  return (
    <S.RadarChartWrapper>
      <RadarChart
        cx={140}
        cy={140}
        outerRadius={100}
        width={300}
        height={280}
        data={data}
      >
        <PolarGrid
          gridType="circle"
          strokeWidth="1"
          strokeDasharray={'0, 8'}
          strokeLinecap="round"
        />
        <PolarRadiusAxis domain={[0, 100]} axisLine={false} tick={false} />
        <PolarAngleAxis
          dataKey="label"
          tick={{
            fill: 'white',
            fontSize: '0.5rem',
            textTransform: 'uppercase',
            letterSpacing: '0.06rem',
          }}
        />
        <Radar
          dataKey="value"
          stroke="yellow"
          fill={colors.main}
          fillOpacity={0.3}
          animationDuration={500}
        />
        {dataKeyToCompare && (
          <Radar
            dataKey={dataKeyToCompare}
            stroke={colors.blue}
            fill={colors.blue}
            fillOpacity={0.3}
            animationDuration={500}
          />
        )}
      </RadarChart>
    </S.RadarChartWrapper>
  )
}
