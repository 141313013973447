import React from 'react'

export default () => (
  <svg
    width="33px"
    height="33px"
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Indicator</title>
    <g
      id="Dallara-Stradale"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="07---Homepage" transform="translate(-1393.000000, -354.000000)">
        <g id="Explore-More" transform="translate(1284.000000, 354.000000)">
          <g id="Indicator" transform="translate(109.000000, 0.000000)">
            <rect
              id="Forma"
              fillOpacity="0.4"
              fill="#FFFFFF"
              transform="translate(16.500000, 16.500000) rotate(45.000000) translate(-16.500000, -16.500000) "
              x="5"
              y="5"
              width="23"
              height="23"
            />
            <rect
              id="Forma"
              fillOpacity="0.6"
              fill="#FFFFFF"
              transform="translate(16.500000, 16.500000) rotate(45.000000) translate(-16.500000, -16.500000) "
              x="9"
              y="9"
              width="15"
              height="15"
            />
            <rect
              id="Forma"
              fill="#FFFFFF"
              transform="translate(16.500000, 16.500000) rotate(45.000000) translate(-16.500000, -16.500000) "
              x="12"
              y="12"
              width="9"
              height="9"
            />
            <rect
              id="Forma"
              fill="#000000"
              transform="translate(16.500000, 16.500000) rotate(45.000000) translate(-16.500000, -16.500000) "
              x="15"
              y="15"
              width="3"
              height="3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
