import { combineReducers } from 'redux'
import slider from './slider'
import initialLoader from './initialLoader/reducer'
import sidebar from './sidebar/reducer'
import mouseTracker from './mouseTracker/reducer'
import customizer from './customizer/reducer'
import burger from './burger/reducer'
import push from './push/reducer'
import contactPush from './contactPush/reducer'

const reducer = combineReducers({
  slider,
  initialLoader,
  sidebar,
  mouseTracker,
  customizer,
  burger,
  push,
  contactPush,
})

export default reducer
