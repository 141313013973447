import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import * as S from './styles'
import {
  isMouseActive,
  isMouseClose,
  isMouseDisabled,
  isMouseDarkModeOn,
} from '../../redux/mouseTracker/reducer'

const CursorInner = props => (
  <>
    <S.CursorSmall {...props} />
    <S.CursorClose {...props} />
    <S.CursorBig {...props} />
  </>
)

const CursorInnerConnected = connect(state => ({
  isMouseActive: isMouseActive(state),
  isMouseDisabled: isMouseDisabled(state),
  isMouseClose: isMouseClose(state),
  mouseDarkMode: isMouseDarkModeOn(state),
}))(CursorInner)

const Cursor = props => (
  <S.CursorWrapper {...props}>
    <CursorInnerConnected {...props} />
  </S.CursorWrapper>
)

const CursorThatPreventChildrenBeeningRerendered = ({ children, ...props }) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [darkMode] = useState(false)

  useEffect(() => {
    let mouseMove = window.addEventListener('mousemove', e => {
      setCoords({ x: e.clientX, y: e.clientY })
    })
    return () => {
      window.removeEventListener('mousemove', mouseMove)
    }
  }, [])

  return (
    <>
      <Cursor {...props} coords={coords} darkMode={darkMode} />
      {children}
    </>
  )
}

export default ({ children }) => {
  return (
    <div
      style={{
        cursor: 'none',
      }}
    >
      <CursorThatPreventChildrenBeeningRerendered children={children} />
    </div>
  )
}
