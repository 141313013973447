import React from 'react'

export default () => (
  <svg
    width="33px"
    height="33px"
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Close</title>
    <g
      id="Dallara-Stradale"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="15---Aerodynamics---Explore-More---Desktop"
        transform="translate(-464.000000, -354.000000)"
      >
        <g id="Container" transform="translate(464.000000, 0.000000)">
          <g id="Close" transform="translate(0.000000, 354.000000)">
            <g id="Indicator" fill="#FFFFFF">
              <rect
                id="Forma"
                transform="translate(16.500000, 16.500000) rotate(45.000000) translate(-16.500000, -16.500000) "
                x="5"
                y="5"
                width="23"
                height="23"
              />
            </g>
            <g
              id="Icon"
              transform="translate(12.000000, 12.000000)"
              fill="#000000"
            >
              <g id="02">
                <rect id="Forma" x="8" y="8" width="1" height="1" />
                <rect id="Forma" x="7" y="7" width="1" height="1" />
                <rect id="Forma" x="6" y="6" width="1" height="1" />
                <rect id="Forma" x="5" y="5" width="1" height="1" />
                <rect id="Forma" x="4" y="4" width="1" height="1" />
                <rect id="Forma" x="3" y="3" width="1" height="1" />
                <rect id="Forma" x="2" y="2" width="1" height="1" />
                <rect id="Forma" x="1" y="1" width="1" height="1" />
                <rect id="Forma" x="0" y="0" width="1" height="1" />
              </g>
              <g id="01">
                <rect id="Forma" x="8" y="0" width="1" height="1" />
                <rect id="Forma" x="7" y="1" width="1" height="1" />
                <rect id="Forma" x="6" y="2" width="1" height="1" />
                <rect id="Forma" x="5" y="3" width="1" height="1" />
                <rect id="Forma" x="4" y="4" width="1" height="1" />
                <rect id="Forma" x="3" y="5" width="1" height="1" />
                <rect id="Forma" x="2" y="6" width="1" height="1" />
                <rect id="Forma" x="1" y="7" width="1" height="1" />
                <rect id="Forma" x="0" y="8" width="1" height="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
