import slides from './slides'
import gallery from './gallery'
import various from './various'
import routes from './routes'
import contacts from './contacts'
import modifiers from './modifiers'
import customize, { customizeExploreMore } from './customize'

export default {
  slides,
  gallery,
  various,
  routes,
  contacts,
  customize,
  customizeExploreMore,
  modifiers,
}
