import { TimelineMax } from 'gsap/TimelineMax'
import CustomEase from '../../utils/CustomEase'

const ease = CustomEase.create(
  'custom',
  'M0,0 C0.25,0 0.297,0.046 0.335,0.078 0.436,0.162 0.438,0.302 0.47,0.512 0.512,0.86 0.53,0.898 0.584,0.946 0.623,0.98 0.698,1 1,1',
)

let lensOpened = []
let originalImage = null

export const openLens = (
  lensToOpen,
  bigLensToOpen,
  descriptionEl,
  labelContainer,
) => {
  var lensAnimation = new TimelineMax()
  lensOpened = [
    ...lensOpened,
    { lensToOpen, bigLensToOpen, descriptionEl, labelContainer },
  ]

  if (
    lensToOpen.current === null ||
    bigLensToOpen.current === null ||
    descriptionEl.current === null ||
    labelContainer.current === null
  )
    return false

  lensAnimation
    .to(lensToOpen.current, 0.8, {
      scale: 1,
      opacity: 1,
      ease,
    })
    .to(
      bigLensToOpen.current,
      0.8,
      {
        scale: 1,
        opacity: 1,
        ease,
      },
      '-=0.6',
    )
    .staggerTo(
      [...labelContainer.current.children, descriptionEl.current],
      0.2,
      {
        opacity: 1,
        // ease
      },
      0.1,
    )
}

export const closeLense = ({
  lensToOpen,
  bigLensToOpen,
  labelContainer,
  descriptionEl,
}) => {
  if (!lensToOpen || !lensToOpen.current) return
  if (!bigLensToOpen || !bigLensToOpen.current) return
  if (
    !labelContainer ||
    !labelContainer.current ||
    !labelContainer.current.children
  )
    return
  var lensAnimation = new TimelineMax()

  lensAnimation
    .staggerTo(
      [...labelContainer.current.children, descriptionEl.current],
      0.2,
      {
        opacity: 0,
        // ease
      },
      -0.1,
    )
    .to(bigLensToOpen.current, 0.5, {
      scale: 0,
      opacity: 0,
      ease,
    })
    .to(
      lensToOpen.current,
      0.5,
      {
        scale: 1,
        opacity: 0,
        ease,
      },
      '-=0.5',
    )
}

export const doAnimate = ({
  miniLens,
  mainImage,
  bigLens,
  translation,
  descriptionEl,
  labelContainer,
}) => {
  if (lensOpened && lensOpened.length > 0) {
    lensOpened.forEach(obj => {
      closeLense(obj)
    })
  }

  const SCALE = 2.5
  if (
    mainImage.current === null ||
    miniLens.current === null ||
    bigLens.current === null ||
    descriptionEl.current === null ||
    labelContainer.current === null
  )
    return false
  // if (zoomLevel === SCALE) return false
  originalImage = mainImage
  var toPallocco = new TimelineMax()

  toPallocco.to(mainImage.current, 1, {
    x: translation.x * SCALE,
    y: translation.y * SCALE,
    scale: SCALE,
    force3D: false,
    ease,
    onComplete: () => {
      openLens(miniLens, bigLens, descriptionEl, labelContainer)
    },
  })
}

export const backToStart = ({ onComplete }) =>
  new Promise((resolve, reject) => {
    if (!originalImage || originalImage.current === null) {
      resolve()
      return false
    }
    lensOpened.forEach(obj => {
      closeLense(obj)
    })
    var toPallocco = new TimelineMax()

    toPallocco.to(originalImage.current, 1, {
      x: 0,
      y: 0,
      scale: 1,
      force3D: false,
      transformOrigin: 'center center',
      ease,
      onComplete: () => {
        lensOpened = []
        onComplete()
        resolve()
      },
    })
  })
