import React, { useContext } from 'react'

import it from '../data/it'
import en from '../data/en'

const data = {
  it,
  en,
}

const Context = React.createContext()

function ProviderTranslation({ children, locale }) {
  return <Context.Provider value={data[locale]}>{children}</Context.Provider>
}

function useConsumerTranslation() {
  const data = useContext(Context)
  return data
}

export { useConsumerTranslation, ProviderTranslation }
