import React from 'react'

export default ({
  size = '350px',
  color = '#FFFFFF',
  strokeDasharray,
  strokeDashoffset,
  strokeWidth = 1,
  strokeOpacity = 1,
  transform,
  transformOrigin = 'center center',
  innerRef,
  withClassName,
  style,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 350 350"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Circle</title>
    <g
      ref={innerRef}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      stroke="none"
      strokeWidth={strokeWidth}
      fill="none"
      fillRule="evenodd"
      strokeOpacity={strokeOpacity}
      transform={transform}
      transform-origin={transformOrigin}
      style={style}
    >
      <g
        transform="translate(-545.000000, -195.000000)"
        stroke={color}
        className={withClassName && 'circle'}
      >
        <g transform="translate(490.000000, 0.000000)">
          <g id="Circle">
            <circle id="Forma" cx="230" cy="370" r="174.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
