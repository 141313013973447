import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMyMouseTrackerPositionInPercentage } from '../customHooks'
import * as mouseTrackerActions from '../redux/mouseTracker/actions'
import * as burgerActions from '../redux/burger/actions'
import { useConsumerTranslation } from '../context/ContextTranslation'
import { useConsumerMobile } from '../context/ContextMobile'
import { useMagicTranslation } from '../utils/utils'
import { getIsImageClosed } from '../redux/burger/reducer'

const FullImage = ({
  match,
  setMouseClose,
  setMouseDefault,
  closeImage,
  isImageClosed,
  openImage,
  onMouseLeave,
}) => {
  const myFullScreenImage = useRef()
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [imageToBeLoaded, setImageToBeLoaded] = useState(null)
  const { gallery } = useConsumerTranslation()
  const isMobile = useConsumerMobile()

  const currentImageID = Number(match.params.id)

  const imageOpened = useMemo(() => {
    return gallery.find(image => image.id === currentImageID)
  }, [currentImageID])

  if (!imageOpened) return <Redirect to="/gallery" />

  const [next, setNext] = useState(null)

  const { url } = imageOpened

  const { x, y, screenX, screenY } = useMyMouseTrackerPositionInPercentage()
  const { translateX, translateY } = useMagicTranslation({
    ref: myFullScreenImage,
    x,
    y,
    screenX,
    screenY,
  })

  const size = isMobile ? window.innerHeight : window.innerWidth
  const dimension = isMobile ? 'h' : 'w'

  useEffect(() => {
    setMouseClose()
    onMouseLeave()
    return () => {
      openImage()
      setMouseDefault()
    }
  }, [])

  useEffect(() => {
    setIsImageLoaded(false)

    if (imageToBeLoaded) {
      imageToBeLoaded.src = ''
    }

    const DOMImage = new Image()

    DOMImage.onload = () => {
      setIsImageLoaded(true)
    }

    DOMImage.src = `${url}?auto=compress,format&${dimension}=${size *
      window.devicePixelRatio}`

    setImageToBeLoaded(DOMImage)
  }, [currentImageID])

  useEffect(() => {
    const handleKeyUp = e => {
      const currentImageIndex = gallery.indexOf(imageOpened)

      if (e.keyCode === 39) {
        if (currentImageIndex + 1 === gallery.length) return
        const { id } = gallery[currentImageIndex + 1]
        setNext(id)
      }

      if (e.keyCode === 37) {
        if (currentImageIndex === 0) return
        const { id } = gallery[currentImageIndex - 1]
        setNext(id)
      }

      if (e.keyCode === 27) {
        closeImage()
      }
    }

    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  })

  if (isImageClosed) return <Redirect to="/gallery" />

  if (next && next !== currentImageID)
    return <Redirect to={`/fullimage/${next}`} />

  return (
    <FullScreenImageWrapper onClick={() => !isMobile && closeImage()}>
      {isImageLoaded ? (
        <FullScreenImage
          src={`${url}?auto=compress,format&${dimension}=${size}`}
          translateX={translateX}
          translateY={translateY}
          ref={myFullScreenImage}
          srcSet={`${url}?auto=compress,format&${dimension}=${size *
            3} 3x, ${url}?auto=compress,format&${dimension}=${size * 2} 2x`}
        />
      ) : (
        <LoadingImage
          style={
            isMobile
              ? {
                  height: size,
                }
              : {
                  width: size,
                }
          }
          src={`${url}?auto=compress&${dimension}=250`}
          translateX={translateX}
          translateY={translateY}
          ref={myFullScreenImage}
        />
      )}
    </FullScreenImageWrapper>
  )
}

const FullScreenImageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  position: fixed;
  top: 0;
`

const FullScreenImage = styled.img.attrs(({ translateX, translateY }) => ({
  style: {
    transform: `translate(${translateX}px, ${translateY}px)`,
  },
}))`
  display: block;
  position: fixed;
`
const LoadingImage = styled.img.attrs(({ translateX, translateY }) => ({
  style: {
    transform: `translate(${translateX}px, ${translateY}px)`,
  },
}))`
  opacity: 1;
  /* width: calc(100vw + 30px); */
  filter: blur(10px);
  -webkit-filter: blur(10px);
  display: block;
  position: fixed;
`
export default connect(
  state => ({
    isImageClosed: getIsImageClosed(state),
  }),
  {
    ...mouseTrackerActions,
    ...burgerActions,
  },
)(FullImage)
