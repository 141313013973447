import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Cta from '../../components/Cta'
import DiamondColumn from './DiamondColumn'
import { withRouter } from 'react-router-dom'
import { useConsumerMobile } from '../../context/ContextMobile'
import ShareMenu from './ShareMenu'
import { animateLeftMenu } from './animations'

const FullImageMenu = ({ show, currentPath, onClick, location }) => {
  const rightMenu = useRef()
  const isMobile = useConsumerMobile()
  const imageId = location.pathname.split('/')[2]
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false)

  function handleShareClick() {
    setIsDropDownMenuOpen(!isDropDownMenuOpen)
  }

  useEffect(() => {
    if (!show || !rightMenu || !rightMenu.current) return
    animateLeftMenu(rightMenu.current.children)
  }, [show])

  return (
    <Container show={show} ref={rightMenu}>
      <Shadow />
      <DiamondColumn />
      <div className="toAnimate">
        <Cta
          src={`https://dallara.imgix.net/gallery/dallara-stradale-${imageId}.jpg`}
          download={`dallara-stradale-${imageId}.jpg`}
        >
          <Box>Download</Box>
        </Cta>
      </div>
      <DiamondColumn />
      <div className="toAnimate">
        <Cta onClick={handleShareClick}>
          <Box>Share</Box>
        </Cta>
      </div>
      <ShareMenu show={isDropDownMenuOpen} />
      {isMobile ? (
        <div style={{ marginRight: '6px' }} />
      ) : (
        <DiamondColumn hideLine={!isMobile} />
      )}
    </Container>
  )
}

export default withRouter(FullImageMenu)

const Container = styled.div`
  position: absolute;
  right: 1px;
  bottom: -11px;
  margin-left: auto;
  height: 55px;
  display: flex;
  margin-right: -4px;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  flex-flow: row;
  > * {
    opacity: 0;
  }
  text-align: center;
  @media (min-width: 1024px) {
    margin-left: 0;
    right: 0;
    bottom: auto;
    top: 1.1rem;
  }
`
const Box = styled.div`
  width: 87px;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  border: 1px solid ${props => props.theme.colors.white30};
  padding: 0.5rem 0.75rem;
  color: white;
  margin: 1px;
  font-size: 0.75rem;
  pointer-events: auto;
  padding-bottom: 0.25rem;
  background: ${props =>
    props.isActive ? props.theme.colors.white30 : 'inherit'};
`

const Shadow = styled.div`
  @media (min-width: 1024px) {
    background: radial-gradient(
      ellipse closest-side at center,
      rgba(23, 23, 23, 0.43) 2%,
      rgba(0, 0, 0, 0.05) 83%,
      transparent
    );
    position: absolute;
    top: -90px;
    right: -40px;
    width: 155%;
    height: 424%;
    z-index: -1;
  }
`
