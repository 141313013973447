import styled from 'styled-components/macro'

export const LateralFrame = styled.div`
  position: relative;
`

export const Frame = styled.div`
  position: absolute;
  top: ${props => (props.big ? '-4rem' : '0')};
  bottom: ${props => (props.big ? '-4rem' : '0')};
  display: flex;
  ${props => props.left && 'left: -1rem'};
  ${props => props.right && 'right: -1rem'};
  width: 1px;
  z-index: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.theme.colors.light};
  @media (min-width: 1024px) {
    ${props => props.left && 'left: -4rem'};
    ${props => props.right && 'right: -4rem'};
  }
`
