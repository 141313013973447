import { MOUSE_ENTER } from './types'
import { MOUSE_LEAVE } from './types'
import { MOUSE_DISABLE } from './types'
import { MOUSE_ACTIVE } from './types'
import { MOUSE_CLOSE } from './types'
import { MOUSE_DEFAULT } from './types'
import { MOUSE_DARK_MODE } from './types'

export const onMouseEnter = () => ({
  type: MOUSE_ENTER,
})

export const onMouseLeave = () => ({
  type: MOUSE_LEAVE,
})

export const onMouseDisable = () => ({
  type: MOUSE_DISABLE,
})

export const onMouseActive = () => ({
  type: MOUSE_ACTIVE,
})

export const setMouseClose = () => ({
  type: MOUSE_CLOSE,
})

export const setMouseDarkMode = payload => ({
  type: MOUSE_DARK_MODE,
  payload,
})

export const setMouseDefault = () => ({
  type: MOUSE_DEFAULT,
})
