import { MOUSE_ENTER } from './types'
import { MOUSE_LEAVE } from './types'
import { MOUSE_DISABLE } from './types'
import { MOUSE_ACTIVE } from './types'
import { MOUSE_CLOSE } from './types'
import { MOUSE_DEFAULT } from './types'
import { MOUSE_DARK_MODE } from './types'

const defaultState = {
  isMouseActive: false,
  isMouseDisabled: false,
  isMouseClose: null,
  mouseDarkMode: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case MOUSE_ENTER:
      return {
        ...state,
        isMouseActive: true,
      }
    case MOUSE_LEAVE:
      return {
        ...state,
        isMouseActive: false,
      }
    case MOUSE_DISABLE:
      return {
        ...state,
        isMouseDisabled: true,
      }
    case MOUSE_ACTIVE:
      return {
        ...state,
        isMouseDisabled: false,
      }
    case MOUSE_CLOSE:
      return {
        ...state,
        isMouseClose: true,
      }
    case MOUSE_DARK_MODE:
      return {
        ...state,
        mouseDarkMode: action.payload,
      }
    case MOUSE_DEFAULT:
      return {
        ...state,
        isMouseClose: false,
      }
    default:
      return state
  }
}

export const isMouseActive = state => state.mouseTracker.isMouseActive
export const isMouseDisabled = state => state.mouseTracker.isMouseDisabled
export const isMouseClose = state => state.mouseTracker.isMouseClose
export const isMouseDarkModeOn = state => state.mouseTracker.mouseDarkMode
