import * as currentIdActions from './currentID/actions'
import * as directionActions from './direction/actions'
import * as isSlidingActions from './isSliding/actions'
import * as nextIDActions from './nextID/actions'
import * as dotsActions from './dots/actions'
import * as keyMessageActions from './keyMessage/actions'

export default {
  ...currentIdActions,
  ...directionActions,
  ...isSlidingActions,
  ...nextIDActions,
  ...dotsActions,
  ...keyMessageActions,
}
