import React from 'react'

const SvgSelect = props => (
  <svg width={9} height={5} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M4 4h1v1H4zM3 3h1v1H3zM2 2h1v1H2zM1 1h1v1H1zM0 0h1v1H0z" />
      <g>
        <path d="M8 0h1v1H8zM7 1h1v1H7zM6 2h1v1H6zM5 3h1v1H5zM4 4h1v1H4z" />
      </g>
    </g>
  </svg>
)

export default SvgSelect
