import React, { useRef, useCallback, useState, useEffect } from 'react'
import Preload from 'react-preload'
import { TimelineMax } from 'gsap'
import styled, { css } from 'styled-components/macro'
import { connect } from 'react-redux'
import { getIsSidebarOpen } from '../redux/sidebar/reducer'
import * as customizerActions from '../redux/customizer/actions'
import * as sidebarActions from '../redux/sidebar/actions'
import {
  getSelectedFeature,
  getIsShowingTechData,
} from '../redux/customizer/reducer'
import { useConsumerMobile } from '../context/ContextMobile'
import { useConsumerTranslation } from '../context/ContextTranslation'
import Draggable from 'react-draggable'
import { Sidebar, Cta } from '../components'
import CustomEase from '../utils/CustomEase'

const CCease = CustomEase.create(
  'custom',
  'M0,0 C0.25,0 0.297,0.046 0.335,0.078 0.436,0.162 0.438,0.302 0.47,0.512 0.512,0.86 0.53,0.898 0.584,0.946 0.623,0.98 0.698,1 1,1',
)

const backgrounds = {
  barchetta: {
    url: 'https://dallara.imgix.net/customize/01-Customize.jpg',
  },
  barchettaWing: {
    url: 'https://dallara.imgix.net/customize/03-Customize.jpg',
  },
  spider: {
    url: 'https://dallara.imgix.net/customize/02-Customize.jpg',
  },
  coupe: {
    url: 'https://dallara.imgix.net/customize/04-Customize.jpg',
  },
  coupeWing: {
    url: 'https://dallara.imgix.net/customize/05-Customize.jpg',
  },
}

const DraggableDecorator = props => {
  const { children, disabled } = props

  const draggableProps = {
    positionOffset: !disabled ? { x: '30%', y: '0%' } : null,
    position: disabled ? { x: 0, y: 0 } : null,
  }

  if (window.innerWidth >= 768) {
    return children
  } else {
    return (
      <Draggable
        axis="x"
        bounds={{ left: -400, right: 150 }}
        disabled={disabled}
        {...draggableProps}
      >
        {children}
      </Draggable>
    )
  }
}

const Customize = ({
  selectedFeature,
  isShowingTechData,
  closeSidebar,
  isSidebarOpen,
}) => {
  const isMobile = useConsumerMobile()
  const backgroundImageRef = useRef()
  const { customizeExploreMore } = useConsumerTranslation()
  const preferredHeight = window.innerHeight * window.devicePixelRatio
  const qs = `?h=${preferredHeight}`

  const [fullWidth, setFullWidth] = useState(false)

  const toggleFullWidth = useCallback(() => {
    setFullWidth(!fullWidth)
  }, [fullWidth])

  return (
    <Preload
      images={Object.values(backgrounds).map(({ url }) => `${url}${qs}`)}
    >
      <Background>
        {(!isShowingTechData || !isMobile) && backgrounds[selectedFeature] && (
          <>
            <DraggableDecorator disabled={fullWidth}>
              <ImageContainer>
                <CustomizeImage
                  onClick={() => isMobile && toggleFullWidth()}
                  fullWidth={fullWidth}
                  ref={backgroundImageRef}
                  src={`${backgrounds[selectedFeature].url}${qs}`}
                />
              </ImageContainer>
            </DraggableDecorator>
            {!isMobile && isSidebarOpen && (
              <Cta onClick={closeSidebar}>
                <SidebarDarkElement />
              </Cta>
            )}
            <SidebarAnimation isMobile={isMobile} isSidebarOpen={isSidebarOpen}>
              {isSidebarOpen && (
                <Sidebar
                  title={customizeExploreMore.label}
                  onClose={closeSidebar}
                  content={customizeExploreMore.content}
                />
              )}
            </SidebarAnimation>
          </>
        )}
      </Background>
    </Preload>
  )
}

const SidebarAnimation = ({ isSidebarOpen, children, isMobile }) => {
  const mySidebarWrapper = useRef()

  useEffect(() => {
    const el = mySidebarWrapper.current
    if (!el) return

    if (isSidebarOpen) {
      new TimelineMax()
        .set(el, {
          display: 'block',
          x: isMobile ? '100vw' : '60vw',
        })
        .to(el, 0.4, {
          x: '0vw',
          ease: CCease,
        })
      return
    } else {
      new TimelineMax()
        .to(el, 0.4, {
          x: isMobile ? '100vw' : '60vw',
          ease: CCease,
        })
        .set(el, {
          display: 'none',
        })
    }
  }, [isSidebarOpen])

  return <SidebarWrapper ref={mySidebarWrapper}>{children}</SidebarWrapper>
}

const CustomizeImage = styled.img`
  height: 80vh;
  width: auto;
  align-self: flex-start;
  transition: width height 0.3s;
  ${props =>
    props.fullWidth &&
    css`
      align-self: center;
      width: 100vw;
      height: auto;
    `}
  @media (min-width: 1024px) {
    width: 120%;
    height: initial;
    align-self: center;
    margin-right: 120px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  @media (min-width: 1024px) {
    padding-left: 390px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  background-color: #0f2038;
  width: 100vw;
  height: 100vh;
`
const SidebarDarkElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
`

const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9;
  bottom: 0;
  transform: translateX(100vw);
  right: 0;
  width: 100vw;
  background-color: white;
  display: none;
  z-index: 1;
  @media (min-width: 1024px) {
    transform: translateX(60vw);
    width: 60vw;
  }
`

export default connect(
  state => ({
    selectedFeature: getSelectedFeature(state),
    isShowingTechData: getIsShowingTechData(state),
    isSidebarOpen: getIsSidebarOpen(state),
  }),
  {
    ...sidebarActions,
    ...customizerActions,
  },
)(Customize)
