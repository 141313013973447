import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import Cta from '../Cta'
import Video from '../Video'

const ContactCta = ({
  firstText,
  secondText,
  cta: { src, text } = {},
  video_center,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [superWrapperInitialHeight, setSuperWrapperInitialHeight] = useState(
    null,
  )
  const superWrapper = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      setSuperWrapperInitialHeight(superWrapper.current.clientHeight + 'px')
    }, 100)
  }, [])

  return (
    <SuperWrapper
      isVideoPlaying={isVideoPlaying}
      ref={superWrapper}
      superWrapperInitialHeight={superWrapperInitialHeight}
    >
      <Wrapper>
        <FirstColumn>
          <p dangerouslySetInnerHTML={{ __html: firstText }} />
        </FirstColumn>
        <SecondColumn>
          <VideoWrapper isVideoPlaying={isVideoPlaying}>
            <VideoInner isVideoPlaying={isVideoPlaying}>
              <Video
                src={video_center.src}
                poster={`${video_center.poster}?w=400`}
                setIsVideoPlaying={setIsVideoPlaying}
                noDecorator
                fixedRatio
                hasClose
              />
            </VideoInner>
          </VideoWrapper>
        </SecondColumn>
      </Wrapper>
      <div>
        {secondText}{' '}
        <YellowText>
          <Cta src={src} isActive inline>
            {text}
          </Cta>
        </YellowText>
      </div>
    </SuperWrapper>
  )
}
export default ContactCta

const VideoWrapper = styled.div`
  position: absolute;
  top: 10px;
  transition: all 0.25s;
  right: 10px;
  ${props =>
    props.isVideoPlaying
      ? `  
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: black;
      `
      : `
        width: 250px;
        height: 150px;
    `}
`
const VideoInner = styled.div`
  transition: all 0.25s;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  > div {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
`
const SuperWrapper = styled.div`
  transition: all 0.25s;
  ${props =>
    props.superWrapperInitialHeight &&
    css`
      height: ${props.superWrapperInitialHeight};
    `}
  ${props =>
    props.isVideoPlaying &&
    css`
      height: 50vh;
      transition: all 0.25s;
    `}
  @media (min-width: 1024px) {
    width: 700px;
  }
`
const Wrapper = styled.div`
  display: flex;
  transition: all 0.25s;
  margin-bottom: 2rem;
  align-items: center;
  a {
    color: ${props => props.theme.colors.main}!important;
  }
`
const YellowText = styled.span`
  > div {
    display: inline-block;
  }
  a {
    color: ${props => props.theme.colors.main}!important;
    text-decoration: none;
  }
  > a {
    color: ${props => props.theme.colors.main}!important;
    text-decoration: none;
  }
`

const FirstColumn = styled.div`
  flex: auto;
  @media (min-width: 1024px) {
    flex: 1;
  }
  p {
    margin: 0;
  }
  a {
    color: yellow;
  }
`

const SecondColumn = styled.div`
  flex: 0 1 260px;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  a {
    color: yellow;
  }
`
