import React, { useEffect, useState } from 'react'
import { Swipeable } from 'react-touch'
import { connect } from 'react-redux'
import sliderActions from '../../redux/slider/actions'
import { closeBurger } from '../../redux/burger/actions'
import { debounce } from 'debounce'
import { getDirection } from '../../redux/slider/direction/reducer'
import { getNextID } from '../../redux/slider/nextID/reducer'
import { getIsSliding } from '../../redux/slider/isSliding/reducer'
import { isSlidable } from '../../redux/ui/selectors'
import * as dotsActions from '../../redux/slider/dots/actions'

// const debouncedHandleSwipe = handleSwipe => debounce(e => handleSwipe(e), 1000, 'immediate')

const BlockManager = ({
  children,
  setDirection,
  unsetDirection,
  setNextID,
  setIsSliding,
  direction,
  nextID,
  childrenLength = 4,
  isSlidable,
  closeBurger,
  isSliding,
}) => {
  const [handleSwipe, setHandleSwipe] = useState(null)

  useEffect(() => {
    if (!handleSwipe || typeof handleSwipe.func !== 'function') return

    const mousewheelevt = /Firefox/i.test(navigator.userAgent)
      ? 'DOMMouseScroll'
      : 'mousewheel'

    const handleKeys = event => {
      if (event.isComposing || event.keyCode === 229 || isSliding) {
        return
      }

      // do something
      switch (event.keyCode) {
        case 40:
          setDirection(1)
          break
        case 38:
          setDirection(-1)
          break
        default:
          break
      }
    }

    window.addEventListener(mousewheelevt, handleSwipe.func, false)
    window.addEventListener('keydown', handleKeys, false)
    return () => {
      window.removeEventListener(mousewheelevt, handleSwipe.func, false)
      window.removeEventListener('keydown', handleKeys, false)
    }
  }, [handleSwipe])

  useEffect(() => {
    if (!isSlidable || handleSwipe) return

    const debouncedHandleSwipe = debounce(
      e => {
        setDirection(Math.sign(e.detail ? e.detail * 500 : e.deltaY) || e)
      },
      1000,
      'immediate',
    )

    setHandleSwipe({
      func: debouncedHandleSwipe,
    })
  }, [isSlidable])

  useEffect(() => {
    if (
      (direction < 0 && nextID === 0) ||
      (direction > 0 && nextID === childrenLength)
    ) {
      return
    }

    if (
      !direction &&
      handleSwipe &&
      handleSwipe.func &&
      typeof handleSwipe.func.clear === 'function'
    ) {
      handleSwipe.func.clear()
    }
  }, [direction])

  useEffect(() => {
    // blocca lo slide agli estremi
    if (
      (direction < 0 && nextID === 0) ||
      (direction > 0 && nextID === childrenLength)
    ) {
      unsetDirection()
      return
    }

    if (isSlidable && direction) {
      setNextID(direction > 0 ? nextID + 1 : nextID - 1)
      setIsSliding()
      closeBurger()
    }
  }, [direction])

  return (
    <Swipeable
      onSwipeUp={() => handleSwipe.func(1)}
      onSwipeDown={() => handleSwipe.func(-1)}
    >
      {children}
    </Swipeable>
  )
}

export default connect(
  state => ({
    direction: getDirection(state),
    nextID: getNextID(state),
    isSlidable: isSlidable(state),
    isSliding: getIsSliding(state),
  }),
  {
    ...sliderActions,
    ...dotsActions,
    closeBurger,
  },
)(BlockManager)
