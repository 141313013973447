import styled, { css } from 'styled-components/macro'

export const AddressDescriptionWrapper = styled.div`
  position: ${props => (props.top && props.left ? 'absolute' : 'relative')};
  width: 100%;
  padding-bottom: 2rem;
  @media (min-width: 1024px) {
    width: auto;
    height: auto;
  }
  top: ${props => props.top}%;
  ${props =>
    props.left < 75
      ? `left: calc(${props.left}% + 35px);`
      : `left: calc(${props.left}% - 250px)`};
  ${props =>
    props.hud &&
    css`
      position: relative;
      width: initial;
      padding-bottom: 4rem;
      flex: 1;
      flex-wrap: wrap;
      flex-grow: 2;
      ${Close} {
        display: none;
      }
    `}
`
export const DiamondWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  margin-left: -4px;
  margin-top: 4px;
`
export const LabelContainer = styled.div`
  display: flex;
  height: 25px;
  position: relative;
  margin-left: 8px;
`
export const Name = styled.div`
  margin: 0 1px;
  background-color: ${props =>
    props.yellow ? props.theme.colors.main : '#fff'};
  font-size: 12px;
  padding: 7px 0.75rem 2px;
  align-items: center;
  opacity: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  letter-spacing: 0.06rem;
  color: #888;
  text-transform: uppercase;
`

export const Label = styled.div`
  margin: 0 1px;
  background-color: ${props =>
    props.yellow ? props.theme.colors.main : '#000'};
  font-size: 12px;
  padding: 4px 0.75rem 0px;
  align-items: center;
  align-items: center;
  opacity: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  flex: 0 15px;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  color: ${props => (props.yellow ? '#000' : '#fff')};
`

export const Close = styled.div`
  margin: 0 1px;
  background-color: #fff;
  font-size: 12px;
  padding: 5px;
  align-items: center;
  opacity: 1;
  flex: 1;
  justify-content: center;
  flex: 0 15px;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
`

export const Description = styled.div`
  font-size: 12px;
  color: #fff;
  display: block;
  flex: 0 100%;
  opacity: 1;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  margin-top: 10px;
  margin-left: 54px;
  line-height: 18px;
  a {
    color: ${props => props.theme.colors.main};
    text-decoration: none;
    &.whiteLink {
      color: #fff;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  strong {
    color: ${props => props.theme.colors.main};
  }
`
