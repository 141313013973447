import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Div100vh from 'react-div-100vh'
import styled from 'styled-components/macro'
import MouseTracker from '../MouseTracker'
import HomeInitialLoader from '../HomeInitialLoader'
import { Hud } from '../../components'
import * as mouseTrackerActions from '../../redux/mouseTracker/actions'
import { isInitialLoaderLoaded } from '../../redux/initialLoader/reducer'
import { getIsBurgerOpen } from '../../redux/burger/reducer'
import sliderActions from '../../redux/slider/actions'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { useConsumerMobile } from '../../context/ContextMobile'
import * as S from './styles'

import { isGallery } from '../../utils/conditions'

const LoadingManager = ({
  isInHomeNotLoadedYet,
  isRouterChanging,
  setIsRouterChanging,
  isHome,
}) => {
  if (isInHomeNotLoadedYet) return <HomeInitialLoader />

  // loading tra pagine
  // if (isRouterChanging && !isHome) {
  //   return (
  //     <S.RouteChangingWrapper>
  //       <RouteChanging onRouteChanged={setIsRouterChanging} />
  //     </S.RouteChangingWrapper>
  //   )
  // }

  return null
}

const Layout = ({
  children,
  history,
  path,
  isInitialLoaderLoaded,
  isBurgerOpen,
  setCurrentID,
  setNextID,
}) => {
  const [isRouterChanging, setIsRouterChanging] = useState(null)
  const { routes } = useConsumerTranslation()
  const isMobile = useConsumerMobile()
  const { HOME, FULLIMAGE } = routes
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setIsRouterChanging(true)
      setCurrentID(0)
      setNextID(0)
    })
    return () => {
      unlisten()
    }
  }, [])

  const content = (
    <>
      <LoadingManager
        isHome={path === HOME.path}
        isInHomeNotLoadedYet={path === HOME.path && !isInitialLoaderLoaded}
        isRouterChanging={isRouterChanging}
        setIsRouterChanging={setIsRouterChanging}
      />
      <S.MobileWrapper isBurgerOpen={isBurgerOpen}>
        <Div100vh>
          <Wrapperino>{children}</Wrapperino>
        </Div100vh>
        <S.HudBox
          isBurgerOpen={isBurgerOpen}
          hideSideShadow={isGallery({ path, routes })}
        >
          <Hud
            isHome={path === HOME.path}
            isFullimage={path === FULLIMAGE.path}
            path={path}
          />
        </S.HudBox>
      </S.MobileWrapper>
    </>
  )

  return isMobile ? content : <MouseTracker>{content}</MouseTracker>
}

const Wrapperino = styled.div`
  box-sizing: border-box;
  padding-top: 8rem;
  height: 100%;
  @media (min-width: 1024px) {
    padding-top: 0;
    height: auto;
  }
`
export default compose(
  withRouter,
  connect(
    state => ({
      isInitialLoaderLoaded: isInitialLoaderLoaded(state),
      isBurgerOpen: getIsBurgerOpen(state),
    }),
    {
      ...mouseTrackerActions,
      ...sliderActions,
    },
  ),
)(Layout)
