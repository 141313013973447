import React, { useEffect, useState } from 'react'
import { TimelineMax, Power4 } from 'gsap'
import * as S from './styles'
import DiamondFrame from '../DiamondFrame'
import { isFirefox } from 'react-device-detect'

const HighlightText = ({ text, onStart, onComplete, onUnmount, nextID }) => {
  const [animation, setAnimation] = useState(false)

  useEffect(() => {
    if (!text || !animation) return
    animation.play(0)
    onStart()
  }, [text, nextID])

  useEffect(() => {
    setAnimation(
      new TimelineMax({
        paused: true,
      })
        .set('#diamondFrame', {
          ...(!isFirefox && {
            filter: 'blur(60px)',
          }),
          display: 'flex',
          opacity: 0,
        })
        .set('#highlightText', {
          display: 'flex',
          opacity: 0,
        })
        .to('#highlightText', 0.3, {
          ease: Power4.easeOut,
          opacity: 0.4,
        })
        .to('#diamondFrame', 0.3, {
          ...(!isFirefox && {
            filter: 'blur(0px)',
          }),
          opacity: 1,
          delay: 1.5,
          ease: Power4.easeOut,
        })
        .to('#diamondFrame', 1.5, {
          ...(!isFirefox && {
            filter: 'blur(60px)',
          }),
          opacity: 0,
          ease: Power4.easeOut,
          delay: 7,
        })
        .to('#highlightText', 0.5, {
          opacity: 0,
          onComplete,
        })
        .addLabel('fadeAnim')
        .set('#highlightText', {
          display: 'none',
        })
        .set('#diamondFrame', {
          display: 'none',
        }),
    )
  }, [])

  const stopHighlight = () => {
    animation.play('fadeAnim')
    onComplete()
  }

  return (
    <>
      <S.Background id="highlightText" onClick={stopHighlight} />
      <S.HighlightText id="diamondFrame" onClick={stopHighlight}>
        <S.TextWrapper>
          {text && (
            <DiamondFrame size="10px" noBorder withSegments>
              <S.TextInner dangerouslySetInnerHTML={{ __html: text }} />
            </DiamondFrame>
          )}
        </S.TextWrapper>
      </S.HighlightText>
    </>
  )
}

export default HighlightText
