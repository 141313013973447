import { DIRECTION_SET, DIRECTION_UNSET } from './types'

export default (state = null, action) => {
  switch (action.type) {
    case DIRECTION_SET:
      return action.payload
    case DIRECTION_UNSET:
      return null
    default:
      return state
  }
}

export const getDirection = state => state.slider.direction
