import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Video from '../Video'

export default ({ show, currentID, isSliding, slides }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const videoProps = slides[currentID].video

  if (!videoProps || isSliding || !show) return null

  return (
    <RightCornerVideo>
      <Header>
        <Title>{videoProps.title}</Title>
        <Description>{videoProps.description}</Description>
      </Header>
      <VideoWrapper isVideoPlaying={isVideoPlaying}>
        <VideoInner isVideoPlaying={isVideoPlaying}>
          <Video
            {...videoProps}
            poster={`${videoProps.poster}?w=300&h=150`}
            setIsVideoPlaying={setIsVideoPlaying}
            hasClose
          />
        </VideoInner>
      </VideoWrapper>
    </RightCornerVideo>
  )
}

const RightCornerVideo = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 5px;
  z-index: 1;
`
const VideoWrapper = styled.div`
  position: relative;
  ${props =>
    props.isVideoPlaying &&
    `
      background-color: black;
    `};
`

const Header = styled.div`
  color: white;
  margin: 0.5rem;
  text-align: right;
  font-size: 0.75rem;
`

const Title = styled.div`
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`

const Description = styled.div``

const VideoInner = styled.div`
  transition: all 0.25s;
  ${props =>
    props.isVideoPlaying
      ? `  
        width: 46vw;
        height: 54vh;
      `
      : `
        width: 250px;
        height: 150px;
      `}
`
