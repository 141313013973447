import React, { useState, useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { TimelineMax } from 'gsap'
import styled from 'styled-components/macro'
import { SlowMo } from 'gsap/TweenMax'
import { isFirefox } from 'react-device-detect'

import InnerPanning from '../components/InnerPanning'
import Video from '../components/Video'
import ContactCta from '../components/ContactCta'
import sliderActions from '../redux/slider/actions'
import * as sidebarActions from '../redux/sidebar/actions'
import * as initialLoaderActions from '../redux/initialLoader/actions'
import * as pushActions from '../redux/push/actions'

import {
  BlockManager,
  Sidebar,
  DisplacementAnimation,
  HighlightText,
  DiamondFrame,
  Cta,
} from '../components'
import { getCurrentID } from '../redux/slider/currentID/reducer'
import { getNextID } from '../redux/slider/nextID/reducer'
import { getIsSliding } from '../redux/slider/isSliding/reducer'
import { getIsSidebarOpen } from '../redux/sidebar/reducer'
import { isInitialLoaderLoaded } from '../redux/initialLoader/reducer'
import { getIsPushOn } from '../redux/push/reducer'
import { getIsKeyMessageSet } from '../redux/slider/keyMessage/reducer'
import CustomEase from '../utils/CustomEase'
import { useConsumerTranslation } from '../context/ContextTranslation'
import { useConsumerMobile } from '../context/ContextMobile'

const CCease = CustomEase.create(
  'custom',
  'M0,0 C0.25,0 0.297,0.046 0.335,0.078 0.436,0.162 0.438,0.302 0.47,0.512 0.512,0.86 0.53,0.898 0.584,0.946 0.623,0.98 0.698,1 1,1',
)

function useMyAnimation(
  myDisplacementAnimationWrapper,
  isSidebarOpen,
  mySidebarDark,
) {
  const [isDisplacementBlurred, setIsDisplacementBlurred] = useState(null)

  useEffect(() => {
    const elementToBlur = myDisplacementAnimationWrapper.current
    const elementToDarken = myDisplacementAnimationWrapper.current.children[1]

    if (!elementToBlur || !isDisplacementBlurred || !elementToDarken) return

    if (!isFirefox) {
      new TimelineMax()
        .addLabel('init')
        .to(
          elementToBlur,
          0.25,
          {
            filter: 'blur(2px)',
          },
          'init',
        )
        .to(
          elementToBlur,
          0.25,
          {
            filter: 'blur(0)',
          },
          'init+=1',
        )
    }
  }, [myDisplacementAnimationWrapper, isDisplacementBlurred])

  useEffect(() => {
    const elementToBlur = myDisplacementAnimationWrapper.current
    const elementToDark = mySidebarDark.current

    if (!elementToBlur || !elementToDark) return

    if (isSidebarOpen) {
      new TimelineMax()
        .to(elementToDark, 0, {
          display: 'block',
          backgroundColor: 'black',
          opacity: 0,
        })
        .to(elementToBlur, 0.25, {
          ...(!isFirefox && { filter: 'blur(5px)' }),
        })
        .to(elementToDark, 0.25, {
          opacity: 0.3,
        })
      return
    }

    new TimelineMax()
      .to(elementToBlur, 0.25, {
        ...(!isFirefox && { filter: 'blur(0px)' }),
      })
      .to(elementToDark, 0.25, {
        opacity: 0,
      })
      .to(elementToDark, 0, {
        display: 'none',
      })
  }, [isSidebarOpen])

  return {
    setIsDisplacementBlurred,
  }
}

const SidebarAnimation = ({ isSidebarOpen, children, isMobile }) => {
  const mySidebarWrapper = useRef()

  useEffect(() => {
    const el = mySidebarWrapper.current
    if (!el) return

    if (isSidebarOpen) {
      new TimelineMax()
        .set(el, {
          display: 'block',
          x: isMobile ? '100vw' : '60vw',
        })
        .to(el, 0.4, {
          x: '0vw',
          ease: CCease,
        })
      return
    } else {
      new TimelineMax()
        .to(el, 0.4, {
          x: isMobile ? '100vw' : '60vw',
          ease: CCease,
        })
        .set(el, {
          display: 'none',
        })
    }
  }, [isSidebarOpen])

  return <SidebarWrapper ref={mySidebarWrapper}>{children}</SidebarWrapper>
}

const Home = ({
  currentID,
  isSliding,
  nextID,
  setCurrentID,
  unsetIsSliding,
  unsetDirection,
  setImageInitialLoader,
  setKeyMessage,
  unsetKeyMessage,
  closeSidebar,
  isSidebarOpen,
  isInitialLoaderLoaded,
  isPushOn,
  isKeyMessageOn,
  turnPushOff,
}) => {
  const myDisplacementAnimationWrapper = useRef(null)
  const mySidebarDark = useRef(null)
  const { slides } = useConsumerTranslation()
  const isMobile = useConsumerMobile()

  const [isVideoReady, setIsVideoReady] = useState(false)

  const { setIsDisplacementBlurred } = useMyAnimation(
    myDisplacementAnimationWrapper,
    isSidebarOpen,
    mySidebarDark,
  )

  const {
    image: nextSlideImage,
    highlightText,
    text,
    contact,
    video_center,
    push,
    content,
    title,
    exploreMoreLabel,
  } = slides[nextID]
  const { image: currentSlideImage, points } = slides[currentID]

  const onCompleteTextAnim = () => {
    unsetKeyMessage()
  }

  const onCompleteDisplacement = () => {
    setCurrentID(nextID)
    unsetIsSliding()
    unsetDirection()
    setIsDisplacementBlurred(false)
  }

  const animationProps = {
    dispImg:
      'https://dallara.imgix.net/transition/05-Background.jpg?auto=fromat,compress&fit=crop&w=2048&h=1024',
    intensity1: 1,
    intensity2: 1,
    commonAngle: Math.PI / 4,
    speedIn: 1.8,
    easing: SlowMo.ease.config(0.2, 0.9, false),
    onFirstImageLoaded: image => {
      setImageInitialLoader(image)
    },
  }

  useEffect(() => {
    const el = document.getElementsByTagName('body')[0]
    el.style.overflowY = 'hidden'
    return () => (el.style.overflowY = 'auto')
  }, [])

  useEffect(() => {
    if (!isPushOn) {
      setIsVideoReady(false)
    }
  }, [isPushOn])

  useEffect(() => {
    if (isSliding) {
      console.log('isSliding')
      setKeyMessage()
    }
  }, [isSliding])

  const preferredHeight = window.innerHeight * window.devicePixelRatio
  const preferredWeight = window.innerWidth * window.devicePixelRatio
  const qs = `?w=${preferredWeight}&h=${preferredHeight}&fit=min&format=compress,auto`
  const qs_high = `?w=${2880}&h=${1600}`

  const onEnded = useCallback(
    e => {
      turnPushOff()
    },
    [turnPushOff],
  )

  const onReady = useCallback(
    e => {
      setIsVideoReady(true)
    },
    [setIsVideoReady],
  )

  return (
    <>
      <BlockManager childrenLength={slides.length - 1}>
        <DisplacementAnimationWrapper
          ref={myDisplacementAnimationWrapper}
          show={!isPushOn}
        >
          <DisplacementAnimation
            {...animationProps}
            onComplete={onCompleteDisplacement}
            image={`${nextSlideImage}${qs}`}
            imageArray={slides
              .map(slide => `${slide.image}${qs}`)
              .map(slide => `${slide.image}${qs_high}`)
              .filter(e => e)}
          />
          <Shadow />
        </DisplacementAnimationWrapper>
      </BlockManager>
      {!isMobile && (
        <Cta onClick={closeSidebar}>
          <SidebarDarkElement ref={mySidebarDark} />
        </Cta>
      )}
      <SidebarAnimation isMobile={isMobile} isSidebarOpen={isSidebarOpen}>
        {isSidebarOpen && (
          <Sidebar
            title={exploreMoreLabel || title}
            onClose={closeSidebar}
            content={content}
          />
        )}
      </SidebarAnimation>
      {isInitialLoaderLoaded && !isSliding && text && (
        <TextWrapper>
          <DiamondFrame size="6px" noBorder withSegments>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </DiamondFrame>
        </TextWrapper>
      )}
      <HighlightText
        text={highlightText}
        onStart={() => setIsDisplacementBlurred(true)}
        onComplete={onCompleteTextAnim}
        nextID={nextID}
      />
      {isInitialLoaderLoaded &&
        !isSliding &&
        !isMobile &&
        !isKeyMessageOn &&
        points && (
          <InnerPaddingWrapper show={!isPushOn}>
            <InnerPanning
              image={`${currentSlideImage}?w=2880&h=1600`}
              isSliding={isSliding}
            />
          </InnerPaddingWrapper>
        )}
      {!isSliding && contact && (
        <ContactWrapper center>
          <DiamondFrame size="6px" noBorder withSegments>
            <ContactCta {...contact} video_center={video_center} />
          </DiamondFrame>
        </ContactWrapper>
      )}
      <VideoWrapper show={isPushOn}>
        {push && push.src && (
          <>
            <Video
              src={push.src}
              playing={isPushOn}
              controls={false}
              playsinline
              onEnded={onEnded}
              onReady={onReady}
              keepMouse
            />
          </>
        )}
        {
          <VideoLoading show={!isVideoReady && isPushOn}>
            Keep pushing
          </VideoLoading>
        }
      </VideoWrapper>
    </>
  )
}

const VideoLoading = styled.div`
  box-sizing: border-box;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.7rem;
  letter-spacing: 0.06rem;
  background: #000;
  text-transform: uppercase;
  transition: opacity 0.3s;
  display: ${props => (props.show ? 'flex' : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 177.77777778vh;
  min-width: 100%;
  min-height: 56.25vw;
  opacity: ${props => (props.show ? 1 : 0)};
  display: ${props => (props.show ? 'block' : 'none')};
  transition: opacity 0.5s;
  background-color: black;
  z-index: ${props => (props.show ? 0 : -1)};
`

const DisplacementAnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s;
  canvas {
    cursor: none !important;
  }
`

const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
`

const TextWrapper = styled.div`
  box-sizing: border-box;
  width: calc(100vw - 20px);
  max-width: auto;
  top: 35%;
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 1.125rem;
  letter-spacing: 0.1rem;
  padding: 1rem 1.5rem;
  line-height: 1.4;
  z-index: 0;
  @media (min-width: 1024px) {
    max-width: 650px;
    width: auto;
    top: ${props => (props.center ? '50%' : '30%')};
  }
`

const ContactWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: calc(100vw - 20px);
  max-width: auto;
  color: white;
  text-transform: uppercase;
  font-size: 1.125rem;
  letter-spacing: 0.1rem;
  padding: 0.7rem 1rem;
  line-height: 1.4;
  z-index: 0;
  margin: 0 10px;
  pointer-events: none;
  @media (min-width: 1024px) {
    position: absolute;
    transform: translate(-50%, -50%);
    max-width: 850px;
    width: auto;
    margin: auto;
    top: ${props => (props.center ? '50%' : '30%')};
    left: 50%;
    pointer-events: auto;
  }
`

const InnerPaddingWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s;
`

const SidebarDarkElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: none;
`

const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9;
  bottom: 0;
  transform: translateX(100vw);
  right: 0;
  width: 100vw;
  background-color: white;
  display: none;
  z-index: 1;
  @media (min-width: 1024px) {
    transform: translateX(60vw);
    width: 60vw;
  }
`
export default connect(
  state => ({
    currentID: getCurrentID(state),
    nextID: getNextID(state),
    isSliding: getIsSliding(state),
    isSidebarOpen: getIsSidebarOpen(state),
    isInitialLoaderLoaded: isInitialLoaderLoaded(state),
    isPushOn: getIsPushOn(state),
    isKeyMessageOn: getIsKeyMessageSet(state),
  }),
  {
    ...sliderActions,
    ...sidebarActions,
    ...initialLoaderActions,
    ...pushActions,
  },
)(Home)
