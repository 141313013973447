import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family:"NeoSansPro-Regular";
    src:url("/Monotype  - NeoSansPro-Regular.woff2") format("woff2"), url("/Monotype  - NeoSansPro-Regular.woff") format("woff"),url("/Monotype  - NeoSansPro-Regular.otf") format("opentype");
    font-style:normal;
    font-weight:400;
  }

  * {
    font-family: 'NeoSansPro-Regular', sans-serif;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
  }
  
  html, body {
    overflow-y: scroll;
    overflow-x: hidden;
    background: black; 
  }
  ::-webkit-scrollbar {
    display: none;
  }
  
`
export default GlobalStyle
