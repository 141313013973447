import React, { useState, useEffect, useRef } from 'react'
import LazyLoad from 'react-lazyload'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import * as mouseTrackerActions from '../../redux/mouseTracker/actions'
import Cta from '../Cta'
import * as S from './styles'
import { PlayerSvg } from '../../svg'
import GridElementWrapper from '../GridElementWrapper'
import Toggler from '../Toggler'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { useConsumerMobile } from '../../context/ContextMobile'

const Video = ({
  src,
  poster,
  onMouseDisable,
  onMouseLeave,
  onMouseActive,
  setIsVideoPlaying,
  hasClose,
  noDecorator,
  fixedRatio,
  playing,
  controls = true,
  playsinline,
  scrollContainer = window,
  onEnded,
  onReady,
  onStart,
  onPlay,
  onProgress,
  onDuration,
  onPause,
  onBuffer,
  onBufferEnd,
  onSeek,
  muted,
  volume,
  keepMouse = false,
  loop,
  keepVisible = false,
}) => {
  const videoRef = useRef()
  const [isStarted, setIsStarted] = useState(playing)
  const [pip, setPip] = useState(false)
  const { various } = useConsumerTranslation()
  const isMobile = useConsumerMobile()

  const handleCloseVideo = () => {
    setIsStarted(false)
    if (typeof setIsVideoPlaying === 'function') {
      setIsVideoPlaying(false)
    }
  }

  useEffect(() => {
    if (isMobile && videoRef.current) {
      setTimeout(() => setPip(true), 100)
    }
    if (isStarted) {
      !keepMouse && onMouseDisable()
      !keepMouse && onMouseLeave()
      if (typeof setIsVideoPlaying === 'function') {
        setIsVideoPlaying(true)
      }
      return
    }
    if (typeof setIsVideoPlaying === 'function') {
      setIsVideoPlaying(false)
    }
  }, [isStarted])

  useEffect(() => {
    if (!playing) onMouseActive()
  }, [playing])

  useEffect(() => {
    return () => {
      onMouseActive()
    }
  }, [])

  return (
    <S.Video fixedRatio={fixedRatio}>
      {isStarted || keepVisible ? (
        <>
          {hasClose && (
            <S.CloseVideoWrapper>
              <Toggler
                text={various.videoClose}
                onClick={handleCloseVideo}
                close
              />
            </S.CloseVideoWrapper>
          )}
          <div
            onMouseEnter={() => !keepMouse && onMouseDisable()}
            onMouseLeave={() => !keepMouse && onMouseActive()}
            style={{ height: '100%', width: '100%' }}
          >
            <ReactPlayer
              playing={isStarted}
              ref={videoRef}
              url={src}
              controls={controls}
              loop={loop}
              width="100%"
              height="100%"
              pip={pip}
              onDisablePIP={() => setIsStarted(false)}
              playsinline={playsinline}
              onEnded={onEnded}
              onReady={onReady}
              onStart={onStart}
              onPlay={onPlay}
              onProgress={onProgress}
              onDuration={onDuration}
              onPause={() => {
                onMouseActive()
                typeof onPause === 'function' && onPause()
              }}
              onBuffer={onBuffer}
              onSeek={onSeek}
              muted={muted}
              volume={volume}
            />
          </div>
        </>
      ) : (
        poster && (
          <LazyLoad once offset={100} scrollContainer={scrollContainer}>
            <Cta onClick={() => setIsStarted(true)} fullWidth>
              <S.PlayerSvgWrapper image={poster}>
                {!noDecorator && <GridElementWrapper />}
                <PlayerSvg />
              </S.PlayerSvgWrapper>
            </Cta>
          </LazyLoad>
        )
      )}
    </S.Video>
  )
}

const maybeWrap = (WrappingComponent, condition) => Component => props => {
  if (condition) {
    return (
      <WrappingComponent>
        <Component {...props} />
      </WrappingComponent>
    )
  }

  return <Component {...props} />
}
export default connect(
  () => ({}),
  mouseTrackerActions,
)(props => {
  const MaybeWrappedVideo = maybeWrap(S.Ratio, props.fixedRatio)(Video)
  return <MaybeWrappedVideo {...props} />
})
