import { BURGER_OPEN, BURGER_CLOSE, IMAGE_CLOSE, IMAGE_OPEN } from './types'

export const openBurger = payload => ({
  type: BURGER_OPEN,
})

export const closeBurger = payload => ({
  type: BURGER_CLOSE,
})

export const closeImage = payload => ({
  type: IMAGE_CLOSE,
})

export const openImage = payload => ({
  type: IMAGE_OPEN,
})
