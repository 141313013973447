import React, { useRef, useEffect } from 'react'
import { HideUntilLoaded } from 'react-animation'
import { connect } from 'react-redux'
import { TimelineLite, SteppedEase } from 'gsap'

import * as S from './styles'
import LateralFrame from '../LateralFrame'
import Paragraph from '../Paragraph'
import Toggler from '../Toggler'
import ExploreMoreImage from '../ExploreMoreImage'
import { useConsumerMobile } from '../../context/ContextMobile'
import { BurgerClose } from '../../svg'
import * as mouseTrackerActions from '../../redux/mouseTracker/actions'

const Sidebar = ({ onClose, title, content, setMouseDarkMode }) => {
  const isMobile = useConsumerMobile()
  const contentContainer = useRef()
  const rightLine = useRef()
  const leftLine = useRef()

  useEffect(() => {
    const timeline = new TimelineLite()

    timeline
      .addLabel('init')
      .staggerTo(
        [leftLine.current, rightLine.current],
        0.1,
        {
          opacity: 1,
          ease: SteppedEase.config(1),
        },
        0.1,
      )
      .staggerTo(
        contentContainer.current.children,
        0.1,
        {
          opacity: 1,
          ease: SteppedEase.config(1),
        },
        0.1,
      )

    return () => {
      timeline.kill()
    }
  }, [])

  return (
    <S.Sidebar>
      {!isMobile ? (
        <S.CloseWrapper>
          <Toggler onClick={onClose} close />
        </S.CloseWrapper>
      ) : (
        ''
      )}

      <S.SidebarInner
        onMouseEnter={() => setMouseDarkMode(true)}
        onMouseLeave={() => setMouseDarkMode(false)}
      >
        <S.RightLine ref={rightLine} />
        {isMobile ? (
          <S.CloseMobileWrapper>
            <BurgerClose fill="#000" onClick={onClose} />
          </S.CloseMobileWrapper>
        ) : null}
        <S.ContentContainer ref={contentContainer}>
          <>
            <LateralFrame big>
              <S.TitleWrapper>
                <S.SidebarTitle>{title}</S.SidebarTitle>
              </S.TitleWrapper>
            </LateralFrame>
            {content && <SidebarContent content={content} />}
          </>
        </S.ContentContainer>
        <S.LeftLine ref={leftLine} />
        {isMobile ? (
          <S.CloseMobileWrapper bottom>
            <BurgerClose fill="#000" onClick={onClose} />
          </S.CloseMobileWrapper>
        ) : null}
      </S.SidebarInner>
    </S.Sidebar>
  )
}

const SidebarContent = ({ content }) => {
  const isMobile = useConsumerMobile()
  return content.map(({ type, text, src, srcMobile }, i) => {
    switch (type) {
      case 'image':
        return (
          <LateralFrame key={i}>
            <HideUntilLoaded imageToLoad={src}>
              <ExploreMoreImage src={src} lazy={false} />
            </HideUntilLoaded>
          </LateralFrame>
        )

      case 'logos':
        const urlToLoad = isMobile ? srcMobile : src
        if (!urlToLoad) {
          return ''
        }
        return (
          <LateralFrame key={i}>
            <div style={{ textAlign: 'center' }}>
              <HideUntilLoaded imageToLoad={urlToLoad}>
                <ExploreMoreImage src={urlToLoad} lazy={false} />
              </HideUntilLoaded>
            </div>
          </LateralFrame>
        )

      case 'paragraph':
        return (
          <LateralFrame key={i}>
            <Paragraph>{text}</Paragraph>
          </LateralFrame>
        )

      default:
        return null
    }
  })
}
export default connect(
  () => ({}),
  {
    ...mouseTrackerActions,
  },
)(Sidebar)
