import React from 'react'
import * as S from './styles'
import { BurgerClose } from '../../svg'
import { Cta } from '../'
import { DiamondSingle } from '../../svg'

const AddressDescription = ({
  description,
  name,
  position,
  label,
  closeAddress,
  hud = false,
  isMobile = false,
}) => {
  const isHQ = label === 'HQ'
  return (
    <S.AddressDescriptionWrapper
      hud={hud}
      left={position && position.left}
      top={position && position.top}
    >
      {(hud || isMobile) && (
        <S.DiamondWrapper>
          <DiamondSingle
            width="8px"
            fill={isHQ ? '#F7E017' : '#FFF'}
            contacts
          />
        </S.DiamondWrapper>
      )}
      <S.LabelContainer>
        <S.Label yellow={isHQ}>
          <span>{label}</span>
        </S.Label>
        <S.Name>{name}</S.Name>
        <S.Close onClick={closeAddress}>
          <Cta onClick={() => {}}>
            <BurgerClose fill="#000" width={11} height={11} />
          </Cta>
        </S.Close>
      </S.LabelContainer>
      <S.Description dangerouslySetInnerHTML={{ __html: description }} />
    </S.AddressDescriptionWrapper>
  )
}

export default AddressDescription
