export default [
  {
    id: 'barchetta',
    label: 'Barchetta',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/01-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'barchettaWing',
    label: 'Barch. con ala',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/03-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'spider',
    label: 'Spider',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/02-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'coupe',
    label: 'Coupè',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/04-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'coupeWing',
    label: 'Coupè con ala',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/05-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
]

export const customizeExploreMore = {
  label: 'VERSATILITÀ',
  content: [
    {
      type: 'paragraph',
      text: `Per due anni, il team Dallara ha lavorato al progetto guidato dal suo Direttore Tecnico, l’ing.
      Giampaolo Dallara, con l’obiettivo di realizzare una vettura diversa da tutte le altre. È nata così
      un’auto senza portiere, come le monoposto da competizione, e versatile, capace di coniugare il
      comfort con le prestazioni di una supercar. Un’auto che nella configurazione standard è una
      Barchetta, ma che può diventare Roadster se dotata di parabrezza, Targa con l’aggiunta del T-
      Frame o Coupè, con l’inserimento delle due porte con apertura ad ala di gabbiano.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/07-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `Il canopy è interamente in carbonio, con superfici trasparenti in policarbonato, esattamente come
      le vetture Le Mans Prototype, con un peso complessivo inferiore ai 35 kg. È la prima volta che una
      vettura stradale ha un parabrezza in policarbonato, consentendo una riduzione di peso superiore ai
      7 kg.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/05-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `La forma del canopy è disegnata per ridurre al minimo la resistenza aerodinamica, garantire la
      massima visibilità sia in pista che in strada e permettere l’uso della vettura in ogni condizione
      climatica.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/04-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `La versatilità della Stradale si ritrova anche nella possibilità di personalizzare appieno l’esperienza
      di guida, attraverso l’utilizzo di diversi accessori e funzionalità. Ci si può avvicinare al mondo delle
      competizioni, ad esempio, con l’impiego di un sistema elettronico di acquisizione dati (Kit GPS) e di
      una smarty cam HD (Cockpit Camera). Un’accoppiata che fornisce al pilota tutti i parametri di
      guida, come in un vero sistema di acquisizione da pista, e genera filmati in alta definizione con
      telemetria live, così da poter ottimizzare lo stile di guida e migliorare le performance giro dopo
      giro. Anche il motore può essere personalizzato attraverso l’impiego di un impianto di scarico
      sportivo per uso pista in combinazione con una nuova centralina di controllo che garantisce
      maggiori prestazioni.`,
    },
  ],
}
