import { PUSH_ON, PUSH_OFF } from './types'

const defaultState = {
  isPushOn: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case PUSH_ON:
      return {
        ...state,
        isPushOn: true,
      }
    case PUSH_OFF:
      return {
        ...state,
        isPushOn: false,
      }
    default:
      return state
  }
}

export const getIsPushOn = state => state.push.isPushOn
