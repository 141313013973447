export default [
  {
    text:
      'Se sei qui per scoprire semplicemente<br />una bella auto sportiva, sei fuori strada.',
    highlightText: '',
    image: 'https://dallara.imgix.net/slides/01-Dallara-Stradale.jpg',
    push: '',
  },
  {
    highlightText: `Dallara Stradale non è un semplice esercizio di stile.<br/>
      È il risultato di oltre 40 anni di studi nel campo<br/>
      dell’aerodinamica.`,
    image: 'https://dallara.imgix.net/slides/02-Aerodynamics.jpg',
    title: 'Aerodinamica',
    push: {
      src: 'https://dallara.imgix.net/push/aerodynamics.mp4',
    },
    video: {
      src: 'https://youtu.be/qaddqSi4bvA ',
      poster: 'https://dallara.imgix.net/posters/01-Aerodynamics.jpg',
      title: 'Dallara racconta',
      description: 'Aerodinamica',
    },
    points: [
      {
        id: 1,
        label: 'Elephant Foot',
        title: 'Elephant Foot',
        description:
          'Condotto di evacuazione laterale integrato, per energizzare il flusso dal diffusore anteriore',
        x: 489, //La distanza da sinistra in pixel nell'immagine originale
        y: 528, //La distanza dal top in pixel dell'immagine originale
        bgX: -440,
        bgY: -393,
        bgXMiniLens: -628,
        bgYMiniLens: -681,
      },
      {
        id: 2,
        label: 'Ala',
        title: 'Ala',
        description:
          'Ala posteriore in carbonio a profilo variabile, RIMOVIBILE, contribuisce alla generazione di 820kg di downforce alla massima velocità.',
        x: 1148, //La distanza da sinistra in pixel nell'immagine originale
        y: 293, //La distanza dal top in pixel dell'immagine originale
        bgX: -909,
        bgY: -252,
        bgXMiniLens: -1506,
        bgYMiniLens: -366,
        zoomPositionX: 0.55,
        inverted: true,
      },
      {
        id: 3,
        label: 'Underfloor',
        title: 'Underfloor',
        description:
          'Diffusore anteriore con splitter d’ingresso e 3 canali principali.<br />Diffusore posteriore a 5 canali che lavora in sincronia con spoiler e ala.',
        x: 280, //La distanza da sinistra in pixel nell'immagine originale
        y: 220, //La distanza dal top in pixel dell'immagine originale
        bgX: -179,
        bgY: -192,
        bgXMiniLens: -344,
        bgYMiniLens: -271,
      },
      {
        id: 4,
        label: 'Radiator Ducts',
        title: 'Radiator Ducts',
        description:
          'Aria convogliata dall’anteriore fino al radiatore, attraverso due lunghi condotti all’interno delle fiancate prive di porte.',
        x: 552, //La distanza da sinistra in pixel nell'immagine originale
        y: 179, //La distanza dal top in pixel dell'immagine originale
        bgX: -376,
        bgY: -132,
        bgXMiniLens: -711,
        bgYMiniLens: -214,
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `Dai chilometri percorsi al simulatore, all’asfalto mangiato negli autodromi di tutto il mondo. Dalle ore passate a perfezionare i dettagli, allo studio meticoloso dei dati. Dai successi nel motorsport, agli insegnamenti tratti dagli errori. Dagli esperimenti, alle innovazioni. Abbiamo portato tutto il nostro mondo dalla pista alla strada.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/01.jpg',
      },
      {
        type: 'paragraph',
        text: `Così nasce Dallara Stradale: incarnando oltre 40 anni di esperienza e amore per le corse.`,
      },
      {
        type: 'paragraph',
        text: `Il risultato? Una quattro ruote connubio di design e sportività, con un’anima radicata nell’aerodinamica.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/02.jpg',
      },
      {
        type: 'paragraph',
        text: `In particolare grazie allo studio maniacale delle forme della vettura, nato dall’esperienza in pista con le vetture Formula, GT e Le Mans Prototype, Dallara Stradale mette su asfalto oltre 820 kg di carico aerodinamico alla velocità massima permettendo un’accelerazione laterale superiore a 2G con pneumatici stradali. La funzione genera la forma, l'eleganza incontra l'efficienza.`,
      },
      {
        type: 'paragraph',
        text: `E l’anima sportiva si mostra anche nei caratteristici dettagli come gli “elephant foot”, i condotti di evacuazione laterale integrati che energizzano il flusso dallo splitter e dai tre canali principali del diffusore anteriore.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/03.jpg',
      },
      {
        type: 'paragraph',
        text: `Il fondo vettura si completa con il diffusore posteriore che, con i suoi 5 canali impegna metà della superficie della vettura e lavora, in perfetta sincronia con il diffusore anteriore, spoiler, ala e due grandi evacuazioni dietro le ruote posteriori, per restituire la migliore armonia ingegneristica.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/04.jpg',
      },
      {
        type: 'paragraph',
        text: `Le sfide che ci si sono poste davanti in decenni di motorsport hanno spinto l’asticella sempre più in alto, motivandoci a ricercare soluzioni sempre più innovative. È con questa filosofia che abbiamo approcciato i flussi interni della vettura e studiato una soluzione con un layout privo di porte: così abbiamo raggiunto la massima efficienza sia per il radiatore, sia per l‘aspirazione motore posti nella parte posteriore della vettura. L’aria viene così raccolta dalle due “narici” poste sul cofano anteriore dell’auto e convogliata all’interno delle fiancate attraverso l’uso di due condotti diffusivi.`,
      },
      {
        type: 'paragraph',
        text: `La Dallara Stradale si presenta in due configurazioni aerodinamiche: la versione Standard e la versione Track, che prevede l’impiego dell’ala posteriore a profilo variabile, facilmente rimovibile consentendo di scegliere la configurazione più idonea allo stile di guida desiderato.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/05.jpg',
      },
      {
        type: 'paragraph',
        text: `Con Dallara Stradale il passato diventa futuro: abbiamo concentrato decenni di sforzi nell’aerodinamica applicata al motorsport, per creare una macchina mai vista prima.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/06.jpg',
      },
    ],
  },
  {
    highlightText: `Dallara Stradale non è semplice teoria della dinamica del veicolo. È esperienza della dinamica in senso assoluto. Sul circuito, sulla strada e sul simulatore.`,
    image: 'https://dallara.imgix.net/slides/04-Vehicle-Dynamics.jpg',
    title: 'Dinamica del veicolo',
    video: {
      src: 'https://youtu.be/P0bm5yZpoSI ',
      poster: 'https://dallara.imgix.net/posters/02-Vehicle-Dynamics.jpg',
      title: 'Dallara racconta',
      description: 'Dinamica del veicolo',
    },
    push: {
      src: 'https://dallara.imgix.net/push/vehicle-dynamics.mp4',
    },
    points: [
      {
        id: 1,
        label: 'Pneumatici',
        title: 'Pneumatici',
        description:
          'Sviluppati e ottimizzati al simulatore di guida, garantiscono in combinazione con lo sterzo meccanico un feeling immediato con l’auto ed elevatissima aderenza laterale, in frenata e accelerazione.',
        bgX: -706,
        bgY: -453,
        bgXMiniLens: -1152,
        bgYMiniLens: -818,
        x: 883, //La distanza da sinistra in pixel nell'immagine originale
        y: 632, //La distanza dal top in pixel dell'immagine originale
        zoomPositionY: 0.5,
      },
      {
        id: 2,
        label: 'Sospensioni',
        title: 'Sospensioni',
        description:
          'Lo schema a quadrilatero articolato consente di controllare ottimamente<br />il contatto penumatico strada, come nelle vetture da corsa.',
        bgX: -750,
        bgY: -293,
        bgXMiniLens: -1188,
        bgYMiniLens: -584,
        x: 910, //La distanza da sinistra in pixel nell'immagine originale
        y: 457, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 3,
        label: 'Sterzo',
        title: 'Sterzo',
        description:
          'Meccanico, privo di ogni ausilio elettronico, per una connessione diretta tra guidatore e asfalto.',
        bgX: -780,
        bgY: -151,
        bgXMiniLens: -1175,
        bgYMiniLens: -258,
        x: 900, //La distanza da sinistra in pixel nell'immagine originale
        y: 212, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 4,
        label: 'Ammortizzatori',
        title: 'Ammortizzatori',
        description:
          'Permettono di variare l’altezza da terra della vettura massimizzando l’effetto aerodinamico del fondo.',
        bgX: -799,
        bgY: -292,
        bgXMiniLens: -1288,
        bgYMiniLens: -539,
        x: 983, //La distanza da sinistra in pixel nell'immagine originale
        y: 421, //La distanza dal top in pixel dell'immagine originale
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `Una vettura tra proiezione e realtà: Dallara Stradale è nata dalle idee concrete del team Dallara ma è stata sviluppata e testata in un ambiente virtuale, grazie al simulatore di guida professionale. I dati raccolti in decenni di esperienza nel mondo motorsport ci hanno aiutato a ricostruire un ambiente ideale di studio.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/01.jpg',
      },
      {
        type: 'paragraph',
        text: `Il simulatore è il cuore pulsante dello sviluppo di una vettura proiettata nel futuro già in fase di progettazione. Ingegneri, tecnici e piloti hanno definito le specifiche di sospensioni, motore ed ergonomia, prima ancora che la Dallara Stradale fosse prodotta.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/03.jpg',
      },
      {
        type: 'paragraph',
        text: `La vettura porta su strada un eccellente connubio tra aderenza e comfort. L’impronta a terra del pneumatico anteriore è stata ottimizzata per mettere il pilota in diretta connessione con l’asfalto. Mentre il pneumatico posteriore lavora garantendo comfort in termini di impatto sugli ostacoli, in modo da poter assaporare ogni curva, trasformare ogni imperfezione della strada in piacere di guida.`,
      },
      {
        type: 'paragraph',
        text: `Un piacere da godere in ogni condizione, grazie al differenziale auto-bloccante a ingranaggi e al sistema di controllo elettronico ABS-ESP Electronic Stability Control che, insieme alle caratteristiche di rigidità torsionale della monoscocca, garantiscono la massimizzazione della performance senza sacrificare la stabilità. Le migliori evoluzioni dal motorsport messe al servizio del guidatore.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/02.jpg',
      },
      {
        type: 'paragraph',
        text: `Una macchina, due anime complementari. Dallara Stradale si presenta infatti con due diverse modalità di guida: l’assetto Road, la configurazione standard per farsi strada nella giungla urbana, e quella Track, che permette l’abbassamento della vettura di 20 mm, per godere al massimo delle potenzialità aerodinamiche.`,
      },
      {
        type: 'paragraph',
        text: `Adatta la vettura alle tue esigenze: le due configurazioni sono selezionabili con comandi al volante, per guidarti in ogni situazione.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/04.jpg',
      },
    ],
  },
  {
    highlightText: `Dallara Stradale non è semplice conoscenza<br/>dei materiali leggeri. È 30 anni di esperienza<br/> nella simulazione e nella produzione dei componenti<br/>in carbonio.`,
    image: 'https://dallara.imgix.net/slides/03-Composite-Materials.jpg',
    video: {
      src: 'https://youtu.be/TRk3BKgn8l8',
      poster: 'https://dallara.imgix.net/posters/03-Composite-Materials.jpg',
      title: 'Dallara racconta',
      description: 'Materiali compositi',
    },
    title: 'Materiali compositi',
    push: {
      src: 'https://dallara.imgix.net/push/composite-materials.mp4',
    },
    points: [
      {
        id: 1,
        label: 'Monoscocca',
        title: 'Monoscocca',
        description:
          'Realizzata in pre-preg autoclave e hot compression moulding: massima sicurezza, leggerezza e rigidezza, come le auto da corsa.',
        bgX: -486,
        bgY: -322,
        bgXMiniLens: -852,
        bgYMiniLens: -570,
        x: 661, //La distanza da sinistra in pixel nell'immagine originale
        y: 447, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 2,
        label: 'Carrozzeria',
        title: 'Carrozzeria',
        description:
          'Estremamente leggera, le sue forme aerodinamiche sono state realizzate<br />in pre-preg autoclave e short fiber compression moulding.',
        bgX: -868,
        bgY: -143,
        bgXMiniLens: -1335,
        bgYMiniLens: -311,
        x: 1020, //La distanza da sinistra in pixel nell'immagine originale
        y: 252, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 3,
        label: 'Underfloor',
        title: 'Underfloor',
        description:
          'Realizzato in air compression moulding: strutturale di rigidezza elevata, per resistere al carico aerodinamico generato. ',
        bgX: -566,
        bgY: -454,
        bgXMiniLens: -721,
        bgYMiniLens: -848,
        x: 714, //La distanza da sinistra in pixel nell'immagine originale
        y: 654, //La distanza dal top in pixel dell'immagine originale
        zoomPositionY: 0.5,
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `La cura dell’essenziale: questo il pensiero che ha guidato il processo del lavoro sui materiali. Abbandonato il superfluo, ci siamo concentrati nel portare al limite il concetto di essenzialità, utilizzando materiali compositi con fibre di carbonio. Il risultato? La Dallara Stradale pesa solo 855 kg.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/01.jpg',
      },
      {
        type: 'paragraph',
        text: `Per realizzarla sono stati utilizzati preimpregnati in autoclave e long fiber compression moulding per la monoscocca, body panel system per pannelli esterni e carbon sheet moulding per i rinforzi.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/02.jpg',
      },
      {
        type: 'paragraph',
        text: `Ma l’anima di carbonio va in profondità: l’impiego della fibra si è spinto anche sotto la scocca, nei componenti invisibili come staffe, leve e interfacce.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/03.jpg',
      },
      {
        type: 'paragraph',
        text: `Questo non impedisce alla Dallara Stradale di ruggire sull’asfalto e di farlo in pieno controllo: si ferma da 100 km/h in appena 31 metri, grazie ai dischi freno compatti che consentono di alleggerire le sospensioni e, di conseguenza, l’intero chassis.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/04.jpg',
      },
    ],
  },
  {
    title: 'Il sogno',
    highlightText: '',
    contact: {
      firstText: `Ora sai che la Dallara Stradale
      <br />
      non è solo un sogno.
      <br />
      È un sogno che è diventato realtà, quello dell’Ingegner Dallara.`,
      secondText: `Se vuoi farne parte`,
      cta: {
        src: '/contact',
        text: 'contattaci',
      },
    },
    video_center: {
      src: 'https://youtu.be/F_k-JPWRQQg',
      poster: 'https://dallara.imgix.net/posters/04-The-Dream.jpg',
      title: 'Il Sogno',
      description: 'Un viaggio nel sogno',
    },
    push: 'hide',
    image: 'https://dallara.imgix.net/slides/05-The-Dream.jpg',
    content: [
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/01-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/01-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/02-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/02-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/03-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/03-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/04-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/04-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/05-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/05-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/06-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/06-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/07-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/07-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/08-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/08-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/09-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/09-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/10-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/10-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/11-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/11-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/12-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/12-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/13-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/13-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/14-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/15-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/16-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/17-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/18-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/19-Partner.png',
      },
    ],
    exploreMoreLabel: 'Partners',
  },
]
