import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { DiamondSingle } from '../../svg'

import * as actions from '../../redux/customizer/actions'

import {
  getSelectedFeature,
  getSelectedModifiers,
  getComparingSelectedModifiers,
  getComparingSelectedFeature,
} from '../../redux/customizer/reducer'
import RadarChart from '../RadarChart'

const data = [
  {
    label: 'Comfort And Usability',
    value: {
      barchetta: 40,
      barchettaWing: 40,
      spider: 60,
      coupe: 80,
      coupeWing: 80,
    },
    modifier: {
      cambio: 10,
      sospensione: 10,
      pneumatico: -5,
      scarico: -10,
    },
  },
  {
    label: 'Lateral G',
    value: {
      barchetta: 40,
      barchettaWing: 70,
      spider: 40,
      coupe: 40,
      coupeWing: 70,
    },
    modifier: {
      cambio: 0,
      sospensione: 5,
      pneumatico: 25,
      scarico: 0,
    },
  },
  {
    label: 'Track attitude',
    value: {
      barchetta: 40,
      barchettaWing: 65,
      spider: 40,
      coupe: 40,
      coupeWing: 65,
    },
    modifier: {
      cambio: 5,
      sospensione: 10,
      pneumatico: 10,
      scarico: 10,
    },
  },
  {
    label: 'Speed',
    value: {
      barchetta: 80,
      barchettaWing: 70,
      spider: 80,
      coupe: 90,
      coupeWing: 70,
    },
    modifier: {
      cambio: 0,
      sospensione: 0,
      pneumatico: 0,
      scarico: 0,
    },
  },
  {
    label: 'Fun',
    value: {
      barchetta: 60,
      barchettaWing: 70,
      spider: 60,
      coupe: 70,
      coupeWing: 70,
    },
    modifier: {
      cambio: 0,
      sospensione: 5,
      pneumatico: 20,
      scarico: 5,
    },
  },
]

function getData({
  data,
  selectedFeature,
  selectedModifiers,
  dataKeyToCompare,
  compareSelectedFeature,
  compareSelectedModifiers,
}) {
  return data.map(({ label, value, modifier }) => ({
    value:
      value[selectedFeature] +
      selectedModifiers.reduce((acc, val) => (modifier[val] || 0) + acc, 0),
    ...(dataKeyToCompare && {
      [dataKeyToCompare]:
        value[compareSelectedFeature] +
        compareSelectedModifiers.reduce(
          (acc, val) => (modifier[val] || 0) + acc,
          0,
        ),
    }),
    label,
  }))
}

const Radar = ({
  show,
  selectedFeature,
  compareSelectedModifiers,
  compareSelectedFeature,
  selectedModifiers,
  addCompare,
  removeCompare,
}) => {
  if (!selectedFeature || !show) return null

  const dataKeyToCompare =
    compareSelectedModifiers && compareSelectedFeature ? 'valueToCompare' : null

  return (
    <RadarContainer>
      <Line className="top" />
      <DiamondSingle size="6px" className="topRight" />
      <DiamondSingle size="6px" className="topLeft" />
      <RadarChart
        dataKeyToCompare={dataKeyToCompare}
        data={getData({
          data,
          selectedFeature,
          selectedModifiers,
          dataKeyToCompare,
          compareSelectedFeature,
          compareSelectedModifiers,
        })}
      />
      {/* <Line className="bottom" /> */}
      <VLine className="top" />
      {/* <VLine className="bottom" /> */}
    </RadarContainer>
  )
}

const RadarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 280px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .bottomRight,
  .bottomLeft {
    display: none;
  }

  @media (min-width: 1024px) {
    .bottomRight,
    .bottomLeft {
      display: block;
    }
  }

  .bottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -3px;
    margin-right: -3px;
  }
  .bottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -3px;
    margin-left: -3px;
  }
  .topRight {
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: -3px;
    margin-right: -3px;
  }
  .topLeft {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: -3px;
    margin-left: -3px;
  }
`
const Line = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  width: calc(100% - 20px);
  background-color: ${props => props.theme.colors.white30};
  position: absolute;
  height: 1px;
  margin-left: 0px;
  &.bottom {
    bottom: 0;
  }
  &.top {
    display: block;
    top: 3px;
  }
`
const VLine = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  width: 1px;
  background-color: ${props => props.theme.colors.white30};
  position: absolute;
  height: 30px;
  right: 0;
  &.bottom {
    bottom: 10px;
  }
  &.top {
    top: 10px;
  }
`

export default connect(
  state => ({
    selectedFeature: getSelectedFeature(state),
    selectedModifiers: getSelectedModifiers(state),
    compareSelectedModifiers: getComparingSelectedModifiers(state),
    compareSelectedFeature: getComparingSelectedFeature(state),
  }),
  actions,
)(Radar)
