import styled from 'styled-components/macro'

export const Cta = styled.div`
  pointer-events: auto !important;
  * {
    pointer-events: auto !important;
  }
  ${props =>
    props.fullWidth &&
    `
      width: 100%;
      height: 100%;
    `}
  ${props =>
    props.inline
      ? `display: inline`
      : `
        
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;  
      `}

  a {
    cursor: none;
    color: ${props => (props.isActive ? props.theme.colors.main : 'inherit')};
    text-transform: none;
    text-decoration: none;
    outline: none;

    * {
      outline: none;
    }
  }
`
