import styled from 'styled-components/macro'

export const Text = styled.div`
  text-align: center;
  font-size: 0.75rem;
  letter-spacing: 0.06rem;
  color: ${props => (props.isActive ? props.theme.colors.main : 'white')};
  text-transform: uppercase;
  height: 27px;
  display: flex;
  align-items: center;
  text-shadow: 1px 1px 4px #000;
  @media (min-width: 1024px) {
    font-size: 0.75rem;
    margin: 4px 4px 0 0;
  }
`

export const Wrapper = styled.div`
  border: 1px solid transparent;
  background: ${props =>
    props.isActive ? 'rgba(120, 120, 120, 0.5)' : 'inherit'};
`
