import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import Cta from '../../components/Cta'
import { TimelineLite } from 'gsap'

const getAnimation = targets =>
  new TimelineLite({ paused: true }).staggerTo(
    targets,
    0.1,
    {
      opacity: 1,
    },
    0.06,
  )

const ShareMenu = ({ show }) => {
  const rightMenu = useRef()
  const [animation, setAnimation] = useState()

  const menuEntries = [
    {
      label: 'Facebook',
      id: 1,
      href: `https://www.facebook.com/sharer/sharer.php?u=${
        window.location.href
      }`,
      onClick: () => {},
    },
    {
      label: 'Twitter',
      id: 2,
      href: `http://twitter.com/share?url=${window.location.href}`,
      onClick: () => {},
    },
  ]

  useEffect(() => {
    if (!rightMenu || !rightMenu.current) return
    setAnimation(getAnimation(rightMenu.current.children))
    return () => animation && animation.kill()
  }, [])

  useEffect(() => {
    if (!rightMenu || !rightMenu.current || !animation) return
    show ? animation.play() : animation.reverse()
  }, [show])

  return (
    <Container ref={rightMenu}>
      <Shadow />
      {menuEntries.map(({ label, onClick, href }) => (
        <React.Fragment key={label}>
          <Cta src={href} target="_blank" external>
            <Box>{label}</Box>
          </Cta>
        </React.Fragment>
      ))}
    </Container>
  )
}

export default ShareMenu

const Container = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 11px;
  bottom: 41px;
  height: 55px;
  margin-right: -4px;
  opacity: 1 !important;
  > * {
    opacity: 0;
  }

  @media (min-width: 1024px) {
    flex-flow: column nowrap;
    margin-left: 0;
    right: 0.8rem;
    top: 2.9rem;
    bottom: auto;
  }
`
const Box = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  width: 87px;
  text-align: center;
  border: 1px solid
    ${props => (props.isActive ? `transparent` : props.theme.colors.white30)};
  padding: 0.5rem 0.75rem;
  color: ${props => (props.isActive ? props.theme.colors.main : 'white')};
  margin-bottom: 6px;
  font-size: 0.75rem;
  pointer-events: auto;
  padding-bottom: 0.25rem;
  background: ${props =>
    props.isActive ? props => props.theme.colors.white30 : 'inherit'};
`

const Shadow = styled.div`
  background: radial-gradient(
    ellipse closest-side at center,
    rgba(23, 23, 23, 0.43) 2%,
    rgba(0, 0, 0, 0.05) 83%,
    transparent
  );
  position: absolute;
  top: -90px;
  right: -40px;
  width: 155%;
  height: 424%;
  z-index: -1;
`
