import React, { useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import Cta from '../../components/Cta'
import DiamondColumn from './DiamondColumn'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { useConsumerMobile } from '../../context/ContextMobile'

import { animateLeftMenu } from './animations'

const SecondaryMenu = ({ show, currentPath, onClick }) => {
  const rightMenu = useRef()
  const isMobile = useConsumerMobile()

  const { routes } = useConsumerTranslation()

  const { GALLERY, CUSTOMIZE, CONTACT, LANGUAGE_SWITCHER } = routes

  useEffect(() => {
    if (!show || !rightMenu || !rightMenu.current) return

    animateLeftMenu(rightMenu.current.children)
  }, [show])

  return (
    <Container show={show} ref={rightMenu}>
      <Shadow />
      {[LANGUAGE_SWITCHER, GALLERY, CUSTOMIZE, CONTACT].map(
        ({ label, path, external }) => (
          <React.Fragment key={label}>
            <DiamondColumn />
            <div className="toAnimate">
              <Cta src={path} external={external}>
                <Box isActive={currentPath === path} onClick={onClick}>
                  {label}
                </Box>
              </Cta>
            </div>
          </React.Fragment>
        ),
      )}
      <DiamondColumn hideLine={!isMobile} />
    </Container>
  )
}

export default SecondaryMenu

const Container = styled.div`
  margin-left: auto;
  height: 55px;
  display: flex;
  margin-right: -4px;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  flex-flow: row;
  > * {
    opacity: 0;
  }

  @media (min-width: 1024px) {
    margin-left: 0;
    position: absolute;
    right: 0;
    top: 1.1rem;
  }
`
const Box = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  ${props =>
    props.isActive
      ? `
      border: 1px solid transparent;
    `
      : `
    border: 1px solid rgba(120, 120, 120, 0.5);
    `};
  padding: 0.5rem 0.75rem;
  color: ${props => (props.isActive ? props.theme.colors.main : 'white')};
  margin: 1px;
  font-size: 0.75rem;
  pointer-events: auto;
  padding-bottom: 0.25rem;
  background: ${props =>
    props.isActive ? 'rgba(120, 120, 120, 0.5)' : 'inherit'};
`

const Shadow = styled.div`
  background: radial-gradient(
    ellipse closest-side at center,
    rgba(23, 23, 23, 0.43) 2%,
    rgba(0, 0, 0, 0.05) 83%,
    transparent
  );
  position: absolute;
  top: -90px;
  right: -40px;
  width: 155%;
  height: 424%;
  z-index: -1;
`
