import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components/macro'
import { SelectIcon, BurgerClose } from '../../svg'
import * as actions from '../../redux/customizer/actions'
import { getIsShowingFeatureSelect } from '../../redux/customizer/reducer'
import { TimelineLite, SteppedEase } from 'gsap'
import Cta from '../Cta'
import { useConsumerTranslation } from '../../context/ContextTranslation'

const SelectModel = props => {
  const {
    color,
    id,
    modelList,
    addModel,
    hideList,
    openFeatureSelect,
    closeFeatureSelect,
    removeCompare,
    isShowingFeatureSelect,
  } = props

  const { various } = useConsumerTranslation()
  const toShow = modelList.find(model => model.id === id)

  function toggleSelect() {
    if (!hideList) {
      isShowingFeatureSelect ? closeFeatureSelect() : openFeatureSelect()
    }
  }

  function removeFeatureSelection(e) {
    e.stopPropagation()
    if (addModel) {
      removeCompare()
    }
  }

  function changeSelected(id) {
    closeFeatureSelect()
    if (addModel) {
      addModel(id)
    }
  }

  return (
    <Wrapper>
      <Column>
        <Cta onClick={toggleSelect}>
          <ModelContainer
            color={color}
            noHover
            unselected={!toShow && !isShowingFeatureSelect}
          >
            <ChosenModel>
              <Label color={color}>
                {toShow ? toShow.label : various.compare}
              </Label>
              {!hideList && !toShow && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SelectIcon
                    style={{
                      margin: 'auto 5px',
                      transition: '0.3s ease-in-out',
                      transform: isShowingFeatureSelect
                        ? 'rotate(180deg)'
                        : 'none',
                    }}
                  />
                </div>
              )}
              {!hideList && toShow && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <BurgerClose
                    onClick={removeFeatureSelection}
                    style={{
                      margin: 'auto 5px',
                      transform: 'scale(0.7)',
                    }}
                  />
                </div>
              )}
            </ChosenModel>
          </ModelContainer>
        </Cta>
        {!hideList && (
          <ModelList
            show={isShowingFeatureSelect}
            modelList={modelList}
            color={color}
            changeSelected={changeSelected}
          />
        )}
      </Column>
    </Wrapper>
  )
}

const ModelList = ({ modelList, color, changeSelected, show }) => {
  const [tl, setTl] = useState()

  useEffect(() => {
    const animation = new TimelineLite({ paused: true })
      .set('.zio', { display: 'block' })
      .staggerTo(
        '.bella',
        0.1,
        {
          display: 'block',
          ease: SteppedEase.config(1),
        },
        0.1,
      )
    setTl(animation)
    return () => tl && tl.kill()
  }, [])

  useEffect(() => {
    if (tl) {
      show ? tl.play() : tl.reverse()
    }
  }, [show])

  return (
    <Column floating className="zio">
      {modelList.map(model => {
        const { label, id } = model
        return (
          <Cta onClick={() => changeSelected(id)} key={id}>
            <ModelContainer className="bella" color={color}>
              {label}
            </ModelContainer>
          </Cta>
        )
      })}
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  align-content: center;
  ${props =>
    props.floating &&
    css`
      display: none;
      position: absolute;
      margin-top: 37px;
      width: 100%;
      z-index: 1;
    `}
`

const ChosenModel = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
`

const ModelContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    `${props.theme.colors[props.color]}${props.unselected ? 50 : ''}`};
  color: white;
  padding: 0.5rem;
  font-size: 13px;
  height: 100%;
  margin: 2px 0;
  width: 100%;

  ${props =>
    !props.noHover &&
    css`
      display: none;
      height: auto;
    `}
`
const Wrapper = styled.div`
  margin: 0 1px;
  width: 100%;
`

const Label = styled.div`
  padding-top: 3px;
  color: ${props => (props.color === 'main' ? '#000' : '#fff')};
`

export default connect(
  state => ({
    isShowingFeatureSelect: getIsShowingFeatureSelect(state),
  }),
  actions,
)(SelectModel)
