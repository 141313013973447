import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import { connect } from 'react-redux'
import * as actions from '../../redux/push/actions'
import { getIsPushOn } from '../../redux/push/reducer'

import { useConsumerMobile } from '../../context/ContextMobile'

import Cta from '../Cta'
import { useStopWatch } from '../../customHooks'

const Push = ({ isPushOn, turnPushOn, turnPushOff }) => {
  const isMobile = useConsumerMobile()

  const { time, start, stop, reset } = useStopWatch()

  useEffect(() => {
    if (isPushOn) {
      start()
    } else {
      stop()
      reset()
    }
  }, [isPushOn, start, stop, reset])

  return (
    <Wrapper>
      <Box isActive={!isPushOn} onTouchStart={() => turnPushOff()}>
        <Cta onClick={() => {}} fullWidth>
          <Space>
            {isPushOn ? `${parseFloat(time / 1000, 1).toFixed(2)}s` : 'OFF'}
          </Space>
        </Cta>
      </Box>
      <Box
        onMouseDown={() => !isMobile && turnPushOn()}
        onMouseLeave={() => !isMobile && turnPushOff()}
        onMouseUp={() => !isMobile && turnPushOff()}
        onTouchStart={() => turnPushOn()}
        onTouchEnd={() => turnPushOff()}
        isActive={isPushOn}
      >
        <Cta onClick={() => {}} fullWidth>
          <Space toAnimate>PUSH</Space>
        </Cta>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 1024px) {
    min-width: 167px;
  }
`

const Space = styled.div`
  padding: 0.5rem 0;
  padding-bottom: 0.25rem;
  @keyframes lightLoop {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${props =>
    props.toAnimate &&
    css`
      animation: lightLoop 1.2s infinite;
    `}
`

const Box = styled.div`
  position: relative;
  text-transform: uppercase;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex: 1 0 50%;
  ${props =>
    props.isActive
      ? `
      border: 1px solid transparent;
    `
      : `
    border: 1px solid rgba(120, 120, 120, 0.5);
    `};
  color: ${props => (props.isActive ? props.theme.colors.main : 'white')};
  font-size: 0.75rem;
  pointer-events: all;
  background: ${props =>
    props.isActive ? 'rgba(120, 120, 120, 0.5)' : 'inherit'};
`

export default connect(
  state => ({ isPushOn: getIsPushOn(state) }),
  actions,
)(Push)
