import React from 'react'
import styled from 'styled-components/macro'

const ExploreMoreImage = styled.img`
  width: 100%;
  height: auto;
  margin: 1rem 0;
`

export default props => {
  const { src } = props
  return <ExploreMoreImage src={`${src}?w=1200`} />
}
