import React from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'
import { connect } from 'react-redux'
import * as mouseTrackerActions from '../../redux/mouseTracker/actions'

const Cta = ({
  children,
  src,
  inline,
  target,
  onMouseEnter,
  onMouseLeave,
  fullWidth,
  onClick,
  isActive,
  external,
  download,
}) => {
  return (
    <S.Cta
      fullWidth={fullWidth}
      inline={inline}
      isActive={isActive}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={e => {
        if (typeof onClick === 'function') {
          onClick(e)
        }
      }}
    >
      {typeof onClick === 'function' ? (
        children
      ) : (
        <LinkComponent
          external={external}
          src={src}
          target={target}
          download={download}
        >
          {children}
        </LinkComponent>
      )}
    </S.Cta>
  )
}

const LinkComponent = ({
  external,
  src,
  target,
  children,
  download = false,
}) => {
  if (external) {
    return (
      <a href={src} target={target}>
        {children}
      </a>
    )
  }
  if (download) {
    return (
      <a href={src} download target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }
  return (
    <Link to={src} target={target}>
      {children}
    </Link>
  )
}

export default connect(
  () => ({}),
  mouseTrackerActions,
)(Cta)
