import { CONTACT_PUSH_STATUS } from './types'

const defaultState = 'MAP'

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONTACT_PUSH_STATUS:
      return action.payload
    default:
      return state
  }
}

// export const getIsContactPushOn = state => state.contactPush
