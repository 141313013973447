import React from 'react'
import { Rombo } from './../../svg'

import * as S from './styles'

export default ({
  children,
  size = '8px',
  noBorder,
  withSegments,
  alignLines,
}) => (
  <S.DiamondFrame noBorder={noBorder}>
    <S.RomboWrapper size={size} top right>
      <Rombo size={size} />
    </S.RomboWrapper>
    <S.RomboWrapper size={size} bottom right>
      <Rombo size={size} />
    </S.RomboWrapper>
    <S.RomboWrapper size={size} bottom left>
      <Rombo size={size} />
    </S.RomboWrapper>
    <S.RomboWrapper size={size} top left>
      <Rombo size={size} />
    </S.RomboWrapper>
    {withSegments && (
      <>
        <S.LeftLine>
          <S.Top /> <S.Bottom />
        </S.LeftLine>
        <S.RightLine alignLines={alignLines}>
          <S.Top /> <S.Bottom />
        </S.RightLine>
        <S.TopLine>
          <S.Left />
          <S.Right />
        </S.TopLine>
        <S.BottomLine>
          <S.Left />
          <S.Right />
        </S.BottomLine>
      </>
    )}
    {children}
  </S.DiamondFrame>
)
