import { createSelector } from 'reselect'

import { getDirection } from '../slider/direction/reducer'
import { getIsSliding } from '../slider/isSliding/reducer'
import { getActiveDot } from '../slider/dots/reducer'
import { getIsSidebarOpen } from '../sidebar/reducer'
import { isInitialLoaderLoaded } from '../initialLoader/reducer'

export const isSlidable = createSelector(
  [
    getDirection,
    isInitialLoaderLoaded,
    getIsSidebarOpen,
    getIsSliding,
    getActiveDot,
  ],
  (a, b, c, d, e) => b && !c && !d && e === null,
)
