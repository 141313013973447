export default {
  dealerHiringMessage: `Diventare Dealer Dallara Stradale. <a href="mailto:dallarastradale@dallara.it" target="_blank">CONTATTACI</a>`,
  list: [
    {
      id: 5,
      name: 'FABBRICA DALLARA',
      description: `
        <a href="https://www.google.com/maps/place/Via+Guglielmo+Marconi,+18,+43040+Varano+de'+Melegari+PR/@44.6860218,10.0124628,17z/data=!3m1!4b1!4m5!3m4!1s0x47807c9380453b8b:0x65511ca5ab664c72!8m2!3d44.6860218!4d10.0146515" target="_blank" class="whiteLink" >
        Via Marconi 18<br/>43040 Varano de' Melegari - Italia</a><br/>Tel. +39 0525.550.711<br/>Mail 
        <strong>
        <a href="mailto:dallarastradale@dallara.it" target="_blank">dallarastradale@dallara.it</a>
        </strong>`,
      yellow: true,
      position: {
        top: 39.8,
        left: 49,
      },
      label: 'HQ',
    },
    {
      id: 1,
      name: 'DALLARA USA',
      description: `
      <a href="https://www.google.it/maps/place/Dallara/@39.7833344,-86.2422751,17z/data=!3m1!4b1!4m5!3m4!1s0x886b57c6a2a8799b:0x6324deda2b11e9ff!8m2!3d39.7833344!4d-86.2400864" target="_blank" class="whiteLink" >
        1201 Main St.<br /> Speedway, IN 46224<br/>
        Phone +39 0525.550.711<br/>
        Mail
        <strong>
        <a href="mailto:dallarastradale@dallara.it" target="_blank">dallarastradale@dallara.it</a>
        </strong>
      </a>
      `,
      yellow: true,
      position: {
        top: 36.2,
        left: 26.6,
      },
      label: 'HQ',
    },
    {
      id: 3,
      name: 'Benelux',
      description: `
        Dallara Stradale Benelux<br />
        Ertbruggestraat 108 C – 2110 Wijnegem – Belgium<br />
        Tel. +32 (0)476 599 115<br />
        Mail <strong><a href="mailto:info@dallarastradale.be" target="_blank">info@dallarastradale.be</a></strong><br />
        <a href="https://www.dallarastradale.be/" target="_blank">https://www.dallarastradale.be/</a>`,
      yellow: false,
      position: {
        top: 37,
        left: 48,
      },
      label: 'Ambassador',
    },
    {
      id: 6,
      name: 'GIAPPONE',
      description: `
      ATLANTIC CARS LTD<br/>
      <a href="https://www.google.it/maps/place/Embassy+of+Fiji/@35.659345,139.7403223,17z/data=!3m1!4b1!4m5!3m4!1s0x60188b978caa4dc5:0x723c08dfa4063ebe!8m2!3d35.659345!4d139.742511" class="whiteLink" target="_blank">
        3-5-5 Azabudai Minato-ku<br/>Tokyo 106-0041 Japan</a><br/>Tel. +81 (0)3-3583-8611<br/>
        Mail <strong><a href="mailto:info@atlantic-c.jp" target="_blank">info@atlantic-c.jp</a></strong><br />
        <a href="http://atlantic-c.jp/" target="_blank">http://atlantic-c.jp/</a>
        <br/>
        `,
      yellow: false,
      position: {
        top: 43.9,
        left: 82,
      },
      label: 'Ambassador',
    },
    {
      id: 4,
      name: 'SVIZZERA',
      description: `
      MASTER CLASS MOTORS SA<br/>
      <a href="https://www.google.it/maps/place/Via+Grancia+4,+6916+Grancia,+Svizzera/@45.9705367,8.9256052,17z/" target="_blank" class="whiteLink" >
      Via Grancia 4<br/>CH-6916 Racing Cars SA Lugano Svizzera</a><br/>
      Tel. +41.91.921.01.30<br/>
      Mail <strong><a href="mailto:cars@masterclassmotors.com" target="_blank">cars@masterclassmotors.com</a></strong><br />
      <a href="http://www.masterclassmotors.com" target="_blank">www.masterclassmotors.com</a>
        `,
      yellow: false,
      position: {
        top: 39.2,
        left: 47,
      },
      label: 'Ambassador',
    },
    {
      id: 7,
      name: 'REGNO UNITO',
      description: `
        Joe Macari Performance Cars<br/>
        <a href="https://goo.gl/maps/rL5gAjAuWa7AsYEU8" target="_blank" class="whiteLink" >
        249-251 Merton Road<br/>London UK</a><br/>
        Tel. +44 208 8709007<br/>
        Mail <strong><a href="mailto:sales@joemacari.com" target="_blank">sales@joemacari.com</a></strong><br />
        <a href="http://www.joemacari.com" target="_blank">www.joemacari.com/</a>
        `,
      yellow: false,
      position: {
        top: 35,
        left: 46,
      },
      label: 'Ambassador',
    },
    {
      id: 8,
      name: 'Germania',
      description: `
        DORR GROUP GMBH<br />
        Am Motodrom – 68766 Hockenheim – Germania<br />
        Tel. +49(0)6205-3066205<br />
        Mail <strong><a href="mailto:eric.hoernig@doerrgroup.com" target="_blank">eric.hoernig@doerrgroup.com</a></strong><br />
        <a href="https://www.doerrgroup.com" target="_blank">www.doerrgroup.com</a>`,
      yellow: false,
      position: {
        top: 38.2,
        left: 49,
      },
      label: 'Ambassador',
    },
    {
      id: 9,
      name: 'Francia',
      description: `
        Marcassus Sport<br />
        <a href="https://www.google.com/maps/place/Marcassus+Sport/@43.642121,1.3198799,12.21z/data=!4m8!1m2!2m1!1sMarcassus+sport!3m4!1s0x12aeb1b06964a127:0xe7fa183aa29e237e!8m2!3d43.6038948!4d1.3167608" target="_blank" class="whiteLink" >
        26 avenue du Louron - 31770 Colomiers (Toulouse)</a><br/>
        <a href="https://www.google.com/maps/place/Marcassus+sport/@44.7443164,-3.0322438,7z/data=!4m8!1m2!2m1!1sMarcassus+sport!3m4!1s0xd54dae3bf4fffff:0xb154469bcae0fcf5!8m2!3d44.8140517!4d-0.7282983" target="_blank" class="whiteLink" >
        40 Impasse du Forgeron - 33127 St Jean d'Illac (Bordeaux)</a><br/>
        Tel. + 33 (0)5 62 12 29 005<br/>
        Mail <strong><a href="mailto:contact@marcassus-sport.com" target="_blank">contact@marcassus-sport.com</a></strong><br />
        <a href="https://www.marcassus-sport.com/" target="_blank">www.marcassus-sport.com</a>`,
      yellow: false,
      position: {
        top: 41.5,
        left: 46,
      },
      label: 'Ambassador',
    },
    {
      id: 10,
      name: 'Monaco',
      description: `
        Stars Monte Carlo<br />
        <a href="https://www.google.com/maps/place/57+Rue+Grimaldi,+98000+Monaco/@43.7368192,7.4185701,17z/data=!3m1!4b1!4m5!3m4!1s0x12cdc28bf2844e33:0x7f96ee4809562856!8m2!3d43.7368153!4d7.4207588" target="_blank" class="whiteLink" >
        <a href="https://www.google.com/maps/place/Marcassus+sport/@44.7443164,-3.0322438,7z/data=!4m8!1m2!2m1!1sMarcassus+sport!3m4!1s0xd54dae3bf4fffff:0xb154469bcae0fcf5!8m2!3d44.8140517!4d-0.7282983" target="_blank" class="whiteLink" >
               57 Rue Grimaldi  - 98000 MONACO</a><br/>
        Tel. +377 92 000 999 <br/>
        Mail <strong><a href="mailto:info@stars.mc" target="_blank">info@stars.mc</a></strong><br />
        <a href="https://www.stars.mc" target="_blank">www.stars.mc</a>`,
      yellow: false,
      position: {
        top: 39.2,
        left: 48.3,
      },
      label: 'Ambassador',
    },
  ],
}
