export const isGallery = ({ path, routes }) => {
  const { GALLERY } = routes
  return path === GALLERY.path
}
export const isFullImage = ({ path, routes }) => {
  const { FULLIMAGE } = routes
  if (!FULLIMAGE) return
  return path.indexOf(FULLIMAGE.path.split('/')[1]) > -1
}
export const isCustomize = ({ path, routes }) => {
  const { CUSTOMIZE } = routes
  return path === CUSTOMIZE.path
}

export const isContact = ({ path, routes }) => {
  const { CONTACT } = routes
  return path === CONTACT.path
}

export const hasBottomBox = ({ currentID, slides }) => {
  const currentSlide = slides[currentID]
  return !!currentSlide.push
}

export const isPushHidden = ({ currentID, slides, isCustomize }) => {
  if (isCustomize) return false
  const currentSlide = slides[currentID]
  return typeof currentSlide.push === 'string'
}

export const getPaddingIfSafariMobile = () => {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') !== -1 && !ua.indexOf('chrome') > -1) {
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    const chrome = !!ua.match(/CriOS/i)

    if (iOS && webkit) {
      const orientation =
        window.screen.msOrientation ||
        window.screen.mozOrientation ||
        (window.screen.orientation || {}).type ||
        Math.abs(window.orientation)

      if (
        orientation === 'landscape-primary' ||
        orientation === 'landscape-secondary' ||
        orientation === 90
      ) {
        return '0px'
      } else if (
        orientation === 'portrait-secondary' ||
        orientation === 'portrait-primary' ||
        orientation === 180 ||
        orientation === 0
      ) {
        const iOSVersionMatch = ua.match(/iPhone OS (\d+)/i)

        if (!!iOSVersionMatch) {
          const iOSVersion = parseInt(iOSVersionMatch[1])
          if (iOSVersion < 12 && chrome) return '0px'

          return '65px'
        }
      }

      return '65px'
    }
  }
  return '0px'
}
