import styled from 'styled-components/macro'

const Paragraph = styled.p`
  letter-spacing: 0.1rem;
  line-height: 1.8rem;
  padding: 1rem 0;
  margin: 0;
`

export default Paragraph
