export default [
  {
    id: 'barchetta',
    label: 'Barchetta',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/01-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'barchettaWing',
    label: 'Barch. con ala',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/03-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'spider',
    label: 'Spider',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/02-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'coupe',
    label: 'Coupè',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/04-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
  {
    id: 'coupeWing',
    label: 'Coupè con ala',
    thumbnailUrl:
      'https://dallara.imgix.net/customize/05-Customize.jpg?fp-x=0.5&fp-y=0.5&fp-z=1.3&fit=crop&w=300&h=200&crop=focalpoint',
  },
]

export const customizeExploreMore = {
  label: 'VERSATILITY',
  content: [
    {
      type: 'paragraph',
      text: `For two years, the Dallara team worked on the project led by its Technical Director, the Engineer
      Giampaolo Dallara, with the aim of creating a car which is unique to all others. Thus a car was
      born that is doorless, like the racing single-seaters, and versatile, able to combine comfort with the
      performance of a supercar. A car that in its basic appearance is a Barchetta, but that can become
      a Roadster if equipped with a windshield, a Targa with the addition of the T-Frame, or a Coupe
      with the insertion of the two doors with gull-wing openings.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/07-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `The canopy is made entirely of carbon, with transparent polycarbonate surfaces, just like the Le
      Mans Prototype cars, with a total weight of less than 35 kg. It is the first time that a road car has
      had a polycarbonate windshield, giving it a weight reduction of more than 7 kg.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/05-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `The shape of the canopy is designed to minimise aerodynamic resistance, guaranteeing maximum
      visibility both on the track and on the road and allowing the use of the car in all weather
      conditions.`,
    },
    {
      type: 'image',
      src: 'https://dallara.imgix.net/customize/04-Versatility.jpg',
    },
    {
      type: 'paragraph',
      text: `The versatility of the Stradale is also found in the possibility to fully customise the driving
      experience, through the use of different accessories and features. You can approach the world of
      competitions, for example, with the use of an electronic data acquisition system (GPS Kit) and a
      smarty HD cam (Cockpit Camera). A combination that provides the driver with all the driving
      parameters, as in a real track acquisition system, and generates high definition videos with live
      telemetry, so as to optimise the driving style and improve performance lap by lap. The engine can
      also be customised through the use of a sports exhaust system for track use in combination with a
      new control unit that guarantees greater performance.`,
    },
  ],
}
