import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { TimelineLite, Expo, SteppedEase } from 'gsap'
import * as S from './styles'
import * as initialLoaderActions from '../../redux/initialLoader/actions'
import { getInitialLoaderImages } from '../../redux/initialLoader/reducer'
import theme from '../../theme'
import { Circle, DottedCircle, Rombo, Logo } from '../../svg'

import { useConsumerMobile } from '../../context/ContextMobile'

const LOADING_TIME = 4
const ANIMATION_TIME = 0.5
const sizes = [200, 210, 280, 350, 360, 400, 460, 460]

const FancyCircle = React.forwardRef((props, ref) => (
  <Circle {...props} innerRef={ref} />
))
const { main, dark, light } = theme.colors

const Loading = ({ setInitialLoader, initialLoaderImages }) => {
  const loadingRef = useRef()
  const myCircleRef2 = useRef()
  const myRombiWrapper = useRef()
  const [isLoaded, setIsLoaded] = useState(null)
  const isMobile = useConsumerMobile()

  useEffect(() => {
    if (initialLoaderImages.length && !isLoaded && loadingRef.current) {
      const loadingProps = { radius: 0, color1: 1, color2: 1 }

      if (!loadingRef.current) return

      new TimelineLite({
        onComplete: () => setIsLoaded(true),
      })
        .addLabel('init')
        .staggerTo(
          loadingRef.current.querySelectorAll('.circle'),
          1.3,
          {
            stroke: light,
            ease: SteppedEase.config(1),
            stagger: {
              ease: SteppedEase.config(3),
              amount: 2.35,
            },
          },
          null,
          'init',
        )
        .staggerTo(
          myRombiWrapper.current.querySelectorAll('.rombo'),
          LOADING_TIME,
          {
            fill: main,
            ease: Expo.easeIn,
            stagger: {
              ease: Expo.easeIn,
              amount: 0.25,
            },
          },
          null,
          'init',
        )
        .to(
          loadingProps,
          LOADING_TIME + 0.85,
          {
            radius: 500,
            color1: 0.55,
            color2: 0.55,
            ease: SteppedEase.config(3),
            onUpdate: () => {
              const { radius, color1, color2 } = loadingProps
              if (!loadingRef.current) return
              loadingRef.current.style.backgroundImage = `radial-gradient(${radius}px at center, rgba(0, 0, 0, ${color1}), rgba(0, 0, 0, ${color2}))`
            },
          },
          'init-=0.5',
        )
        .to(
          myCircleRef2.current,
          LOADING_TIME,
          {
            strokeDashoffset: 0,
            ease: Expo.easeIn,
          },
          'init',
        )
    }
  }, [initialLoaderImages])

  useEffect(() => {
    if (isLoaded && loadingRef.current) {
      new TimelineLite({
        onComplete: () => setInitialLoader(true),
      })
        .to(loadingRef.current, ANIMATION_TIME, {
          opacity: 0,
        })
        .set(loadingRef.current, {
          display: 'none',
        })
    }
  }, [isLoaded])

  return (
    <S.Loading ref={loadingRef}>
      <S.ScalingWrapper isMobile={isMobile}>
        <S.Center>
          <Circle size={sizes[0]} color={dark} />
        </S.Center>
        <S.Center>
          <Circle size={sizes[1]} color={dark} withClassName />
        </S.Center>
        <S.Center>
          <DottedCircle size={sizes[2]} />
        </S.Center>
        <S.Center rotate="-90deg">
          <FancyCircle
            ref={myCircleRef2}
            size={sizes[3]}
            strokeWidth={87}
            color={main}
            strokeOpacity={0.2}
            transform="scale(.8)"
            strokeDasharray={Math.PI * sizes[3]}
            strokeDashoffset={Math.PI * sizes[3]}
            style={{
              transform: 'scale(0.8)',
              transformOrigin: 'center',
            }}
          />
        </S.Center>
        <S.Center>
          <Circle size={sizes[3]} color={dark} withClassName />
        </S.Center>
        <S.Center>
          <Circle size={sizes[4]} color={dark} />
        </S.Center>
        <S.Center>
          <Circle size={sizes[6]} color={dark} withClassName />
        </S.Center>
        <S.Center>
          <S.RombiWrapper size={sizes[5]} ref={myRombiWrapper}>
            <S.RomboWrapper top>
              <Rombo color={light} />
            </S.RomboWrapper>
            <S.RomboWrapper right>
              <Rombo color={light} />
            </S.RomboWrapper>
            <S.RomboWrapper bottom>
              <Rombo color={light} />
            </S.RomboWrapper>
            <S.RomboWrapper left>
              <Rombo color={light} />
            </S.RomboWrapper>
          </S.RombiWrapper>
        </S.Center>

        <S.Center>
          <S.LogoWrapper size={sizes[1]}>
            <Logo size={sizes[1] - 25} />
          </S.LogoWrapper>
        </S.Center>
      </S.ScalingWrapper>
    </S.Loading>
  )
}

export default connect(
  state => ({
    initialLoaderImages: getInitialLoaderImages(state),
  }),
  initialLoaderActions,
)(Loading)
