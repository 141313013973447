import { CURRENT_ID_SET } from './types'

export default (state = 0, action) => {
  switch (action.type) {
    case CURRENT_ID_SET:
      return action.payload
    default:
      return state
  }
}

export const getCurrentID = state => state.slider.currentID
