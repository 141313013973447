import React from 'react'
import * as S from './styles'
import { ExploreMore, Close } from '../../svg'
import { useConsumerMobile } from '../../context/ContextMobile'
import Cta from '../Cta'

export default ({ onClick, text, close, fullWidth, isActive }) => {
  const isMobile = useConsumerMobile()
  const Icon = () => (close ? <Close /> : <ExploreMore />)
  return (
    <S.Wrapper isActive={isActive}>
      <Cta onClick={onClick} fullWidth={fullWidth}>
        {text && <S.Text isActive={isActive}>{text}</S.Text>}
        {!isMobile && <Icon />}
      </Cta>
    </S.Wrapper>
  )
}
