import styled from 'styled-components/macro'

const General = styled.div`
  position: absolute;
`
export const TopLeft = styled(General)`
  left: -5px;
  top: -5px;
  transform: translate(-50%, -50%);
`
export const TopRight = styled(General)`
  right: -5px;
  top: -5px;
  transform: translate(50%, -50%);
`
export const BottomLeft = styled(General)`
  left: -5px;
  bottom: -5px;
  transform: translate(-50%, 50%);
`
export const BottomRight = styled(General)`
  right: -5px;
  bottom: -5px;
  transform: translate(50%, 50%);
`
export const Top = styled(General)`
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  background-color: #fff;
`
export const Bottom = styled(General)`
  left: 0;
  bottom: 0;
  height: 10px;
  background-color: #fff;
  width: 100%;
`
export const Left = styled(General)`
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #fff;
  width: 10px;
`
export const Right = styled(General)`
  right: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  width: 10px;
`

export const LeftLine = styled(General)`
  left: -5px;
  top: 5px;
  height: calc(100% - 10px);
  width: 1px;
`
export const RightLine = styled(General)`
  right: -6px;
  top: 5px;
  height: calc(100% - 10px);
  width: 1px;
`
export const TopLine = styled(General)`
  left: 5px;
  top: -5px;
  width: calc(100% - 10px);
  height: 1px;
`
export const BottomLine = styled(General)`
  left: 5px;
  bottom: -6px;
  width: calc(100% - 10px);
  height: 1px;
`
