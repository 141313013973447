import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import { TimelineMax } from 'gsap/TimelineMax'

import SubMenu from './SubMenu'
import Cta from '../../components/Cta'
import { Diamond, DiamondSingle } from '../../svg'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { useConsumerMobile } from '../../context/ContextMobile'

const animateLeftMenu = targets => {
  const menuAnimation = new TimelineMax({ paused: true })
  return menuAnimation.staggerTo(
    targets,
    0.1,
    {
      opacity: 1,
    },
    0.06,
  )
}

const MainMenu = ({
  currentID,
  nextID,
  isSliding,
  setNextID,
  setIsSliding,
  show,
  isPushOn,
  unsetActiveDot,
  isInitialLoaderLoaded,
}) => {
  const [section, setSection] = useState(null)
  const { slides } = useConsumerTranslation()
  const isMobile = useConsumerMobile()
  const leftMenu = useRef()

  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    if (!leftMenu || !leftMenu.current) return
    setAnimation(animateLeftMenu(leftMenu.current.children))
  }, [])

  useEffect(() => {
    if ((section || section === 0) && !isSliding) {
      setNextID(section)
      unsetActiveDot()
      // momentaneamente disabilitato per check
      setIsSliding()
    }
  }, [section])

  useEffect(() => {
    if (!show || !isInitialLoaderLoaded || !animation) {
      return
    }

    animation.restart().play()
  }, [isInitialLoaderLoaded, show, animation])

  useEffect(() => {
    if (!animation || !isInitialLoaderLoaded) return

    if (isPushOn) {
      animation.reverse()
    } else {
      animation.play()
    }
  }, [isPushOn, isInitialLoaderLoaded])

  async function handleSection(ID) {
    if (isSliding) return
    setSection(ID)
  }

  return isMobile ? (
    <Wrapper show={show}>
      <TitleLabel isActive>{slides[nextID].title}</TitleLabel>
    </Wrapper>
  ) : (
    <Wrapper show={show}>
      <Container ref={leftMenu}>
        <Box>
          <DiamondSingle size="8px" className="leftSmallDiamond" />
        </Box>
        {slides.map((slide, index) => {
          if (index === 0) return null
          return (
            <React.Fragment key={index + 'cta'}>
              <Box>
                <Cta onClick={() => {}}>
                  <div
                    style={{ position: 'relative' }}
                    onClick={e => handleSection(index)}
                  >
                    <Diamond
                      width="22px"
                      className="leftBigDiamond"
                      isActive={nextID === index}
                    />
                  </div>
                  <TitleLabel
                    isActive={nextID === index}
                    onClick={e => handleSection(index)}
                  >
                    <span>{slide.title}</span>
                    {slide && slide.points && nextID === index && (
                      <SubMenu points={slide.points} isSliding={isSliding} />
                    )}
                  </TitleLabel>
                </Cta>
              </Box>
              <Box>
                <DiamondSingle size="8px" className="leftSmallDiamond" />
              </Box>
            </React.Fragment>
          )
        })}
      </Container>
    </Wrapper>
  )
}

export default MainMenu

const Wrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`

const Container = styled.div`
  height: 70%;
  display: flex;
  position: absolute;
  left: 0;
  top: 15%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 1;
  /* opacity: ${props => (props.isInitialLoaderLoaded ? 1 : 0)}; */
  /* transition: opacity 0.5s; */
  .leftSmallDiamond {
    margin-left: -4px;
  }
  .leftBigDiamond {
    margin-left: -10px;
  }
`
const Box = styled.div`
  opacity: 0;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  pointer-events: auto;
  top: 100px;
  @media (min-width: 1024px) {
    width: auto;
    justify-content: right;
    position: initial;
    top: auto;
  }
`
const TitleLabel = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.isActive ? props.theme.colors.main : props.theme.colors.light};
  @media (min-width: 1024px) {
    margin-left: 16px;
    text-align: left;
  }
  ${props =>
    props.isActive &&
    css`
      span {
        filter: drop-shadow(7px 7px 2px ${props => props.theme.colors.main});
      }
    `}
  ${props =>
    props.bottom &&
    css`
      position: absolute;
      bottom: 1rem;
      width: 100%;
      text-align: center;
    `}
`
