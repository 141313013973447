import React, { useRef, useEffect, useState } from 'react'
import * as THREE from 'three'
import { connect } from 'react-redux'
import { isInitialLoaderLoaded } from '../../redux/initialLoader/reducer'
import TweenMax from 'gsap/TweenMax'
import TimelineLite from 'gsap/TimelineLite'
import { Canvas, useThree } from 'react-three-fiber'

const vertex = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`

const fragment = `
  varying vec2 vUv;
  uniform float dispFactor;
  uniform sampler2D disp;
  uniform sampler2D texture1;
  uniform sampler2D texture2;
  uniform float angle1;
  uniform float angle2;
  uniform float intensity1;
  uniform float intensity2;
  mat2 getRotM(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat2(c, -s, s, c);
  }
  void main() {
    vec4 disp = texture2D(disp, vUv);
    vec2 dispVec = vec2(disp.r, disp.g);
    vec2 distortedPosition1 = vUv + getRotM(angle1) * dispVec * intensity1 * dispFactor;
    vec2 distortedPosition2 = vUv + getRotM(angle2) * dispVec * intensity2 * (1.0 - dispFactor);
    vec4 _texture1 = texture2D(texture1, distortedPosition1);
    vec4 _texture2 = texture2D(texture2, distortedPosition2);
    gl_FragColor = mix(_texture1, _texture2, dispFactor);
  }
`
const isInDefaultPosition = (x, y, z) => x === 0 && y === 0 && z === 5

const getMaterialParams = props => {
  const {
    intensity1,
    intensity2,
    angle1,
    angle2,
    texture1,
    texture2,
    disp,
  } = props
  return {
    uniforms: {
      intensity1: {
        type: 'f',
        value: intensity1,
      },
      intensity2: {
        type: 'f',
        value: intensity2,
      },
      dispFactor: {
        type: 'f',
        value: 0.0,
      },
      angle1: {
        type: 'f',
        value: angle1,
      },
      angle2: {
        type: 'f',
        value: angle2,
      },
      texture1: {
        type: 't',
        value: texture1,
      },
      texture2: {
        type: 't',
        value: texture2,
      },
      disp: {
        type: 't',
        value: disp,
      },
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity: 1.0,
  }
}

const cameraTransition = ({
  camera,
  isDefaultPos,
  xf,
  yf,
  zf,
  changeImage,
  ease,
}) => {
  const speed = isDefaultPos ? 0 : 1.5
  const { position } = camera
  const tl = new TimelineLite()
  tl.to(position, speed, {
    z: zf,
    x: xf,
  })
  tl.to(
    position,
    speed,
    {
      y: yf,
      ease,
    },
    0,
  )
  changeImage && tl.add(changeImage, isDefaultPos ? '0' : '-=1')
}

const generalTransition = ({ obj, speed, value, ease, onComplete }) => {
  TweenMax.to(obj, speed, {
    value,
    ease,
    onComplete,
  })
}

const transitionIn = ({ dispFactor, speedIn, easing, onComplete }) => {
  generalTransition({
    obj: dispFactor,
    speed: speedIn,
    value: 1,
    ease: easing,
    onComplete,
  })
}

const textureAsUniform = ({ texture, meshRef }) => {
  const { texture1, texture2, dispFactor } = meshRef.current.material.uniforms
  texture1.value = texture2.value
  texture2.value = texture
  dispFactor.value = 0.0
}

const preloadImages = ({ imageArray = [], loader }) => {
  const promises = imageArray.map(image => loadTexture({ image, loader }))
  return Promise.all(promises)
}

const loadTexture = ({ image, loader }) => {
  return new Promise(resolve => loader.load(image, resolve))
}

const loadTextures = ({ image1, image2, loader }) => {
  const promises = [
    loadTexture({ image: image1, loader }),
    loadTexture({ image: image2, loader }),
  ]
  return Promise.all(promises).then(result => {
    const texture1 = result[0]
    const texture2 = result[1]
    texture1.magFilter = texture2.magFilter = THREE.LinearFilter
    texture1.minFilter = texture2.minFilter = THREE.LinearFilter
    return { texture1, texture2 }
  })
}

const resizeMesh = ({ image, canvas }) => {
  const canvasAspect = canvas.clientWidth / canvas.clientHeight
  const imageAspect = image.width / image.height
  const horizontalDrawAspect = imageAspect / canvasAspect
  const verticalDrawAspect = 1
  if (horizontalDrawAspect < 1) {
    return { width: 1, height: verticalDrawAspect / horizontalDrawAspect }
  }
  return { width: horizontalDrawAspect, height: verticalDrawAspect }
}

const DisplacementAnimation = props => {
  const {
    dispImg,
    image,
    intensity1,
    intensity2,
    commonAngle,
    speedIn,
    easing,
    onComplete,
    coords,
    onFirstImageLoaded,
    imageArray,
    isAppLoaded,
  } = props
  const meshRef = useRef()
  const [shaderProps, setShaderProps] = useState()
  const loader = new THREE.TextureLoader()

  useEffect(() => {
    const angle1 = commonAngle
    const angle2 = -commonAngle * 3
    loadTextures({ image1: image, image2: dispImg, loader }).then(result => {
      if (imageArray && imageArray.length > 0) {
        preloadImages({ imageArray, loader })
      }
      const { texture1: imageTexture, texture2: dispTexture } = result
      onFirstImageLoaded(image)
      const shader = getMaterialParams({
        intensity1,
        intensity2,
        angle1,
        angle2,
        texture1: imageTexture,
        texture2: imageTexture,
        disp: dispTexture,
      })
      setShaderProps(shader)
    })
  }, [])

  useEffect(() => {
    if (meshRef.current) {
      loadTexture({
        image,
        loader,
      }).then(result => {
        textureAsUniform({ texture: result, meshRef })
        transitionIn({
          dispFactor: meshRef.current.material.uniforms.dispFactor,
          speedIn,
          easing,
          onComplete,
        })
      })
    }
  }, [image])

  return shaderProps ? (
    <Canvas>
      <Content
        meshRef={meshRef}
        shaderProps={shaderProps}
        coords={coords}
        isAppLoaded={isAppLoaded}
      />
    </Canvas>
  ) : null
}

const Content = ({ meshRef, shaderProps, coords = null, isAppLoaded }) => {
  const { gl, canvas, viewport, camera } = useThree()
  const [vpDimensions, setVpDimensions] = useState(viewport())
  const [dimensions, setDimensions] = useState(viewport())

  const { width, height } = dimensions
  const scale = [
    width <= 0 ? 0.00001 : width,
    height <= 0 ? 0.00001 : height,
    1,
  ]

  const onResize = () => {
    setTimeout(() => {
      camera.position.set(0, 0, 5)
      setVpDimensions(viewport())
      setDimensions(
        resizeMesh({
          image:
            meshRef &&
            meshRef.current &&
            meshRef.current.material &&
            meshRef.current.material.uniforms &&
            meshRef.current.material.uniforms.texture1 &&
            meshRef.current.material.uniforms.texture1.value &&
            meshRef.current.material.uniforms.texture1.value.image,
          canvas,
        }),
      )
    }, 10)
  }

  useEffect(() => {
    gl.setPixelRatio(window.devicePixelRatio)
    gl.alpha = 0
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    onResize()
  }, [isAppLoaded, meshRef.current])

  useEffect(() => {
    if (coords) {
      const { x, y, z } = camera.position
      const { xf = 0, yf = 0, zf = 5, changeImage, ease } = coords
      const isDefaultPos =
        isInDefaultPosition(x, y, z) && isInDefaultPosition(xf, yf, zf)
      cameraTransition({
        camera,
        isDefaultPos,
        xf,
        yf,
        zf,
        changeImage,
        ease,
      })
    }
  }, [coords])

  return (
    <mesh ref={meshRef} scale={scale}>
      <planeBufferGeometry
        name="geometry"
        args={[vpDimensions.width, vpDimensions.height, 1, 1]}
      />
      <shaderMaterial name="material" {...shaderProps} />
    </mesh>
  )
}

export default connect(state => ({
  isAppLoaded: isInitialLoaderLoaded(state),
}))(DisplacementAnimation)
