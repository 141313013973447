import styled, { css } from 'styled-components/macro'

export const CursorWrapper = styled.div.attrs(({ coords }) => ({
  style: {
    top: `${coords.y}px`,
    left: `${coords.x}px`,
  },
}))`
  position: absolute;
  z-index: 2;
  transform: rotate(45deg) translate(-50%, 0);
  transform-origin: center center;
  cursor: none;
  pointer-events: none;
`

export const CursorSmall = styled.div.attrs(({ isPositionOutside }) => ({
  style: {
    width: `${isPositionOutside ? '0' : '20px'}`,
    height: `${isPositionOutside ? '0' : '20px'}`,
    opacity: `${isPositionOutside ? 0 : 1}`,
  },
}))`
  top: 50%;
  border: 1px solid ${props => props.theme.colors.light};
  ${props =>
    props.mouseDarkMode &&
    css`
      border: 1px solid #000;
    `}
  left: 50%;
  position: absolute;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.96, 0.16, 0.36, 0.99);
  transform: translate(-50%, -50%);
  display: ${props => (props.isMouseDisabled ? 'none' : 'block')};
  pointer-events: none;
`

export const CursorBig = styled.div.attrs(
  ({ isPositionOutside, darkMode }) => ({
    style: {
      width: `${isPositionOutside ? '0' : '50px'}`,
      height: `${isPositionOutside ? '0' : '50px'}`,
      opacity: `${isPositionOutside ? 0 : 1}`,
    },
  }),
)`
  top: 50%;
  left: 50%;
  ${props =>
    props.isMouseActive
      ? `
      border: 1px solid ${props.theme.colors.main};
      background-color: ${props.theme.colors.main};
      width: 10px !important;
      height: 10px !important;
    `
      : `border: 1px solid ${props.theme.colors.dark}
    
    `}
  ${props =>
    props.mouseDarkMode &&
    css`
      border: 1px solid rgba(0, 0, 0, 0.3);
    `}
  position: absolute;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.96, 0.16, 0.36, 0.99);
  display: ${props => (props.isMouseDisabled ? 'none' : 'block')};
  transform: translate(-50%, -50%);
  pointer-events: none;
`

export const CursorClose = styled.div.attrs(({ isPositionOutside }) => ({
  style: {
    opacity: `${isPositionOutside ? 0 : 1}`,
    top: '50%',
    left: '50%',
    position: 'absolute',
    transitionTimingFunction: 'cubic-bezier(0.96, 0.16, 0.36, 0.99)',
  },
}))`
  display: ${props =>
    props.isMouseDisabled || !props.isMouseClose || props.isMouseActive
      ? 'none'
      : 'block'};
  transform: translate(-50%, -50%);
  pointer-events: none;

  &:before {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
    transform: translate(-5px);
    transform-origin: center;
  }
  &:after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
    transform: rotate(-90deg) translate(0px, -5px);
    transform-origin: center;
  }
`
