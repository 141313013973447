import React, { useState, useCallback } from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import YouTube from 'react-youtube'

import { GalleryGrid, Video, Image, Cta } from '../components'
import { useConsumerTranslation } from '../context/ContextTranslation'
import { useConsumerMobile } from '../context/ContextMobile'
import * as burgerActions from '../redux/burger/actions'
import { getIsBurgerOpen } from '../redux/burger/reducer'
import GridElementWrapper from '../components/GridElementWrapper'
import { isAndroid } from 'react-device-detect'

const GalleryItem = props => {
  const isMobile = useConsumerMobile()
  const { item, setFullScreenImageId, index, scrollContainer } = props
  const { type, id, videoId } = item

  const [isPlaying, setIsPlaying] = useState(false)
  const [player, setPlayer] = useState(null)

  const onPlayerReady = useCallback(e => {
    setPlayer(e.target)
  }, [])

  const play = useCallback(() => {
    player.playVideo()
  }, [player])

  const pause = useCallback(() => {
    player.pauseVideo()
  }, [player])

  const togglePlayback = useCallback(() => {
    isPlaying ? pause() : play()
  }, [isPlaying, play, pause])

  const onPlay = useCallback(() => {
    setIsPlaying(true)
  }, [])

  const onPause = useCallback(() => {
    setIsPlaying(false)
  }, [])

  switch (type) {
    case 'video':
      return (
        <>
          <Cta
            {...(isAndroid
              ? { src: `https://www.youtube.com/watch?v=${videoId}` }
              : { onClick: () => player && togglePlayback() })}
            fullWidth
          >
            <LazyLoad offset={200} once scrollContainer={scrollContainer}>
              <YouTubeWrapper>
                <YouTube
                  videoId={videoId}
                  onReady={onPlayerReady}
                  onPlay={onPlay}
                  onPause={onPause}
                  opts={{
                    width: '100%',
                    height: '100%',
                  }}
                  containerClassName="youtubeWrapper"
                />
              </YouTubeWrapper>
              {!isPlaying && (
                <VideoWrapper>
                  <Video
                    playing
                    muted
                    volume={0}
                    src={item.loop}
                    loop
                    controls={false}
                    playsinline
                    keepMouse
                  />
                  {!player && <Loading>Loading</Loading>}
                </VideoWrapper>
              )}
            </LazyLoad>
            <GridElementWrapper />
          </Cta>
        </>
      )
    case 'image':
      return (
        <Cta onClick={() => setFullScreenImageId(id)} fullWidth>
          <Image
            {...item}
            src={`${item.url}?${
              index === 3 || index === 5 ? `w=1000&h=1000` : `w=500&h=500`
            }&auto=format,compress&q=${isMobile ? '3' : ''}&fit=crop`}
            scrollContainer={scrollContainer}
          />
        </Cta>
      )
    default:
      return null
  }
}

const recursiveArrayToChunks = (arr, num) => {
  if (!arr) return []
  if (arr.length <= num) return [arr]
  const sliced = arr.slice(0, num)
  const toSlice = arr.slice(num)
  return [sliced].concat(recursiveArrayToChunks(toSlice, num))
}

const Gallery = ({ isBurgerOpen, closeBurger, openImage }) => {
  const [fullScreenImageId, setFullScreenImageId] = useState(null)
  const { gallery } = useConsumerTranslation()
  const isMobile = useConsumerMobile()
  const CHUNCK_LENGTH = isMobile ? 6 : 9
  const chunkedGallery = recursiveArrayToChunks(gallery, CHUNCK_LENGTH)

  function handleClick(id) {
    if (isBurgerOpen) {
      closeBurger()
    }
    openImage()
    setFullScreenImageId(id)
  }

  if (fullScreenImageId !== false && fullScreenImageId !== null)
    return <Redirect to={`/fullimage/${fullScreenImageId}`} />

  return (
    <>
      <Background />
      <GalleryWrapper className="scrollContainer">
        {chunkedGallery.map((galleryItems, galleryID) => (
          <GalleryGridWrapper key={galleryID}>
            <GalleryGrid
              items={galleryItems.map((item, index) => (
                <GalleryItem
                  index={index}
                  item={item}
                  setFullScreenImageId={handleClick}
                  scrollContainer={isMobile ? '.scrollContainer' : 'body'}
                />
              ))}
            />
          </GalleryGridWrapper>
        ))}
      </GalleryWrapper>
    </>
  )
}

const Background = styled.div`
  position: fixed;
  background-image: url('http://dallara.imgix.net/backgrounds/gallery.jpg?q=20');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
`

const YouTubeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;

  .youtubeWrapper {
    width: 100%;
    height: 100%;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: black;
`

const Loading = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.06rem;
  padding-top: 10px;
`

const GalleryGridWrapper = styled.div`
  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
  margin-bottom: 10px;
`
const GalleryWrapper = styled.div`
  padding: 8px 16px 1rem;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  box-sizing: border-box;
  * {
    overscroll-behavior: auto;
  }
  overscroll-behavior: auto;
  overflow-y: scroll;

  padding-top: calc(8rem + 8px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (min-width: 1024px) {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 8rem 46px 1rem;
  }
`

export default connect(
  state => ({
    isBurgerOpen: getIsBurgerOpen(state),
  }),
  {
    ...burgerActions,
  },
)(Gallery)
