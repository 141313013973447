import styled from 'styled-components/macro'

export const Sidebar = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1s;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`

export const SidebarInner = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 2rem;
  @media (min-width: 1024px) {
    padding: 3rem 9rem;
  }
`

export const LeftLine = styled.div`
  opacity: 0;
  position: absolute;
  left: 1rem;
  @media (min-width: 1024px) {
    left: 5rem;
  }
  width: 1px;
  background-color: #efefef;
  top: 0;
  bottom: 0;
`
export const ContentContainer = styled.div`
  > * {
    opacity: 0;
  }
`

export const RightLine = styled.div`
  opacity: 0;
  position: absolute;
  right: 1rem;
  @media (min-width: 1024px) {
    right: 5rem;
  }
  width: 1px;
  background-color: #efefef;
  top: 0;
  bottom: 0;
`

export const SidebarTitle = styled.span`
  margin: 0 auto;
  text-align: center;
  background-color: black;
  color: ${props => props.theme.colors.main};
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  padding: 0.5rem 0.75rem 0.25rem;
  font-size: 0.75rem;
  @media (min-width: 1024px) {
    max-width: 200px;
    margin: 0 0 2rem;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 4rem;
  text-align: center;
`

export const CloseWrapper = styled.div`
  z-index: 1;
  top: 50%;
  position: absolute;
  color: white;
  background-color: #fff;
  overflow: auto;
  display: flex;
  margin-top: -10px;
  transform: translate(-15px, -100%) rotate(45deg);
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
  }
  svg {
    transform: rotate(45deg);
  }
`
export const CloseMobileWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin: ${props => (props.bottom ? '0.3rem auto 1.5rem' : '1.5rem auto')};
`
