import { SLIDING_UNSET, SLIDING_SET } from './types'

export default (state = false, action) => {
  switch (action.type) {
    case SLIDING_SET:
      return true
    case SLIDING_UNSET:
      return false
    default:
      return state
  }
}

export const getIsSliding = state => state.slider.isSliding
