import styled from 'styled-components/macro'

export const ModifiersWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50%;
  margin-left: 5px;
  width: 160px;
}
`
export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
`
export const Modifier = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 5px 0;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`
export const CompareWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  height: 1.25rem;
`
export const CompareItemsResume = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  color: ${props => props.theme.colors.light};
  margin: 1rem 0;
  letter-spacing: 0.06rem;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  transition: 0.3s ease-in-out;
`
export const CompareItem = styled.div`
  margin-bottom: 0.5rem;
`
export const CompareItemTitle = styled.div`
  color: ${props => props.color};
`
export const Flex = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: center;
  width: calc(100% - 9px);
  margin: 4px auto;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
`
export const DiamondWrapper = styled.div`
  margin: 0 3px;
`
export const ModifierText = styled.div`
  margin-left: 5px;
  font-size: 0.7rem;
`
