import { TimelineLite, TweenMax, Elastic, Power2 } from 'gsap'
import CustomEase from '../../utils/CustomEase'

const ease = CustomEase.create(
  'custom',
  'M0,0 C0.25,0 0.297,0.046 0.335,0.078 0.436,0.162 0.438,0.302 0.47,0.512 0.512,0.86 0.53,0.898 0.584,0.946 0.623,0.98 0.698,1 1,1',
)

export const animateLeftMenu = targets => {
  const menuAnimation = new TimelineLite()
  return menuAnimation.staggerTo(
    targets,
    0.1,
    {
      opacity: 1,
    },
    0.06,
  )
}

export const animateFirstToggle = target => {
  if (!target || !target.current) return
  new TimelineLite({
    repeat: 2,
    repeatDelay: 0.7,
  })
    .to(
      target.current,
      0.2,
      {
        x: 20,
        yoyo: true,
        repeat: 2,
        ease: Power2.easeOut,
      },
      '+=1',
    )
    .to(target.current, 0.2, {
      x: 0,
      ease: Power2.easeOut,
    })
}

export const animateLeftMenuReverse = targets => {
  const menuAnimation = new TimelineLite()
  return menuAnimation.staggerTo(
    targets,
    0.1,
    {
      opacity: 0,
    },
    -0.06,
  )
}

export const animateRightMenu = targets => {
  const menuAnimation = new TimelineLite()
  return menuAnimation.staggerTo(
    targets,
    0.1,
    {
      opacity: 1,
    },
    0.06,
  )
}

export const animateRightMenuReverse = targets => {
  const menuAnimation = new TimelineLite()
  return menuAnimation.staggerTo(
    targets,
    0.1,
    {
      opacity: 0,
    },
    -0.06,
  )
}

// function percentToPixel(_elem, _perc) {
//   window._elem = _elem
//   return (_elem.getBoundingClientRect().width / 100) * parseFloat(_perc)
// }

export const showSimpleAnimation = (element, isMobile = false) => {
  TweenMax.to(element, 0.4, {
    autoAlpha: 1,
    // width: isMobile ? percentToPixel(element, 50) : percentToPixel(element, 100),
    // height: '100%',
    // ease,
  })
}

export const animateValues = (valueToChange, newValues, setObjToShow) => {
  let objnew = {
    nm: 1000,
    kmh: 1000,
    zeroOnehundred: 1000,
    g: 1000,
    downforce: 1000,
    kg: 1000,
  }
  var tl = new TimelineLite()
  tl.to(objnew, 1, {
    nm: newValues.nm,
    kmh: newValues.kmh,
    zeroOnehundred: newValues.zeroOnehundred,
    g: newValues.g,
    downforce: newValues.downforce,
    kg: newValues.kg,
    ease,
    roundProps: ['nm', 'kmh', 'zeroOnehundred', 'g', 'downforce', 'kg'],
    onUpdate: () => {
      setObjToShow(objnew)
    },
  })
}

export const showScatteredAnimationStagger = elements => {
  var tl = new TimelineLite()
  tl.staggerTo(
    elements,
    0.2,
    {
      autoAlpha: 1,
      ease: Elastic.easeIn,
      // repeat: 1,
      // yoyo: false,
    },
    0.1,
  )
}

export const hideScatteredAnimationStagger = elements => {
  var tl = new TimelineLite()
  tl.to(elements, 0.2, {
    autoAlpha: 0,
  })
}
export const hideSimpleAnimation = element => {
  var tl = new TimelineLite()
  tl.to(element, 0.2, {
    autoAlpha: 0,
    // width: 0,
    // height: 0,
  })
}
