import styled from 'styled-components/macro'

// background: radial-gradient(258px at center, rgba(0,0,0,0.2), rgba(0,0,0,0.8));

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
  background-image: radial-gradient(
    200px at center,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.95)
  );
`

export const ScalingWrapper = styled.div`
  transform: scale(${props => (props.isMobile ? 0.85 : 1)});
`

export const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.rotate
      ? `
      transform: translate(-50%, -50%) rotate(${props.rotate});
      transform-origin: center;
    `
      : `transform: translate(-50%, -50%);`}
`

export const LogoWrapper = styled.div`
  border-radius: 50%;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const RombiWrapper = styled.div`
  position: relative;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`

export const RomboWrapper = styled.div`
  position: absolute;
  ${props =>
    props.top &&
    `
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
  `}

  ${props =>
    props.bottom &&
    `
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
  `}

  ${props =>
    props.left &&
    `
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  `}


  ${props =>
    props.right &&
    `
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  `}
`

export const Logo = styled.img`
  display: block;
`
