import React from 'react'
import { Rombo } from '../../svg'
import styled, { css } from 'styled-components/macro'

const DiamondColumn = ({ hideLine, hideBottomRombo }) => {
  return (
    <Container>
      <Rombo size="6px" className="top" />
      <CenterLine hideLine={hideLine} />
      <Rombo size="6px" className="bottom" hide={hideBottomRombo} />
    </Container>
  )
}

const CenterLine = styled.div`
  right: 0;
  top: 12px;
  height: calc(100% - 25px);
  width: 1px;
  background-color: ${props => props.theme.colors.white30};
  flex: 1;
  ${props =>
    props.hideLine &&
    css`
      background-color: transparent;
    `};
`
const Container = styled.div`
  height: calc(100% - 11px);
  @media (min-width: 1024px) {
    height: 100%;
  }
  width: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  justify-content: center;
  flex-flow: column;
  align-content: center;
  align-items: center;

  .top {
    top: 0;
    left: 0;
    flex: 0 14px;
  }
  .bottom {
    bottom: 0;
    left: 0;
    flex: 0 14px;
  }
`

export default DiamondColumn
