export default [
  {
    id: 'cambio',
    defaultName: 'Stick shift',
    activeName: 'Paddle shift',
  },
  {
    id: 'sospensione',
    defaultName: 'Std. Suspen.',
    activeName: 'Racing Suspen.',
  },
  {
    id: 'pneumatico',
    defaultName: 'Pzero tyres',
    activeName: 'Trofeo R tyres',
  },
  {
    id: 'scarico',
    defaultName: 'Std. Exhaust',
    activeName: 'Racing exhaust',
  },
]
