export default [
  {
    text:
      'You are here to discover just a beautiful sports car. <br /> Well, you are wrong.',
    highlightText: '',
    image: 'https://dallara.imgix.net/slides/01-Dallara-Stradale.jpg',
    push: '',
  },
  {
    highlightText: `Dallara Stradale isn’t just an aesthetic exercise.<br/>It is the result of 40 Years of studies in the field<br/> of aerodynamics.`,
    image: 'https://dallara.imgix.net/slides/02-Aerodynamics.jpg',
    title: 'Aerodynamics',
    push: {
      src: 'https://dallara.imgix.net/push/aerodynamics.mp4',
    },
    video: {
      src: 'https://youtu.be/qaddqSi4bvA ',
      poster: 'https://dallara.imgix.net/posters/01-Aerodynamics.jpg',
      title: 'Dallara stories',
      description: 'Aerodynamics',
    },
    points: [
      {
        id: 1,
        label: 'Elephant Foot',
        title: 'Elephant Foot',
        description:
          'Built-in lateral outlet duct, to give energy to the flow<br/>from the front diffuser',
        x: 489, //La distanza da sinistra in pixel nell'immagine originale
        y: 528, //La distanza dal top in pixel dell'immagine originale
        bgX: -440,
        bgY: -393,
        bgXMiniLens: -628,
        bgYMiniLens: -681,
      },
      {
        id: 2,
        label: 'Wing',
        title: 'Wing',
        description:
          'The rear REMOVABLE carbon wing with a variable profile contributes to generating 820kg of downforce at top speed',
        x: 1148, //La distanza da sinistra in pixel nell'immagine originale
        y: 293, //La distanza dal top in pixel dell'immagine originale
        bgX: -909,
        bgY: -252,
        bgXMiniLens: -1506,
        bgYMiniLens: -366,
        zoomPositionX: 0.55,
        inverted: true,
      },
      {
        id: 3,
        label: 'Underfloor',
        title: 'Underfloor',
        description:
          'Front diffuser including the splitter<br/>and 3 main channels. Rear diffuser with 5 channels working in synchrony with the spoiler and the wing',
        x: 280, //La distanza da sinistra in pixel nell'immagine originale
        y: 220, //La distanza dal top in pixel dell'immagine originale
        bgX: -179,
        bgY: -192,
        bgXMiniLens: -344,
        bgYMiniLens: -271,
      },
      {
        id: 4,
        label: 'Radiator Ducts',
        title: 'Radiator Ducts',
        description:
          'The Air is collected  from the front to the radiator, through two long ducts inside the sills without doors',
        x: 552, //La distanza da sinistra in pixel nell'immagine originale
        y: 179, //La distanza dal top in pixel dell'immagine originale
        bgX: -376,
        bgY: -132,
        bgXMiniLens: -711,
        bgYMiniLens: -214,
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `From the kilometres at the simulator to the asphalt on racetracks all over the world. From the hours spent perfecting the details to the meticulous study of data. From the successes in racing cars to the lessons learned from mistakes. From the experiments to the innovations. We have brought all our knowledge from the racetrack to the road.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/01.jpg',
      },
      {
        type: 'paragraph',
        text: `This is how the Dallara Stradale came into being: embodying over 40 years of experience and passion for racing cars.`,
      },
      {
        type: 'paragraph',
        text: `The result? A vehicle which is a combination of design and sportiness, with an aerodynamic soul.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/02.jpg',
      },
      {
        type: 'paragraph',
        text: `In particular, thanks to the perfectionist study of the shapes of the car, the result of the experience on the racetrack with Formula, GT and Le Mans Prototype vehicles, the Dallara Stradale puts on to the road over 820 kilos of aerodynamic load at the top speed, allowing a lateral acceleration of more than 2G with road tyres. Function generates shape, Elegance meets efficiency.`,
      },
      {
        type: 'paragraph',
        text: `The racing soul is also shown in the characteristic details such as the built-in “elephant foot” ducts to energize the flow from the front splitter and from the three main channels of the front diffuser.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/03.jpg',
      },
      {
        type: 'paragraph',
        text: `The underfloor of the vehicle also includes the rear diffuser which, with its 5 channels, uses half the surface of the vehicle and works in perfect synchrony with the front diffuser, the spoiler, the wing and two large evacuations behind the rear wheels, to achieve the best engineering harmony.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/04.jpg',
      },
      {
        type: 'paragraph',
        text: `The challenges that have been faced in decades of racing cars have pushed the bar higher and higher, motivating us to find increasingly innovative solutions. This was our philosophy when we approached the internal flows of the vehicle and studied a solution of a layout without doors: this way we have reached maximum efficiency both for the radiator and for the engine aspiration placed in the rear part of the vehicle. The air is thus collected by two “nostrils” placed on the front hood of the car and ducted into the sides through the use of two diffusing ducts.`,
      },
      {
        type: 'paragraph',
        text: `The Dallara Stradale comes in two aerodynamic configurations: the Standard version and the Racetrack version, which has an easily removable rear wing with a variable profile, allowing the most suitable configuration for the driving style desired to be chosen.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/05.jpg',
      },
      {
        type: 'paragraph',
        text: `With the Dallara Stradale, the past becomes the future: we have concentrated decades of efforts into aerodynamics applied to racing cars, to create a car like no other`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/aerodynamics/06.jpg',
      },
    ],
  },
  {
    highlightText:
      'Dallara Stradale isn’t just the theory of vehicle dynamics. It is the experience of dynamics - on the track, the road and the driving simulator.',
    image: 'https://dallara.imgix.net/slides/04-Vehicle-Dynamics.jpg',
    title: 'Vehicle dynamics',
    video: {
      src: 'https://youtu.be/P0bm5yZpoSI ',
      poster: 'https://dallara.imgix.net/posters/02-Vehicle-Dynamics.jpg',
      title: 'Dallara stories',
      description: 'Vehicle dynamics',
    },
    push: {
      src: 'https://dallara.imgix.net/push/vehicle-dynamics.mp4',
    },
    points: [
      {
        id: 1,
        label: 'Tyres',
        title: 'Tyres',
        description:
          'Developed and optimized at the driving simulator, they guarantee, in combination with the mechanical steering, an immediate feeling with the car and very great lateral grip, when braking and accelerating. ',
        bgX: -706,
        bgY: -453,
        bgXMiniLens: -1152,
        bgYMiniLens: -818,
        x: 883, //La distanza da sinistra in pixel nell'immagine originale
        y: 632, //La distanza dal top in pixel dell'immagine originale
        zoomPositionY: 0.5,
      },
      {
        id: 2,
        label: 'Suspensions',
        title: 'Suspensions',
        description:
          'The double wishbone layout allows optimal control of the tyre contact patch,<br/>as in the racing cars',
        bgX: -750,
        bgY: -293,
        bgXMiniLens: -1188,
        bgYMiniLens: -584,
        x: 910, //La distanza da sinistra in pixel nell'immagine originale
        y: 457, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 3,
        label: 'Steering',
        title: 'Steering',
        description:
          'Fully Mechanical, without any electronic assistance, to give the driver the feeling of the direct connection with the road ',
        bgX: -780,
        bgY: -151,
        bgXMiniLens: -1175,
        bgYMiniLens: -258,
        x: 900, //La distanza da sinistra in pixel nell'immagine originale
        y: 212, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 4,
        label: 'Suspension',
        title: 'Suspension',
        description:
          'These allow setting the ride height of the vehicle, increasing the aerodynamic effect of the underfloor',
        bgX: -799,
        bgY: -292,
        bgXMiniLens: -1288,
        bgYMiniLens: -539,
        x: 983, //La distanza da sinistra in pixel nell'immagine originale
        y: 421, //La distanza dal top in pixel dell'immagine originale
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `A vehicle between projection and reality: Dallara Stradale is the result of the concrete ideas of the Dallara team yet it has been developed and tested in a virtual environment, thanks to the professional driving simulator. The data collected in decades of experience in the world of racing cars have helped us to model an ideal environment of study.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/01.jpg',
      },
      {
        type: 'paragraph',
        text: `The simulator is the very heart of the development of a vehicle projected into the future from as early as the design phase. Engineers, technicians and drivers defined the specifications of suspensions, engine and ergonomics, even before the Dallara Stradale was produced.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/03.jpg',
      },
      {
        type: 'paragraph',
        text: `The vehicle takes on to the road an excellent combination of grip and comfort. The front tyre contact patch on the road has been optimized to give the driver a direct connection with the asphalt, while the rear tyre guarantees comfort in terms of impact on the obstacles, so that every corner can be enjoyed and every imperfection of the road transformed into driving pleasure.`,
      },
      {
        type: 'paragraph',
        text: `It is a pleasure to be enjoyed in every condition, thanks to the  limited slip  differential and the ABS-ESP Electronic Stability Control which, together with the extremely high torsional stiffness of the monocoque, guarantee maximum performance without sacrificing stability. The best evolutions of racing cars at the service of the driver.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/02.jpg',
      },
      {
        type: 'paragraph',
        text: `One car, two complementary souls. Dallara Stradale comes with two different ways of driving: Road, which is the standard configuration to make your way through the urban jungle, and Racetrack, which allows lowering the vehicle by 20 mm, to reach its aerodynamic potential.`,
      },
      {
        type: 'paragraph',
        text: `The vehicle can be adapted to your needs; the two configurations can be selected by controls on the steering wheel, to guide you in every situation.`,
      },
      {
        type: 'image',
        src: 'https://dallara.imgix.net/explore-more/vehicle-dynamics/04.jpg',
      },
    ],
  },
  {
    highlightText: `Dallara Stradale is not just knowledge of light materials. It is 30 years of experience<br/> in simulation and manufacturing of carbon components.`,
    image: 'https://dallara.imgix.net/slides/03-Composite-Materials.jpg',
    video: {
      src: 'https://youtu.be/TRk3BKgn8l8',
      poster: 'https://dallara.imgix.net/posters/03-Composite-Materials.jpg',
      title: 'Dallara stories',
      description: 'Composite materials',
    },
    title: 'Composite materials',
    push: {
      src: 'https://dallara.imgix.net/push/composite-materials.mp4',
    },
    points: [
      {
        id: 1,
        label: 'Monocoque',
        title: 'Monocoque',
        description:
          'Pre-preg autoclave processing and hot compression moulding: maximum safety, lightness and stiffness, like racing cars',
        bgX: -486,
        bgY: -322,
        bgXMiniLens: -852,
        bgYMiniLens: -570,
        x: 661, //La distanza da sinistra in pixel nell'immagine originale
        y: 447, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 2,
        label: 'Bodywork',
        title: 'Bodywork',
        description:
          'Extremely lightweight, its aerodynamic shapes have been made using pre-preg autoclave processing and short fiber compression moulding',
        bgX: -868,
        bgY: -143,
        bgXMiniLens: -1335,
        bgYMiniLens: -311,
        x: 1020, //La distanza da sinistra in pixel nell'immagine originale
        y: 252, //La distanza dal top in pixel dell'immagine originale
      },
      {
        id: 3,
        label: 'Underfloor',
        title: 'Underfloor',
        description:
          'Air compression moulding: structural with high stiffness, to withstand the extreme aerodynamic load',
        bgX: -566,
        bgY: -454,
        bgXMiniLens: -721,
        bgYMiniLens: -848,
        x: 714, //La distanza da sinistra in pixel nell'immagine originale
        y: 654, //La distanza dal top in pixel dell'immagine originale
        zoomPositionY: 0.5,
      },
    ],
    content: [
      {
        type: 'paragraph',
        text: `Attention to the essential: this is the thought that guided the process of work on the materials. After having abandoned the superfluous, we concentrated on taking the concept of what is essential to the limit, using composite materials with carbon fibers. The result? The Dallara Stradale weighs only 855 kg. `,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/01.jpg',
      },
      {
        type: 'paragraph',
        text: `To make it, the pre-preg autoclave processing and long fiber compression moulding were used for the monocoque, the body panel system for the exterior panels and carbon sheet moulding for the reinforcements.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/02.jpg',
      },
      {
        type: 'paragraph',
        text: `But the soul of carbon goes deep-down: the carbon fiber has also been used in the invisible components such as the mounts, the levers and the interfaces.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/03.jpg',
      },
      {
        type: 'paragraph',
        text: `This does not prevent the Dallara Stradale from roaring on the asphalt and doing so in full control: it stops from 100 km/h in just 31 metres, thanks to the compact brake discs which allow lightening the suspensions and, as a consequence, the whole chassis.`,
      },
      {
        type: 'image',
        src:
          'https://dallara.imgix.net/explore-more/composite-materials/04.jpg',
      },
    ],
  },
  {
    title: 'The dream',
    highlightText: '',
    contact: {
      firstText: `Now you know Dallara Stradale<br />isn’t just a dream.<br /> It's a dream that has become reality,<br />the Ingegner Dallara's dream.`,
      secondText: `If you want to join it`,
      cta: {
        src: '/contact',
        text: 'CONTACT US',
      },
    },
    push: 'hide',
    video_center: {
      src: 'https://youtu.be/F_k-JPWRQQg',
      poster: 'https://dallara.imgix.net/posters/04-The-Dream.jpg',
      title: 'Il Sogno',
      description: 'Un viaggio nel sogno',
    },
    image: 'https://dallara.imgix.net/slides/05-The-Dream.jpg',
    content: [
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/01-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/01-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/02-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/02-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/03-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/03-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/04-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/04-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/05-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/05-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/06-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/06-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/07-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/07-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/08-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/08-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/09-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/09-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/10-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/10-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/11-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/11-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/12-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/12-Partner.png',
      },
      {
        type: 'logos',
        src: 'https://dallara.imgix.net/partners/desktop/13-Partner.png',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/13-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/14-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/15-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/16-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/17-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/18-Partner.png',
      },
      {
        type: 'logos',
        srcMobile: 'https://dallara.imgix.net/partners/mobile/19-Partner.png',
      },
    ],
    exploreMoreLabel: 'Partners',
  },
]
