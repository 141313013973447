import React from 'react'
import styled, { css } from 'styled-components/macro'

const Diamond = ({
  width,
  height,
  className,
  isActive,
  yellow,
  style = {},
  contacts,
}) => {
  return (
    <React.Fragment>
      <SvgContainer
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.213 21.213"
        width={width}
        height={height}
        style={style}
        yellow={yellow}
        isActive={isActive}
        className={className}
      >
        <g id="Group_1" transform="translate(-32.893 -176.515)">
          <g
            id="Forma"
            className="cls-1"
            transform="translate(43.5 176.515) rotate(45)"
          >
            <rect className="cls-3" width="15" height="15" />
            <rect className="cls-4" x="0.5" y="0.5" width="14" height="14" />
          </g>
          <rect
            id="Forma-2"
            className="cls-2"
            width="3"
            height="3"
            transform="translate(43.5 185) rotate(45)"
          />
        </g>
      </SvgContainer>
      <SvgContainerInner
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        contacts={contacts}
        viewBox="0 0 32.527 32.527"
        isActive={isActive}
      >
        <g id="Indicator" transform="translate(-0.237 -0.237)">
          <rect
            id="Forma"
            width="23"
            height="23"
            transform="translate(16.5 0.237) rotate(45)"
            fill="rgba(247,224,23,0.4)"
          />
          <rect
            id="Forma-2"
            data-name="Forma"
            width="15"
            height="15"
            transform="translate(16.5 5.893) rotate(45)"
            fill="rgba(247,224,23,0.6)"
          />
          <rect
            id="Forma-3"
            data-name="Forma"
            width="9"
            height="9"
            transform="translate(16.5 10.136) rotate(45)"
            fill="#f7e017"
          />
          <rect
            id="Forma-4"
            data-name="Forma"
            width="3"
            height="3"
            transform="translate(16.5 14.379) rotate(45)"
          />
        </g>
      </SvgContainerInner>
    </React.Fragment>
  )
}

const SvgContainer = styled.svg`
  .cls-1,
  .cls-4 {
    fill: none;
  }
  .cls-1 {
    stroke: ${props => (props.yellow ? props.theme.colors.main : '#fff')};
    stroke-miterlimit: 10;
  }
  .cls-2 {
    fill: ${props => (props.yellow ? props.theme.colors.main : '#fff')};
  }
  .cls-3 {
    stroke: none;
  }
  transition: 0.4s ease-in-out;
  opacity: 1;
  ${props =>
    props.isActive &&
    css`
      opacity: 0;
      transition: 0.4s cubic-bezier(0.96, 0.16, 0.36, 0.99);
    `}
`
const SvgContainerInner = styled.svg`
  display: block;
  position: absolute;
  margin-left: ${props => (props.contacts ? -6 : -16)}px;
  width: 33px;
  margin-top: -6px;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
  ${props =>
    props.isActive &&
    css`
      opacity: 1;
      margin-left: ${props => (props.contacts ? -6 : -16)}px
      width: 33px;
      margin-top:-6px;
      transition: 0.4s cubic-bezier(0.96, 0.16, 0.36, 0.99);
    `}
`
export default Diamond
