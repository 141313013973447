import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import DiamondFrame from '../DiamondFrame'
import CustomizeSelector from './CustomizeSelector'
import { showSimpleAnimation, hideSimpleAnimation } from './animations'
import { useConsumerMobile } from '../../context/ContextMobile'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import Toggler from '../Toggler'
import Push from '../Push'

const BottomBox = ({
  show,
  openSidebar,
  showSidebarCta,
  cta = {},
  push = true,
  isShowingTechData,
  hidePush = false,
  isCustomize,
  currentID,
}) => {
  const isMobile = useConsumerMobile()
  const { text, onClick } = cta
  const { various, slides } = useConsumerTranslation()
  const containerDesktop = useRef()

  useEffect(() => {
    if (!containerDesktop.current) return

    if (show) {
      showSimpleAnimation(containerDesktop.current, isMobile)
    } else {
      hideSimpleAnimation(containerDesktop.current)
    }
  }, [show])

  // if (!show) return null

  return isMobile ? (
    show && (
      <BoxContainer>
        {isCustomize && (
          <CustomizeSelectorWrapper show={!isShowingTechData}>
            <CustomizeSelector />
          </CustomizeSelectorWrapper>
        )}
        <BottomBoxContainer>
          {!hidePush && (
            <Container>
              <MaybeFlex flex={text && onClick}>
                <DiamondFrame alignLines noBorder withSegments>
                  {text && onClick && (
                    <Toggler
                      onClick={onClick}
                      text={text}
                      fullWidth
                      isActive={isShowingTechData}
                    />
                  )}
                </DiamondFrame>
              </MaybeFlex>
              {push && !text && <Push />}
            </Container>
          )}
          <Container
            ref={containerDesktop}
            disable={!showSidebarCta}
            hidePush={hidePush}
          >
            <DiamondFrame noBorder withSegments>
              <Toggler
                onClick={showSidebarCta ? openSidebar : () => null}
                text={slides[currentID].exploreMoreLabel || various.sidebarOpen}
                fullWidth
              />
            </DiamondFrame>
          </Container>
        </BottomBoxContainer>
      </BoxContainer>
    )
  ) : (
    <React.Fragment>
      {!hidePush && (
        <BottomBoxContainer>
          {push && (
            <Container ref={containerDesktop}>
              <MaybeFlex>
                <DiamondFrame alignLines noBorder withSegments />
              </MaybeFlex>
              <Flex>
                <Push />
              </Flex>
            </Container>
          )}
        </BottomBoxContainer>
      )}
      {isCustomize && (
        <CustomizeSelectorWrapper>
          <CustomizeSelector />
        </CustomizeSelectorWrapper>
      )}
    </React.Fragment>
  )
}

const MaybeFlex = styled.div`
  flex: ${props => (props.flex ? '1' : '0')};
`
const Flex = styled.div`
  flex-grow: 1;
`
const BoxContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0px;
`
const CustomizeSelectorWrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: relative;
  overflow-x: scroll;
  margin: 0 1px;
  @media (min-width: 1024px) {
    width: auto;
    display: block;
    bottom: -7px;
    right: -4px;
    left: auto;
    position: absolute;
    margin: 0;
  }
`
const BottomBoxContainer = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    right: auto;
    display: block;
  }
`
const Container = styled.div`
  display: flex;
  position: relative;
  padding: 0.5rem;
  flex: 1;
  ${props =>
    props.hidePush &&
    css`
      position: absolute;
      width: 50%;
      z-index: 1;
      bottom: 0px;
      right: 0;
    `}
  @media (min-width: 1024px) {
    opacity: 1;
  }
  ${props =>
    props.disable &&
    css`
      div {
        color: rgba(255, 255, 255, 0.3);
      }
    `}
`

export default BottomBox
