export default [
  {
    id: 'cambio',
    defaultName: 'cambio manuale',
    activeName: 'cambio paddle',
  },
  {
    id: 'sospensione',
    defaultName: 'sospen. standard',
    activeName: 'sospen. racing',
  },
  {
    id: 'pneumatico',
    defaultName: 'pneum. pzero',
    activeName: 'pneum. trofeo r',
  },
  {
    id: 'scarico',
    defaultName: 'scarico stradale',
    activeName: 'scarico racing',
  },
]
