import React from 'react'
import styled, { css } from 'styled-components'

const SvgCompo = styled.svg`
  width: ${props => props.size};
  height: ${props => props.size};
`

const SvgSelectorActive = props => {
  const { fill, size = '8px', className, noPulse = true, ...allTheRest } = props
  return (
    <SvgCompo
      className={className}
      size={size}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...allTheRest}
    >
      <g fill="none" fillRule="evenodd">
        <PathCustom
          noPulse={noPulse}
          fill={fill || '#FFF'}
          d="M6.5 4.379L8.621 6.5 6.5 8.621 4.379 6.5z"
        />
        <path d="M6.5 1.55l4.95 4.95-4.95 4.95L1.55 6.5z" stroke="#fff" />
      </g>
    </SvgCompo>
  )
}

const PathCustom = styled.path`
  opacity: 1;
  ${props =>
    !props.noPulse &&
    css`
      @keyframes pulse {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
    `}
`

export default SvgSelectorActive
