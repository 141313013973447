import {
  FEATURE_SELECT,
  MODIFIER_TOGGLE,
  COMPARE_ADD,
  COMPARE_REMOVE,
  SHOW_TECH_DATA,
  HIDE_TECH_DATA,
  OPEN_FEATURE_SELECT,
  CLOSE_FEATURE_SELECT,
} from './types'

export const selectFeature = payload => ({
  type: FEATURE_SELECT,
  payload,
})

export const toggleModifier = payload => ({
  type: MODIFIER_TOGGLE,
  payload,
})

export const addCompare = payload => ({
  type: COMPARE_ADD,
})

export const removeCompare = payload => ({
  type: COMPARE_REMOVE,
})

export const showTechData = payload => ({
  type: SHOW_TECH_DATA,
})

export const hideTechData = payload => ({
  type: HIDE_TECH_DATA,
})

export const openFeatureSelect = payload => ({
  type: OPEN_FEATURE_SELECT,
})

export const closeFeatureSelect = payload => ({
  type: CLOSE_FEATURE_SELECT,
})
