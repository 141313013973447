import { useState, useEffect, useRef, useCallback } from 'react'
import { useConsumerMobile } from '../context/ContextMobile'

export function useMyMouseTrackerPositionInPercentage(ref) {
  const [screenDimension, setScreenDimension] = useState(null)
  const [mousePosition, setMousePosition] = useState(null)
  const [
    mousePositionScreenPercentage,
    setMousePositionScreenPercentage,
  ] = useState(null)
  const isMobile = useConsumerMobile()

  function handleMouseMove(e) {
    if (isMobile && e.touches && e.touches.length > 0) {
      const { clientX, clientY } = e.touches[0]
      setMousePosition({
        x: clientX,
        y: clientY,
      })
    } else {
      setMousePosition({
        x: e.x,
        y: e.y,
      })
    }
  }

  useEffect(() => {
    if (mousePosition && screenDimension) {
      setMousePositionScreenPercentage({
        x: -(mousePosition.x - screenDimension.x / 2) / (screenDimension.x / 2),
        y: -(mousePosition.y - screenDimension.y / 2) / (screenDimension.y / 2),
      })
    }
  }, [mousePosition, screenDimension])

  useEffect(() => {
    setScreenDimension({
      x: window.innerWidth,
      y: window.innerHeight,
    })
    if (isMobile) {
      window.addEventListener('touchmove', handleMouseMove)
    } else {
      window.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      if (isMobile) {
        window.removeEventListener('touchmove', handleMouseMove)
      } else {
        window.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [])

  return {
    ...mousePositionScreenPercentage,
    screenX: screenDimension && screenDimension.x,
    screenY: screenDimension && screenDimension.y,
  }
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useStopWatch() {
  const [time, setTime] = useState(0)
  const [start, setStart] = useState(Date.now())
  const [isRunning, setIsRunning] = useState(false)

  useInterval(
    () => {
      setTime(Date.now() - start)
    },
    isRunning ? 50 : null,
  )

  return {
    start: useCallback(() => {
      if (time === 0) {
        setStart(Date.now())
      }

      setIsRunning(true)
    }),
    stop: useCallback(() => {
      setIsRunning(false)
    }),
    reset: useCallback(() => {
      setTime(0)
    }),
    isRunning,
    time,
  }
}
