import styled from 'styled-components/macro'

export const DiamondFrame = styled.div`
  box-sizing: border-box;
  ${props => !props.noBorder && `border: 1px solid ${props.theme.colors.dark}`};
  width: 100%;
`

export const RomboWrapper = styled.div`
  position: absolute;
  display: flex;
  ${props => props.top && `top: calc(-${props.size} / 2)`};
  ${props => props.left && `left: calc(-${props.size} / 2) `};
  ${props => props.bottom && `bottom: calc(-${props.size} / 2)`};
  ${props => props.right && `right: calc(-${props.size} / 2)`};
`

export const Top = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  background-color: #fff;
`
export const Bottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 10px;
  background-color: #fff;
  width: 100%;
`
export const Left = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #fff;
  width: 10px;
`
export const Right = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  width: 10px;
`

export const LeftLine = styled.div`
  position: absolute;
  left: 0;
  top: 8px;
  height: calc(100% - 16px);
  width: 1px;
`
export const RightLine = styled.div`
  position: absolute;
  right: ${props => (props.alignLines ? '-1px' : '0px')};
  top: 8px;
  height: calc(100% - 16px);
  width: 1px;
`
export const TopLine = styled.div`
  position: absolute;
  left: 8px;
  top: 0;
  width: calc(100% - 16px);
  height: 1px;
`
export const BottomLine = styled.div`
  position: absolute;
  left: 8px;
  bottom: 0;
  width: calc(100% - 16px);
  height: 1px;
`
