import styled from 'styled-components/macro'

export const RadarChartWrapper = styled.div`
  margin: auto;
  .recharts-polar-grid-angle {
    line {
      stroke: rgb(255, 255, 255, 0.2);
      stroke-width: 1;
      stroke-dasharray: none;
      stroke-linecap: none;
    }
  }

  .recharts-polar-grid-concentric > :last-child {
    stroke-dasharray: none;
    stroke-linecap: none;
  }

  .recharts-polar-angle-axis-tick-value {
    text-transform: uppercase;
  }
`
