import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { connect } from 'react-redux'
import { useConsumerTranslation } from '../../context/ContextTranslation'
import { useConsumerMobile } from '../../context/ContextMobile'
import MainMenu from './MainMenu'
import Track from './Track'
import CompareItemsResume from './CompareItemsResume'
import BottomBox from './BottomBox'
import BottomBoxContacts from './BottomBoxContacts'
import SecondaryMenu from './SecondaryMenu'
import FullImageMenu from './FullImageMenu'
import RightCornerVideo from './RightCornerVideo'
import ExploreMore from './ExploreMore'
import { Cta, AddressDescription } from '../../components'
import { DiamondSingle, Logo, Burger, BurgerClose } from '../../svg'
import { isInitialLoaderLoaded } from '../../redux/initialLoader/reducer'
import { getCurrentID } from '../../redux/slider/currentID/reducer'
import { getIsSliding } from '../../redux/slider/isSliding/reducer'
import { getNextID } from '../../redux/slider/nextID/reducer'
import sliderActions from '../../redux/slider/actions'
import * as sidebarActions from '../../redux/sidebar/actions'
import * as customizerActions from '../../redux/customizer/actions'
import * as burgerActions from '../../redux/burger/actions'
import {
  getSelectedFeature,
  getIsShowingTechData,
} from '../../redux/customizer/reducer'
import { getIsPushOn } from '../../redux/push/reducer'
import { getIsBurgerOpen } from '../../redux/burger/reducer'

import {
  isCustomize,
  isGallery,
  isFullImage,
  hasBottomBox,
  isPushHidden,
  isContact,
} from '../../utils/conditions'

const Customize = () => (
  <TrackWrapper>
    <Track show />
    <CompareItemsResume show />
    {/* <Specs /> */}
  </TrackWrapper>
)

const Hud = ({
  isInitialLoaderLoaded,
  isHome,
  isFullimage,
  currentID,
  nextID,
  isSliding,
  openSidebar,
  closeSidebar,
  isBurgerOpen,
  openBurger,
  closeBurger,
  closeImage,
  setNextID,
  setIsSliding,
  path,
  params,
  isShowingTechData,
  showTechData,
  hideTechData,
  isPushOn,
  contactPush,
  unsetActiveDot,
}) => {
  const leftMenu = useRef()
  const rightMenu = useRef()
  const bottomMenu = useRef()
  const { slides, routes, various } = useConsumerTranslation()
  const isMobile = useConsumerMobile()
  const { HOME } = routes
  const { contacts } = useConsumerTranslation()

  useEffect(() => {
    const handleKeys = event => {
      if (
        event.isComposing ||
        event.keyCode === 229 ||
        !(isHome && slides[nextID].content && !isSliding)
      ) {
        return
      }

      switch (event.keyCode) {
        case 39:
          openSidebar()
          break
        case 37:
          closeSidebar()
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeys, false)

    return () => {
      window.removeEventListener('keydown', handleKeys, false)
    }
  }, [isHome, slides, isSliding])

  return (
    <HudWrapper fullHeight={isGallery({ path, routes })}>
      <TouchController>
        {isMobile && (
          <>
            <MobileMenu>
              <SecondaryMenu
                onClick={closeBurger}
                show={isInitialLoaderLoaded || !isHome}
                currentPath={path}
                params={params}
              />
            </MobileMenu>
            <BurgerWrapper show={isInitialLoaderLoaded || !isHome}>
              {!isFullimage &&
                (!isBurgerOpen ? (
                  <Burger onClick={openBurger} />
                ) : (
                  <BurgerClose onClick={closeBurger} />
                ))}
              {isFullimage && isMobile && <BurgerClose onClick={closeImage} />}
            </BurgerWrapper>
          </>
        )}
        {(isInitialLoaderLoaded || !isHome) && (
          <LogoWrapper>
            <Cta
              {...(!isHome
                ? { src: HOME.path }
                : {
                    onClick: () => {
                      if (currentID === 0) return
                      setNextID(0)
                      setIsSliding()
                    },
                  })}
            >
              <Logo />
            </Cta>
          </LogoWrapper>
        )}
        <MainMenu
          currentID={currentID}
          nextID={nextID}
          isSliding={isSliding}
          isInitialLoaderLoaded={isInitialLoaderLoaded}
          show={isHome}
          setNextID={setNextID}
          setIsSliding={setIsSliding}
          isPushOn={isPushOn}
          unsetActiveDot={unsetActiveDot}
        />
        {!isMobile && (
          <>
            {!isFullImage({ path, routes }) && (
              <SecondaryMenu
                show={isInitialLoaderLoaded || !isHome}
                currentPath={path}
                params={params}
              />
            )}
            <ExploreMore
              show={
                (isHome &&
                  slides[nextID] &&
                  slides[nextID].content &&
                  !isSliding) ||
                isCustomize({ path, routes })
              }
              onClick={() => openSidebar()}
              nextID={nextID}
              isPushOn={isPushOn}
              label={slides[nextID].exploreMoreLabel || various.sidebarOpen}
            />
            <RightCornerVideo
              show={isHome && !isPushOn}
              slides={slides}
              currentID={currentID}
              isSliding={isSliding}
            />
          </>
        )}
        {isFullImage({ path, routes }) && (
          <FullImageMenu
            show={isInitialLoaderLoaded || !isHome}
            currentPath={path}
            params={params}
          />
        )}
        <BottomBox
          currentID={currentID}
          show={
            (!isGallery({ path, routes }) &&
              !isContact({ path, routes }) &&
              !isFullImage({ path, routes }) &&
              hasBottomBox({ currentID, slides })) ||
            isCustomize({ path, routes })
          }
          hidePush={isPushHidden({
            currentID,
            slides,
            isCustomize: isCustomize({ path, routes }),
          })}
          cta={
            isCustomize({ path, routes }) && {
              text: !isShowingTechData ? 'TECHNICAL DATA' : 'CLOSE',
              onClick: () =>
                isShowingTechData ? hideTechData() : showTechData(),
            }
          }
          openSidebar={() => {
            if (isShowingTechData) {
              hideTechData()
            }
            openSidebar(true)
          }}
          showSidebarCta={
            (isHome && slides[nextID].content && !isSliding) ||
            isCustomize({ path, routes })
          }
          push={isHome}
          isShowingTechData={isShowingTechData}
          isCustomize={isCustomize({ path, routes })}
        />
        {isContact({ path, routes }) && !isMobile && <BottomBoxContacts />}
        {/* {isContact({ path, routes }) && <BottomBoxNewDealersCTA />}
        {isContact({ path, routes }) && <Shadow />} */}
        {(isShowingTechData || !isMobile) &&
          isCustomize({ path, routes }) &&
          (isMobile ? (
            <ScrollableTrackWrapper>
              <Customize />
            </ScrollableTrackWrapper>
          ) : (
            <Customize />
          ))}

        <LeftMenuLine
          ref={leftMenu}
          fullHeight={isGallery({ path, routes, slides })}
          bottomBoxShow={
            !isGallery({ path, routes, slides }) &&
            !isCustomize({ path, routes, slides }) &&
            !isContact({ path, routes }) &&
            hasBottomBox({ currentID, slides }) &&
            !isPushHidden({ currentID, slides })
          }
        />
        <RightMenuLine
          ref={rightMenu}
          fullHeight={isGallery({ path, routes, slides })}
          bottomBoxShow={
            !isGallery({ path, routes, slides }) &&
            !isCustomize({ path, routes, slides }) &&
            !isContact({ path, routes }) &&
            hasBottomBox({ currentID, slides }) &&
            !isPushHidden({ currentID, slides })
          }
        />
        <BottomMenuLine
          ref={bottomMenu}
          bottomBoxShow={
            !isGallery({ path, routes }) &&
            !isCustomize({ path, routes }) &&
            !isContact({ path, routes }) &&
            hasBottomBox({ currentID, slides }) &&
            !isPushHidden({ currentID, slides })
          }
          bottomLineRemove={isGallery({ path, routes })}
        />

        {contactPush === 'LIST' && isContact({ path, routes }) && (
          <DesktopContainerList>
            {contacts.list.map(({ position, id, ...contact }) => (
              <AddressDescription {...contact} key={id} hud />
            ))}
          </DesktopContainerList>
        )}

        {!isGallery({ path, routes, slides }) && (
          <DiamondSingle width="10px" height="10px" className="bottomRight" />
        )}
        {!isGallery({ path, routes, slides }) && (
          <DiamondSingle width="10px" height="10px" className="bottomLeft" />
        )}
        {!isSliding && isInitialLoaderLoaded && isHome && currentID !== 4 && (
          <CtaScroll>Scroll to discover</CtaScroll>
        )}
      </TouchController>
    </HudWrapper>
  )
}
const CtaScroll = styled.div`
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7rem;
  letter-spacing: 0.06rem;
  color: rgba(255, 255, 255, 0.4);
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  @media (min-width: 1024px) {
    bottom: 1rem;
  }
`
const TouchController = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  > * {
    pointer-events: all;
  }
`
const DesktopContainerList = styled.div`
  display: flex;
  padding-top: 149px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 2;
  max-width: 760px;
  flex-direction: row;
  max-height: 70%;
`

const HudWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 0;
  box-sizing: border-box;
  ${props =>
    props.fullHeight &&
    css`
      padding-bottom: 0;
    `}
  @media (min-width: 1024px) {
    padding: 40px;
    padding-top: 0;
  }
`

const ScrollableTrackWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px;
  left: -5px;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 70%,
    rgba(0, 0, 0, 0) 75%
  );
  @media (orientation: landscape) {
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 55%,
      rgba(0, 0, 0, 0) 58%
    );
  }
  @media (min-width: 1024px) {
    -webkit-overflow-scrolling: auto;
  }
`

const TrackWrapper = styled.div`
  width: calc(100% - 11px);
  height: 120%;
  position: absolute;
  @media (min-width: 1024px) {
    bottom: 0;
    left: 0;
    height: auto;
    display: flex;
    flex-flow: column;
    width: 350px;
  }
`

const LogoWrapper = styled.div`
  width: 300px;
  text-align: center;
  margin: 0 auto 1rem;
  @media (min-width: 1024px) {
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;
  }
`
const BurgerWrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  text-align: center;
  padding: 1rem;
`
const LeftMenuLine = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  bottom: 10px;
  width: 1px;
  background-color: ${props => props.theme.colors.white30};
  transition: 0.3s ease-in-out;
  ${props =>
    props.bottomBoxShow &&
    css`
      bottom: 50px;
    `}
  ${props =>
    props.fullHeight &&
    css`
      bottom: 0px;
    `}
`
const RightMenuLine = styled.div`
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  bottom: 10px;
  background-color: ${props => props.theme.colors.white30};
  transition: 0.3s ease-in-out;
  ${props =>
    props.bottomBoxShow &&
    css`
      bottom: 50px;
    `}
  ${props =>
    props.fullHeight &&
    css`
      bottom: 0px;
    `}
  @media (min-width: 1024px) {
    top: 23px;
    bottom: 10px;
  }
`
const BottomMenuLine = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.colors.white30};
  width: calc(100% - ${props => (props.bottomBoxShow ? '100%' : '20px')});
  height: 1px;
  right: 10px;
  transition: 0.3s ease-in-out;
  opacity: ${props => (props.bottomLineRemove ? '0' : '1')};

  @media (min-width: 1024px) {
    width: calc(100% - ${props => (props.bottomBoxShow ? '200px' : '20px')});
  }
`

const MobileMenu = styled.div`
  position: absolute;
  display: flex;
  top: -60px;
  left: 0;
  height: 60px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export default connect(
  state => ({
    isInitialLoaderLoaded: isInitialLoaderLoaded(state),
    currentID: getCurrentID(state),
    nextID: getNextID(state),
    isSliding: getIsSliding(state),
    featureSelected: getSelectedFeature(state),
    isBurgerOpen: getIsBurgerOpen(state),
    isShowingTechData: getIsShowingTechData(state),
    isPushOn: getIsPushOn(state),
    contactPush: state.contactPush,
  }),
  {
    ...sliderActions,
    ...sidebarActions,
    ...customizerActions,
    ...burgerActions,
  },
)(Hud)
