export default {
  LANGUAGE_SWITCHER: {
    path: process.env.REACT_APP_LANGUAGE_SWITCHER,
    label: 'It',
    external: true,
  },
  CUSTOMIZE: {
    path: '/interact',
    label: 'Interact',
  },
  GALLERY: {
    path: '/gallery',
    label: 'Gallery',
  },
  CONTACT: {
    path: '/contact',
    label: 'Contact',
  },
  FULLIMAGE: {
    path: '/fullimage/:id?',
    label: 'Customize',
  },
  HOME: {
    path: '/',
    label: 'Home',
  },
}
