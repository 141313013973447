import { useEffect, useState } from 'react'

export function useMagicTranslation({ ref, x, y, screenY, screenX }) {
  const [translation, setTranslationValue] = useState({
    translateX: 0,
    translateY: 0,
  })

  useEffect(() => {
    if (!ref || !ref.current || !x || !y || !screenX || !screenY) return
    const { width, height } = ref.current.getBoundingClientRect()
    setTranslationValue({
      translateX: (width / 2 - screenX / 2) * x,
      translateY: (height / 2 - screenY / 2) * y,
    })
  }, [ref, x, y])

  return translation
}

export function getTotalWidthFromParent(parentRef) {
  if (parentRef.current && parentRef.current.children) {
    const children = [...parentRef.current.children]
    const childrenWidth = children.map(item => {
      const { width } = item.getBoundingClientRect()
      return width ? width : 0
    })
    return childrenWidth.reduce((a, b) => {
      return a + b
    }, 0)
  }
  return 0
}
