import React from 'react'

const SvgClose = props => {
  const { fill = '#FFF' } = props
  return (
    <svg width={15} height={15} {...props} viewBox="0 0 15 15">
      <g fill={fill} fillRule="evenodd">
        <path d="M14 14h1v1h-1zM13 13h1v1h-1zM12 12h1v1h-1zM11 11h1v1h-1zM10 10h1v1h-1zM9 9h1v1H9zM8 8h1v1H8zM7 7h1v1H7zM6 6h1v1H6zM5 5h1v1H5zM4 4h1v1H4zM3 3h1v1H3zM2 2h1v1H2zM1 1h1v1H1zM0 0h1v1H0z" />
        <g>
          <path d="M14 0h1v1h-1zM13 1h1v1h-1zM12 2h1v1h-1zM11 3h1v1h-1zM10 4h1v1h-1zM9 5h1v1H9zM8 6h1v1H8zM7 7h1v1H7zM6 8h1v1H6zM5 9h1v1H5zM4 10h1v1H4zM3 11h1v1H3zM2 12h1v1H2zM1 13h1v1H1zM0 14h1v1H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgClose
